import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

function PhysicalGiftCardDialog({
  openGiftCardFilterDialog,
  handleCloseGiftCard,
  handleFilterGiftCard,
  selectedFilters,
  setSelectedFilters
}) {
  const handleClose = () => {
    handleCloseGiftCard();
  };
  const handleFilter = () => {
    handleFilterGiftCard();
  };

  const handleClearAll = () => {
    setSelectedFilters({
      status: "",
      from: moment().subtract(180, "days").format("YYYY-MM-DD"),
      to: moment().add(1, "days").format("YYYY-MM-DD"),
      giftCardId: "",
      id: "",
      batchName: "",
      sort: "DESC",
    });
  };
  return (
    <Dialog open={openGiftCardFilterDialog}>
      <DialogTitle align="center">Filter</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <TextField
            label="Gift Card Id"
            variant="outlined"
            size="small"
            sx={{ width: 200 }}
            value={selectedFilters?.giftCardId}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                giftCardId: event.target.value,
              }))
            }
          />
          <TextField
            type="number"
            size="small"
            label="Batch Id"
            sx={{ width: 200 }}
            value={selectedFilters?.id}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                id: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <FormControl sx={{ width: 200 }} size="small">
            <InputLabel>Select Status</InputLabel>
            <Select
              value={selectedFilters?.status}
              label="Select Month"
              onChange={(event) =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  status: event.target.value,
                }))
              }
            >
              <MenuItem value={100}>PRINTING</MenuItem>
              <MenuItem value={200}>ACTIVE</MenuItem>
              <MenuItem value={300}>DEACTIVE</MenuItem>
              <MenuItem value={400}>PAYMENTCLOSE</MenuItem>
              <MenuItem value={500}>COMPLETED</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="text"
            size="small"
            label="Batch Name"
            sx={{ width: 200 }}
            value={selectedFilters?.batchName}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                batchName: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <FormControl sx={{ width: 200 }} size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedFilters?.sort}
              label="Select Month"
              onChange={(event) =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  sort: event.target.value,
                }))
              }
            >
              <MenuItem value="DESC">Recent First</MenuItem>
              <MenuItem value="ASC">Old First</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <TextField
            type="date"
            size="small"
            label="From Date"
            value={selectedFilters?.from}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                from: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            type="date"
            size="small"
            label="To Date"
            value={selectedFilters?.to}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                to: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClearAll}
          size="small"
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          size="small"
        >
          close
        </Button>
        <Button variant="contained" onClick={handleFilter} size="small">
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PhysicalGiftCardDialog;
