import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import { Button } from "@mui/material";
import { DownloadSharp } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import "./gift.css"
// Component to render gift cards
const GiftCardTemplate = React.forwardRef(({ template, renderData }, ref) => {
  return (
    <div ref={ref} className="print-container four-cards">
      {renderData.map((data, index) => {
        const qrCodeImage = data.qrCode;
        const cardHTML = template
          .replace("{{amount}}", data.amount || "")
          .replace("{{branch}}", data.branch || "")
          .replace("{{id}}", data.id || "")
          .replace("{{password}}", data.password || "")
          .replace(
            "{{qr_code_image}}",
            `<img src="${qrCodeImage}" alt="QR Code" />`
          );
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: cardHTML }}
            style={{ pageBreakAfter: "always" }}
          ></div>
        );
      })}
    </div>
  );
});

export default function RenderGiftCards({
  templateFileName,
  renderData,
  page,
}) {
  const [template, setTemplate] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const imageFiles = useSelector((state) => state.fileHandler.appImages);
  const imageFilesstore = imageFiles[`image_${templateFileName}`];

  const componentRef = useRef();

  // Generate QR codes and populate preview data
  useEffect(() => {
    const prepareData = async () => {
      if (template && renderData.length) {
        const updatedData = await Promise.all(
          renderData.map(async (data) => {
            const qrCode = await QRCode.toDataURL(data.id);
            return { ...data, qrCode };
          })
        );
        setPreviewData(updatedData);
      }
    };
    prepareData();
  }, [template, renderData]);

  // Load template from base64
  useEffect(() => {
    if (imageFilesstore) {
      const base64Content = imageFilesstore.split(",")[1];
      const decodedTemplate = atob(base64Content);
      setTemplate(decodedTemplate);
    }
  }, [imageFilesstore]);

  // Handle printing
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      {previewData.length > 0 && (
        <>
          {/* Preview the first card */}
          <div
            dangerouslySetInnerHTML={{
              __html: template
                .replace("{{amount}}", previewData[0].amount || "")
                .replace("{{branch}}", previewData[0].branch || "")
                .replace("{{id}}", previewData[0].id || "")
                .replace("{{password}}", previewData[0].password || "")
                .replace(
                  "{{qr_code_image}}",
                  `<img src="${previewData[0].qrCode}" alt="QR Code" />`
                ),
            }}
            style={{ marginBottom: "20px" }}
          ></div>

          {/* Button to print all gift cards */}
          {page&&<Button
            onClick={handlePrint}
            sx={{ background: "#1976d2", color: "#fff", marginTop: ".5rem" }}
            variant="contained"
            size="small"
          >
            <DownloadSharp /> Print Gift Cards
          </Button>}
        </>
      )}

      {/* Hidden component for printing */}
      <div style={{ display: "none" }}>
        <GiftCardTemplate
          ref={componentRef}
          template={template}
          renderData={previewData}
        />
      </div>
    </div>
  );
}
