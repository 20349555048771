/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
// Importing the Libraries and Other Files
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Box } from "@mui/material";
import AutocompleteAddressFields from "../../zoneComponents/AutocompleteAddressFields";

const SkuPincodeFilterDialog = ({
  open,
  onClose,
  handleApplyFilter,
  intialParamsState,
  queryParams,
  setQueryParams,
  sortByKey,
  setSortByKey,
}) => {
  const [sortBy, setSortBy] = React.useState("created_at_desc");
  //@Removed/console.log(sortBy);
  const sorKeys = [
    { key: "created_at_asc", value: "sort_created_at", order: "ASC" },
    { key: "created_at_desc", value: "sort_created_at", order: "DESC" },
    { key: "updated_at_asc", value: "sort_updated_at", order: "ASC" },
    { key: "updated_at_desc", value: "sort_updated_at", order: "DESC" },
    { key: "pincode_asc", value: "sort_pincode", order: "ASC" },
    { key: "pincode_desc", value: "sort_pincode", order: "DESC" },
    { key: "locality_asc", value: "sort_locality", order: "ASC" },
    { key: "locality_desc", value: "sort_locality", order: "DESC" },
    { key: "district_asc", value: "sort_district", order: "ASC" },
    { key: "district_desc", value: "sort_district", order: "DESC" },
    { key: "zone_name_asc", value: "sort_zone_name", order: "ASC" },
    { key: "zone_name_desc", value: "sort_zone_name", order: "DESC" },
  ];
  React.useEffect(() => {
    //@Removed/console.log("sortByKey: ", sortByKey);
    for (const data of sorKeys) {
      if (sortByKey[data.value] && data.order === sortByKey[data.value]) {
        //@Removed/console.log("--TESTT UPODATE --");
        //@Removed/console.log(data);
        setSortBy(data.key);
        break;
      }
    }
  }, []);

  const handleChange = (field, value) => {
    setQueryParams({ ...queryParams, [field]: value });
  };

  const handleFilter = () => {
    let tempSortBy = {};
    for (const data of sorKeys) {
      if (sortBy === data.key) {
        //@Removed/console.log("--TESTT UPODATE -1 --");
        //@Removed/console.log(data);
        tempSortBy[data.value] = data.order;
        //@Removed/console.log("tempSortBy: ", tempSortBy);
        setSortByKey(tempSortBy);
        break;
      }
    }
    handleApplyFilter(queryParams, tempSortBy);
    onClose();
  };

  const handelClearFilter = () => {
    setSortBy("created_at_desc");
    setSortByKey({ created_at: "DESC" });
    // setQueryParams(intialParamsState);
      setQueryParams({
        ...queryParams,
        pincode: "",
        locality: "",
        district: "",
        state: "",
      zone_name: "",
    });
    handleApplyFilter(
      {
        pincode: "" || undefined,
        locality: "" || undefined,
        district: "" || undefined,
        country: "INDIA",
        state: "" || undefined,
        page_size: 25,
        page_number: 1,
        zone_name: "",
      },
      { created_at: "DESC" }
    );
    onClose();

  };

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={6}>
          <TextField
            label="Pincode"
            size="small"
            type="number"
              fullWidth
            value={queryParams.pincode}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            onChange={(e) => handleChange("pincode", e.target.value)}
          />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAddressFields
              key_name="locality"
            label="Locality"
            value={queryParams.locality}
              setValue={(value) => handleChange("locality", value)}
          />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAddressFields
              key_name="district"
            label="District"
            value={queryParams.district}
              setValue={(value) => handleChange("district", value)}
          />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAddressFields
              key_name="country"
            label="Country"
            value={queryParams.country}
              setValue={(value) => handleChange("country", value)}
          />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAddressFields
              key_name="state_name"
            label="State"
              value={queryParams?.state}
              setValue={(value) => handleChange("state", value)}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAddressFields
              key_name="zone_name"
              label="Zone Name"
              value={queryParams.zone_name}
              setValue={(value) => handleChange("zone_name", value)}
          />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard" size="small">
            <InputLabel>Sort by</InputLabel>
            <Select
              name="sort_by"
              size="small"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="created_at_asc">Created at (A-Z) ASC</MenuItem>
                <MenuItem value="created_at_desc">
                  Created at (Z-A) DESC
                </MenuItem>
              <MenuItem value="updated_at_asc">Updated At (A-Z) ASC</MenuItem>
                <MenuItem value="updated_at_desc">
                  Updated At (Z-A) DESC
                </MenuItem>
              <MenuItem value="pincode_asc">Pincode (A-Z) ASC</MenuItem>
              <MenuItem value="pincode_desc">Pincode (Z-A) DESC</MenuItem>
              <MenuItem value="locality_asc">Locality (A-Z) ASC</MenuItem>
              <MenuItem value="locality_desc">Locality (Z-A) DESC</MenuItem>
                <MenuItem value="district_asc">District (A-Z) ASC</MenuItem>
                <MenuItem value="district_desc">District (Z-A) DESC</MenuItem>
                <MenuItem value="zone_name_asc">ZoneName (A-Z) ASC</MenuItem>
                <MenuItem value="zone_name_desc">ZoneName (Z-A) DESC</MenuItem>
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        {/* Add similar Select components for other sort options */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handelClearFilter}>
          Clear Filter
        </Button>
        <Button
          onClick={handleFilter}
          variant="contained"
          color="primary"
          size="small"
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkuPincodeFilterDialog;
