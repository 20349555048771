import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FirstPage, LastPage } from "@mui/icons-material";
export default function CoustomPagiation({
  setOpenPincodeFilter,
  csvLink,
  setPageNumber,
  handlePageSizeChange,
  pageNumber,
  toCSV,
  handelFilterDialog,
  pageSize,
  data,
  downLoad,
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
      {" "}
      <Button
        onClick={() => handelFilterDialog()}
        size="small"
        startIcon={<FilterListIcon />}
      >
        Filter
      </Button>
      <Box
        sx={{
          maxWidth: "120px",
          maxHeight: "10px",
        }}
      >
        <FormControl fullWidth>
          <NativeSelect
            value={pageSize}
            onChange={handlePageSizeChange}
            inputProps={{
              name: "pageSize",
              // id: "uncontrolled-native",
            }}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </NativeSelect>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={() => {
            if (pageNumber - 1 >= 0) {
              setPageNumber(() => pageNumber - 1);
            }
          }}
        >
          <FirstPage sx={{ color: pageNumber - 1 >= 0 ? "blue" : "gray" }} />
        </IconButton>
        <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
          {pageNumber + 1}
        </Typography>
        <IconButton
          sx={{ maxHeight: "50px" }}
          onClick={() => {
            if (pageSize === data?.length) {
              setPageNumber(() => pageNumber + 1);
            }
          }}
        >
          <LastPage
            sx={{
              color: pageSize > data?.length ? "gray" : "blue",
            }}
          />
        </IconButton>
      </Box>
 
    </Box>
  );
}
