/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Inputs)
 * @date 20-11-2024
 * @version
 */

import React, { useState } from "react";
import { TextField } from "@mui/material";

function CoustomTextField({
  validationRegex,
  isHintRequired,
  setIsValid,
  handleChange,
  type,
  isRequired,
  value,
  isValid,
  label,
  name,
}) {
  const [hint, setHint] = useState(false);

  //validation function for text fields
  const handleValidation = (name, value) => {
    let isValid = true;
    if (validationRegex) {
      isValid = validationRegex.test(value.trim());
    }
    if (isHintRequired) {
      setHint(!isValid);
    }
    setIsValid(name, isValid);
    return isValid;
  };

  //handel change function
  const handleChangeTextField = (event) => {
    const value = event.target.value;
    const isValid = handleValidation(name, value);
    handleChange(name, value, isValid);
  };

  return (
    <TextField
      size="small"
      type={type}
      onChange={handleChangeTextField}
      error={
        (isRequired && !hint && value !== "") || !isRequired ? false : !isValid
      }
      label={label}
      color="success"
      value={value || ""}
      focused
      required={isRequired}
      helperText={hint ? "Incorrect entry" : ""}
      sx={{
        backgroundColor: "white",
        fontSize: "18px",
        borderRadius: "5px",
        // width: { md: "45%", xs: "100%" },
        marginTop: "1%",
      }}
    />
  );
}

export default CoustomTextField;
