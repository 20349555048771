/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.173
 * @Date 03-12-2024
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Chip } from "@mui/material";
import CustomTable from "../../Components/Tables/CoustomTable";
import { tabelConfig } from "../../Components/AppMeta/appTabelConfig";
import EditIcon from "@mui/icons-material/Edit";
import {
  ApprovePoOrder,
  CompleteManufactureOrder,
  GetMftOrderPoData,
  ManufactureOrdersList,
  UpdateManufactureOrder,
  UploadManufactureOrderPo,
  UploadManufactureOrderPR,
  ValidateManufactureOrder,
} from "../Redux/Actions/bookWaterStockAction";
import { manufactureOrder } from "../../Components/AppMeta/appMetaConfig";
import ManuFactureOrderEdit from "../../Components/Dialog/editManufactureOrder";
import { getFiles, UploadFile } from "../Redux/Actions/fileHandlingAction";
import ManuFactureFilter from "../../Components/Dialog/manufactureOrderFilter";
import PoFileDownload from "./PoFileDownload";
export default function ManufactureOrderDetails() {
  const dispatch = useDispatch();
  const [manufactureOrderData, setManufactureOrderData] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [validateOrder, setValidateOrder] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    order_id: "",
    created_at: "DESC",
    order_status: null,
  });
  const [poData, setPoData] = useState();
  //function to fetch data from api
  const manufactureOrdersData = () => {
    let reqObj = {
      page_number: pageNumber + 1,
      page_size: pageSize,
      order_by: [{ created_at: selectedFilters?.created_at }],
    };
    if (selectedFilters?.order_id) {
      reqObj.order_id = selectedFilters?.order_id;
    }
    if (selectedFilters.order_status) {
      // reqObj.order_status = [{ order_status: selectedFilters?.order_status }];
      reqObj.order_status = selectedFilters?.order_status;
    }
    dispatch(ManufactureOrdersList(reqObj))
      .then((payload) => {
        const { data } = payload;
        setManufactureOrderData(data?.data);
      })
      .catch((error) => {
        setManufactureOrderData([]);
      });
  };

  //useEffect to get manufacture data
  useEffect(() => {
    manufactureOrdersData();
  }, [pageSize, pageNumber]);

  //function to show edit icon
  const editProductsIcon = (props) => {
    return (
      <Box>
        <EditIcon onClick={() => editOrderDialog(props)} />
      </Box>
    );
  };

  // Function to get color and label based on status
  const getStatusDetails = (status) => {
    switch (status) {
      case manufactureOrder.CANCELLED:
        return { label: "Cancelled", color: "error" };
      case manufactureOrder.PO_REJECTED:
      case manufactureOrder.PR_REJECTED:
        return { label: "Rejected", color: "warning" };
      case manufactureOrder.PO_APPROVED:
        return { label: "PO Approved", color: "info" };
      case manufactureOrder.PR_APPROVED:
        return { label: "PR Approved", color: "info" };
      case manufactureOrder.CONFIRMED:
      case manufactureOrder.COMPLETED:
        return {
          label:
            status === manufactureOrder.COMPLETED ? "Completed" : "CONFIRMED",
          color: "success",
        }; // Green
      default:
        return { label: "In Progress", color: "info" }; // Water Blue
    }
  };
  //order status function
  const OrderStatusFunction = (status) => {
    const { label, color } = getStatusDetails(status?.row?.order_status);
    return (
      <Box>
        <Chip label={label} color={color} variant="outlined" />
      </Box>
    );
  };

  //function to open edit dialog box for
  const tableRowClickFunction = (index, data) => {
    console.log(data);
    setOpenDialog(true);
    setComponentData(JSON.parse(JSON.stringify(data?.products)));
    setSelectedData(data);
  };

  //function to open edit dialog box for
  const editOrderDialog = (data) => {
    setOpenDialog(true);
    setComponentData(JSON.parse(JSON.stringify(data?.row?.products)));
    setSelectedData(data?.row);
  };

  //function to handel upload function
  const handelUpdateOrder = () => {
    const Data = selectedData;
    let reqObj = {
      order_type: Data?.order_type,
      order_id: Data?.order_id,
      mft_id: Data?.mft_details?.id,
      products: componentData,
    };
    dispatch(UpdateManufactureOrder(reqObj)).then((details) => {
      console.log(details);
      const { data } = details;
      if (!data.error) {
        manufactureOrdersData();
        setOpenDialog(false);
      }
    });
  };

  //function to cancel order
  const handelCancelOrder = () => {
    const Data = selectedData;
    let reqObj = {
      order_type: Data?.order_type,
      order_id: Data?.order_id,
      mft_id: Data?.mft_details?.id,
      cancel_order: true,
      reason: "Order Cancelled by Admin",
    };
    dispatch(UpdateManufactureOrder(reqObj)).then((details) => {
      console.log(details);
      const { data } = details;
      if (!data.error) {
        manufactureOrdersData();
        setOpenDialog(false);
      }
    });
  };

  //function to handel po upload
  const handelFileUpload = (e) => {
    const file = e.target.files[0];
    dispatch(UploadFile(file)).then((data) => {
      if (data?.filename) {
        dispatch(
          UploadManufactureOrderPo({
            order_id: selectedData?.order_id,
            is_approve: false,
            po_file: data?.filename,
          })
        ).then((data) => {
          if (!data?.error) {
            manufactureOrdersData();
            setOpenDialog(false);
          }
        });
      }
    });
  };
  //funtion to handel approve po
  const handelApprovePo = () => {
    dispatch(
      ApprovePoOrder({
        order_id: selectedData?.order_id,
        is_approve: true,
      })
    ).then((data) => {
      if (!data?.error) {
        manufactureOrdersData();
        setOpenDialog(false);
      }
    });
  };

  //funtion to handel approve po
  const handelCompleteOrder = () => {
    dispatch(
      CompleteManufactureOrder({
        order_id: selectedData?.order_id,
      })
    ).then((data) => {
      if (!data?.error) {
        manufactureOrdersData();
        setOpenDialog(false);
      }
    });
  };

  //function to upload pr
  const handelUploadPR = (e) => {
    const file = e.target.files[0];
    dispatch(UploadFile(file)).then((data) => {
      if (data?.filename) {
        dispatch(
          UploadManufactureOrderPR({
            order_id: selectedData?.order_id,
            is_approve: false,
            payment_receipt: data?.filename,
          })
        ).then((data) => {
          if (!data?.error) {
            manufactureOrdersData();
            setOpenDialog(false);
          }
        });
      }
    });
  };

  //function to approve pr
  const handelApprovePr = () => {
    dispatch(
      CompleteManufactureOrder({
        order_id: selectedData?.order_id,
      })
    ).then((data) => {
      if (!data?.error) {
        manufactureOrdersData();
        setOpenDialog(false);
      }
    });
  };

  //function to handel download po
  const handelDownloadPo = () => {
    dispatch(getFiles(selectedData?.po_file));
  };

  //function to handel download pr
  const handelDownloadPr = () => {
    dispatch(getFiles(selectedData?.payment_receipt));
  };

  //function to validate order
  const handelValidateOrder = () => {
    const Data = selectedData;

    let reqObject = {
      order_type: Data?.order_type,
      order_id: Data?.order_id,
      orders: [{ mft_id: Data?.mft_details?.id, products: componentData }],
    };
    dispatch(ValidateManufactureOrder(reqObject)).then((details) => {
      const { data } = details;
      if (!data?.error) {
        setComponentData(data?.data?.orders[0]?.products);
        setValidateOrder(false);
      }
    });
  };

  //function to handel download po
  const handelDownloadDraftPo = () => {
    const Data = selectedData;
    let reqObject = {
      order_id: Data?.order_id,
    };
    dispatch(GetMftOrderPoData(reqObject)).then((data) => {
      if (!data.error) {
        setPoData(data.data);
      }
    });
  };

  //oprn filter dialog
  const handelFilterDialog = () => {
    setOpenFilterDialog(true);
  };

  //function to close gift card dialog
  const handelClose = () => {
    setOpenFilterDialog(false);
  };
  //function handel gift card
  const handleFilter = (props) => {
    manufactureOrdersData();
    handelClose();
  };

  return (
    <>
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <CustomTable
          tableHead={tabelConfig.manufactureOrderData.headerData}
          tableData={manufactureOrderData}
          tableDataKey={[
            ...tabelConfig.manufactureOrderData.tabelDataKey,
            {
              component: (props) => {
                return OrderStatusFunction(props);
              },
            },
            {
              component: (props) => {
                return editProductsIcon(props);
              },
            },
          ]}
          tableRowClickFunction={tableRowClickFunction}
          pagination="200"
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          pageSize={pageSize}
          handelFilterDialog={handelFilterDialog}
        />
        {openDialog && (
          <ManuFactureOrderEdit
            open={openDialog}
            setOpen={setOpenDialog}
            componentData={componentData}
            setComponentData={setComponentData}
            handelUpdateOrder={handelUpdateOrder}
            selectedData={selectedData}
            handelApprovePo={handelApprovePo}
            handelCompleteOrder={handelCompleteOrder}
            handelUploadPo={handelFileUpload}
            handelApprovePr={handelApprovePr}
            handelUploadPr={handelUploadPR}
            handelDownloadPr={handelDownloadPr}
            handelDownloadPo={handelDownloadPo}
            setValidateOrder={setValidateOrder}
            validateOrder={validateOrder}
            handelValidateOrder={handelValidateOrder}
            handelCancelOrder={handelCancelOrder}
            handelDownloadDraftPo={handelDownloadDraftPo}
          />
        )}

        {openFilterDialog && (
          <ManuFactureFilter
            openFilterDialog={openFilterDialog}
            handelClose={handelClose}
            handleFilter={handleFilter}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        )}
      </Box>
      <Box sx={{ display: "none" }}>
        {poData && <PoFileDownload data={poData} />}
      </Box>
    </>
  );
}
