import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  GetPinCodeDeltails,
  AssignSupplier,
  RegularOrdersAssignSupplier,
  GetViewOrderDetails,
  GetNewSearchOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
import { ToastContainer, toast } from "react-toastify";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";

export default function Editdistibuter({
  seteditBtnFlag,
  editBtnFlag,
  orderDetails,
  newOrderDetails,
}) {
  const dispatch = useDispatch();
  const [pinCode, setPincode] = React.useState(0);
  const [orderId, setOrderId] = React.useState(newOrderDetails[0]?.order_id);
  const [pinCodeId, setpinCodeId] = React.useState({ user_id: "" });
  const [isUpdatebutton, setUpdatebutton] = React.useState(false);
  //   const [orderIdForSupplier, setorderIdForSupplier] = React.useState({
  //     order_id: orderDetails.order_id,
  //   });

  const orderIdForSupplier = {
    order_id: newOrderDetails[0]?.order_id,
  };
  const allPincodeData = useSelector(
    (state) => state.orderDetailsAdmin.getPinCode
  );
  const allPincodeData1 = useSelector((state) => state.orderDetailsAdmin);
  const handelClose = () => {
    seteditBtnFlag(false);
  };
  const handelSubmitPincode = (event) => {
    event.preventDefault();
    dispatch(GetPinCodeDeltails(pinCode, orderId));
  };
  const handelIdChange = (data) => {
    // setpinCodeId({ user_id: data });
    setpinCodeId({ supplier_user_id: data });
    setUpdatebutton(true);
  };
  const handelSupplierId = (event) => {
    event.preventDefault();
    seteditBtnFlag(false);
    const reqobj = {
      ...pinCodeId,
      ...orderIdForSupplier,
    };
    dispatch(RegularOrdersAssignSupplier(reqobj)).then((data)=>{
    const reqObj = {
      delivery_from_date: moment().format("YYYY-MM-DD"),
      delivery_to_date: moment().format("YYYY-MM-DD"),
      page_size: 30,
      page_number: 1,
      order_ids: [orderId],
    };
    dispatch(GetNewSearchOrderDetails(reqObj));
    });
    seteditBtnFlag(false);
  };
  CustomConsole(pinCodeId);
  return (
    <div>
      <Dialog
        open={editBtnFlag}
        onClose={handelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                type="text"
                label="Pincode"
                variant="outlined"
                inputProps={{
                  maxLength: 6,
                }}
                onChange={(event) => setPincode(event.target.value)}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={handelSubmitPincode}
              sx={appColors.commonBtnColor}
            >
              Search
            </Button>
            {allPincodeData !== undefined ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  {allPincodeData.map((data, index) => (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        display: "inline-flex",
                        flexWrap: "wrap",
                      }}
                      component="nav"
                      aria-label="folders"
                      onClick={() => handelIdChange(data.id)}
                      key={index}
                    >
                      <ListItem
                        button
                        sx={{
                          display: "inline-flex",
                          flexWrap: "wrap",
                          "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#2196f3",
                          },
                        }}
                      >
                        <Typography>Supplier Name:{data.fullname}</Typography>
                        <Typography>Phone Number:{data.phone}</Typography>
                        <Typography>
                          Container to be Delivered:{data.delivery_containers}
                        </Typography>
                        <Typography>
                          Empty container with distributor:
                          {data.empty_container_qty}
                        </Typography>
                        <Typography>
                          Filled container with distributor:
                          {data.filled_container_qty}
                        </Typography>
                      </ListItem>
                      <Divider />
                    </List>
                  ))}

                  {
                    <Dialog
                      open={isUpdatebutton}
                      // onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ marginTop: "1em" }}
                          onClick={handelSupplierId}
                        >
                          Assign
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ marginTop: "1em", marginLeft: "10px" }}
                          onClick={() => {
                            setUpdatebutton(false);
                          }}
                        >
                          close
                        </Button>
                      </DialogContent>
                    </Dialog>
                  }
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ marginTop: "1em" }}
                    onClick={handelClose}
                  >
                    Close
                  </Button>
                </FormControl>
              </Box>
            ) : (
              " "
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
