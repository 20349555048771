/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author
 * @description Add product dialog box
 * @date
 * @version
 */
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import {
  UploadFile,
  getFilesImages,
} from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextFieldReusable from "../TextFiled/textField";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import {
  GetAvailableProductsByName,
  UpdateSkuProductDetails,
} from "../../Pages/Redux/Actions/SKUProductDetailsAction";
import ConformationDialog from "./conformationDialog";
import CustomConsole from "../../CustomConsole";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { PRODUCT_TYPES } from "../AppMeta/appMetaConfig";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { RawMaterialStockDetails } from "../../Pages/Redux/Actions/bookWaterStockAction";

function AddnewProductDialog(props) {
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const [productName, setProductName] = React.useState("");
  const [availableComponents, setAvailableComponents] = useState([]);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [skuQQty, setSkuQQty] = React.useState([]);
  const [hsnCode, setHsnCode] = React.useState("");
  const [emptyhsnCode, setEmptyHsnCode] = React.useState("");
  const [emptycgstPercentage, setEmptyCgstPercentage] = React.useState("");
  const [emptysgstPercentage, setEmptySgstPercentage] = React.useState("");
  const [emptyigstPercentage, setEmptyIgstPercentage] = React.useState("");
  const [cgstPercentage, setCgstPercentage] = React.useState("");
  const [sgstPercentage, setSgstPercentage] = React.useState("");
  const [igstPercentage, setIgstPercentage] = React.useState("");
  const [minimumBookQty, setMinimumBookQty] = React.useState("");
  const [maximumBookQty, setMaximumBookQty] = React.useState("");
  const [orderType, setOrderType] = React.useState(400);
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [refunTypeSelectedRefill, setRefunTypeSelectedRefill] =
    React.useState(0);
  const [refunTypeSelectedOneTime, setRefunTypeSelectedOneTime] =
    React.useState(0);
  const [oneTimeSale, setOneTimeSale] = React.useState({
    mrp: 1,
    sp: 1,
    deposit: 0,
    refund_type: 100,
    refund_amount: refunTypeSelectedOneTime,
  });
  const [selectedData, setSelectedData] = useState([]);
  const [refillSale, setRefillSale] = React.useState({
    mrp: 1,
    sp: 1,
    deposit: 0,
    refund_type: 100,
    refund_amount: refunTypeSelectedRefill,
  });
  const [selectedComponent, setSelectedComponent] = useState();
  const [description, setDescription] = React.useState("");
  const [uploadNewProductFile, setUploadNewProductFile] = React.useState("");
  const [newProductUrl, setNewProductUrl] = React.useState("");
  const [isEpValid, setIsValid] = React.useState(false);
  const [uploadProdcutBtn, setUploadProdcutBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [buttonFlag, setButtonFlag] = React.useState(true);
  const [bookingType, setBookingType] = React.useState(1);
  const [isActive, setIsActive] = React.useState(true);
  const uploadNewProduct = React.useRef(null);
  const [deleteProduct, setDeleteProduct] = React.useState(false);
  const updateFlag = Object.keys(props.updateProductDetails).length > 0;
  const [crateDetails, setCrateDetails] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(true);
  const [isFillingProduct, setIsFillingProduct] = React.useState(true);
  const [errorField, setErrorField] = React.useState({
    Capacity: false,
    "Product Name": false,
    Material: false,
    "Life Span": false,
    "SKU Quantity": false,
    "HSN Code": false,
    "CGST %": false,
    "SGST %": false,
    "IGST %": false,
    "Minimum Book Quantity": false,
    "Maximum Book Quantity": false,
    "Empty HSN Code": false,
    "Empty CGST %": false,
    "Empty SGST %": false,
    "Empty IGST %": false,
    Description: false,
  });
  const dialogMsg = "Are You sure to delete selected Product";
  console.log(props.updateProductDetails);
  CustomConsole(props.updateCrateDetails);

  //useEffect for setting up initial data in state
  React.useEffect(() => {
    const setSaleValues =
      (saleType) =>
      ({ mrp, sp, deposit, refund_type, refund_amount }) => ({
        mrp: mrp === 0 ? 1 : mrp,
        sp: sp === 0 ? 1 : sp,
        deposit: deposit === 0 ? 1 : deposit,
        refund_type: refund_type === 0 ? 100 : refund_type,
        refund_amount:
          refund_amount === 0
            ? saleType === "one_time"
              ? refunTypeSelectedOneTime
              : refunTypeSelectedRefill
            : refund_amount,
      });

    if (updateFlag) {
      const {
        name,
        capacity,
        material,
        life_span,
        amount,
        sku_qty,
        hsn_code,
        cgst_perc,
        sgst_perc,
        min_book_qty,
        igst_perc,
        max_book_qty,
        order_type,
        delivery_charges,
        description,
        one_time_sale,
        refill,
        booking_type,
        is_active,
        image,
        is_qr,
        is_filling_product,
        empty_sgst_perc,
        empty_cgst_perc,
        empty_hsn_code,
        empty_igst_perc,
        components,
      } = props.updateProductDetails;
      CustomConsole("is_qr" + is_qr);
      setProductName(name);
      setCapacity(capacity);
      setMaterial(material);
      setLifeSpan(life_span);
      setAmount(amount);
      setSkuQQty(sku_qty);
      setHsnCode(hsn_code);
      setEmptyIgstPercentage(empty_igst_perc);
      setEmptySgstPercentage(empty_sgst_perc);
      setEmptyCgstPercentage(empty_cgst_perc);
      setEmptyHsnCode(empty_hsn_code);
      setCgstPercentage(cgst_perc);
      setSgstPercentage(sgst_perc);
      setMinimumBookQty(min_book_qty);
      setIgstPercentage(igst_perc);
      setMaximumBookQty(max_book_qty);
      setOrderType(order_type === 0 ? orderType : order_type);
      setDeliveryCharges(delivery_charges);
      setDescription(description);
      setOneTimeSale(setSaleValues("one_time")(one_time_sale));
      setRefillSale(setSaleValues("refill")(refill));
      setBookingType(booking_type);
      setIsActive(is_active === undefined ? true : is_active);
      setNewProductUrl(image);
      setCrateDetails(props.updateCrateDetails);
      setIsChecked(is_qr);
      setIsFillingProduct(is_filling_product);
      setSelectedData(components);
    }
  }, [props.updateProductDetails]);

  //@Removed/console.log(refillSale);
  //function for handling file upload opreation
  const handleUploadNewProduct = () => {
    uploadNewProduct.current.click();
  };

  //variable for different Refund types
  const refundTypes = [
    {
      label: "WALLET",
      value: 100,
    },
    {
      label: "WATER DROPS",
      value: 200,
    },
    {
      label: "None",
      value: 0,
    },
  ];
  const ProductTypes = [
    {
      label: "Finished Good",
      value: PRODUCT_TYPES.PRODUCT_TYPE_FINISHED_GOODS,
    },
  ];

  let reqObj = {
    description: description,
    delivery_charges: deliveryCharges,
    order_type: orderType,
    max_book_qty: maximumBookQty,
    min_book_qty: minimumBookQty,
    igst_perc: igstPercentage,
    sgst_perc: sgstPercentage,
    cgst_perc: cgstPercentage,
    hsn_code: hsnCode,
    empty_hsn_code: emptyhsnCode,
    empty_igst_perc: emptyigstPercentage || 0,
    empty_cgst_perc: emptycgstPercentage,
    empty_sgst_perc: emptysgstPercentage,
    sku_qty: skuQQty,
    amount: amount,
    life_span: lifeSpan,
    material: material,
    capacity: capacity,
    name: productName,
    image: newProductUrl,
    booking_type: bookingType,
    one_time_sale: oneTimeSale,
    product_type: props?.selectedoptions === "Final Products" ? 100 : 200,
    components: selectedData,
    refill: refillSale,
    is_active: isActive,
    is_qr: isChecked,
    is_filling_product: isFillingProduct,
  };
  //function for updating state with selected order type
  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  //function for updating state with selected order type
  const handelRefundTypeChanged = (event, data) => {
    //@Removed/console.log(event.target.value);
    if (data === "Refill") {
      setRefillSale((prev) => ({ ...prev, refund_type: event.target.value }));
    } else {
      setOneTimeSale((prev) => ({ ...prev, refund_type: event.target.value }));
    }
  };
  ////useeffect for handling file upload opreation
  React.useEffect(() => {
    if (uploadNewProductFile !== "") {
      dispatch(UploadFile(uploadNewProductFile));
      setUploadProdcutBtn(true);
      setUploadNewProductFile("");
      setUploadFlags(true);
    }
  }, [uploadNewProductFile]);

  //useEffect for uploading file
  React.useEffect(() => {
    if (fileHandler.isFileUploaded === true && uploadProdcutBtn === true) {
      CustomConsole("New Product file uploaded file here");
      setNewProductUrl(fileHandler.fileName);
      setUploadProdcutBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("New Product File uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);
  //function for handling closing dialog
  const handleCancle = () => {
    props.handleCancle();
    setProductName("");
    setCapacity("");
    setMaterial("");
    setLifeSpan("");
    setAmount("");
    setSkuQQty([]);
    setHsnCode("");
    setEmptyIgstPercentage("");
    setEmptySgstPercentage("");
    setEmptyCgstPercentage("");
    setEmptyHsnCode("");
    setCgstPercentage("");
    setSgstPercentage("");
    setMinimumBookQty("");
    setIgstPercentage("");
    setMaximumBookQty("");
    setOrderType("");
    setDeliveryCharges("");
    setDescription("");
    setUploadNewProductFile("");
    setIsActive(true);
    props.setUpdateProductDetails({});
  };

  //function for handling addProduct operation
  const handleAddProduct = () => {
    console.log(validateFormData());
    if (validateFormData().length === 0) {
    if (!updateFlag) {
      props.handleAddProduct(reqObj);
        props.setUpdateProductDetails({});
    } else {
      reqObj.container_id = props.updateProductDetails.id;
      reqObj.crate_details = crateDetails;
      dispatch(UpdateSkuProductDetails(reqObj));
        props.setUpdateProductDetails({});
      }
    } else {
      toast.error(
        `Please provide valid values in the required fields ${validateFormData().join()}`,
        {
          position: "top-right",
          autoClose: 3000,
        }
      );
    }
  };

  const handleChange = (event) => {
    CustomConsole(event.target.value);
    setBookingType(event.target.value);
  };

  //function for deleting account
  const handelProceedDelete = () => {
    reqObj.container_id = props.updateProductDetails.id;
    reqObj.is_active = false;
    dispatch(UpdateSkuProductDetails(reqObj));
    props.setUpdateProductDetails({});
  };

  //useEffect for getting filee images
  React.useEffect(() => {
    CustomConsole("I am Hitted for getting file images");
    CustomConsole(newProductUrl);
    if (updateFlag && newProductUrl !== "") {
      dispatch(getFilesImages(newProductUrl));
    }
  }, [newProductUrl]);
  // Function to handle the change in Checkbox state
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  //function to handel check box change filling process
  const handleCheckboxChangeFilling = (event) => {
    setIsFillingProduct(event.target.checked);
  };

  //products functio get products on clicik
  const getProducts = async (name) => {
    if (props?.selectedoptions === "Final Products") {
      let products = await GetAvailableProductsByName({
        product_type: PRODUCT_TYPES.PRODUCT_TYPE_FINISHED_GOODS,
        ignore_app_loading_spinner: true,
        name,
      });
      if (products.data) {
        const components = products.data?.map((item) => ({
          ...item.product_details,
        }));
        console.log(components);
        setAvailableComponents(components);
      } else {
        setAvailableComponents([]);
      }
    } else {
      dispatch(
        RawMaterialStockDetails({
          ignore_app_loading_spinner: true,
          name,
        })
      ).then((data) => {
        if (!data?.data?.error) {
          setAvailableComponents(data?.data?.data);
        } else {
          setAvailableComponents([]);
        }
      });
    }
  };

  console.log("selectedComponent....................");
  console.log(selectedComponent);

  const setProcessingDetails = () => {
    // Check if the product already exists in the data array
    const existingIndex = selectedData.findIndex(
      (item) =>
        item.capacity === selectedComponent.capacity &&
        item.material === selectedComponent.material
    );

    if (existingIndex >= 0) {
      // If it exists, update its quantity
      const updatedData = [...selectedData];
      updatedData[existingIndex].quantity =
        Number(updatedData[existingIndex].quantity) +
        Number(selectedComponent.req_qty);
      setSelectedData(updatedData);
    } else {
      // If not, add a new entry
      setSelectedData((prev) => [
        ...prev,
        {
          capacity: selectedComponent.capacity,
          material: selectedComponent.material,
          quantity: Number(selectedComponent.req_qty),
        },
      ]);
    }

    // Reset selectedComponent
    setSelectedComponent({});
  };

  //function to delete selected product
  const handelDeleteSelectedProduct = (details) => {
    let deletedData = selectedData?.filter(
      (data) =>
        `{${data?.capacity}_${data?.material}}` !==
        `{${details?.capacity}_${details?.material}}`
    );
    setSelectedData(deletedData);
  };

  console.log("selectedData..........................");
  console.log(selectedData);

  //function to gvalidate data
  const validateFormData = () => {
    console.log(errorField);
    const invalidFields = Object.keys(errorField).filter(
      (key) => errorField[key]
    );
    return invalidFields;
  };

  //fetch product data first time
  React.useEffect(() => {
    getProducts("");
  }, []);
  console.log(errorField);

  //function to handel add quanity
  const handelAddQuantity = (data) => {
    const existingIndex = selectedData.findIndex(
      (item) =>
        item.capacity === data.capacity && item.material === data.material
    );
    if (existingIndex >= 0) {
      const updatedData = [...selectedData];
      updatedData[existingIndex].quantity =
        Number(updatedData[existingIndex].quantity) + 1;
      setSelectedData(updatedData);
    }
  };

  //function to handel reduse quantity
  const handelReduseQuantity = (data) => {
    const existingIndex = selectedData.findIndex(
      (item) =>
        item.capacity === data.capacity && item.material === data.material
    );
    if (existingIndex >= 0) {
      const updatedData = [...selectedData];
      if (updatedData[existingIndex].quantity > 0) {
        updatedData[existingIndex].quantity =
          Number(updatedData[existingIndex].quantity) - 1;
        setSelectedData(updatedData);
      }
    }
  };
  return (
    <Box>
      <Dialog open={props.openAddOrUpdateProduct}>
        <DialogTitle>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            ADD/UPDATE PRODUCT
            {updateFlag && newProductUrl !== "" ? (
              <img
                style={{ height: "50px", width: "100px" }}
                src={fileHandler.imagePath}
                alt="BookWater"
              />
            ) : null}{" "}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {props?.selectedoptions !== "Finished Goods" && (
            <Grid item md={6} sm={12} xs={12}>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="success"
                    />
                  }
                  label="Product With Qr/BarCode"
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFillingProduct}
                      onChange={handleCheckboxChangeFilling}
                      color="success"
                    />
                  }
                  label="Filling Product"
                />
              </Box>
            </Grid>
            )}
            <Grid item md={12} sm={12} xs={12}>
              {props?.selectedoptions === "Finished Goods" && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    sx={{
                      width: { sm: "90%", xs: "100%" },
                      marginTop: ".6rem",
                    }}
                    size="small"
                    label="Product Type"
                    defaultValue="Finished Goods"
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(event) => setProcessingDetails()}
                  >
                    {ProductTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Box>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextFieldReusable
                label="Product Name"
                type="text"
                value={productName}
                isRequired={true}
                setDataForState={setProductName}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Capacity"
                type="text"
                value={capacity}
                isRequired={true}
                setDataForState={setCapacity}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Material"
                type="text"
                value={material}
                isRequired={true}
                setDataForState={setMaterial}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Life Span"
                type="number"
                value={lifeSpan}
                isRequired={true}
                setDataForState={setLifeSpan}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="SKU Quantity"
                type="number"
                value={skuQQty}
                isRequired={true}
                setDataForState={setSkuQQty}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="HSN Code"
                type="text"
                value={hsnCode}
                isRequired={true}
                setDataForState={setHsnCode}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="CGST %"
                type="number"
                value={cgstPercentage}
                isRequired={true}
                setDataForState={setCgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="SGST %"
                type="number"
                value={sgstPercentage}
                isRequired={true}
                setDataForState={setSgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="IGST %"
                type="number"
                value={igstPercentage}
                isRequired={true}
                setDataForState={setIgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Minimum Book Quantity"
                type="number"
                value={minimumBookQty}
                isRequired={true}
                setDataForState={setMinimumBookQty}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Maximum Book Quantity"
                type="number"
                value={maximumBookQty}
                isRequired={true}
                setDataForState={setMaximumBookQty}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty HSN Code"
                type="text"
                value={emptyhsnCode}
                isRequired={true}
                setDataForState={setEmptyHsnCode}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty CGST %"
                type="number"
                value={emptycgstPercentage}
                isRequired={true}
                setDataForState={setEmptyCgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty SGST %"
                type="number"
                value={emptysgstPercentage}
                isRequired={true}
                setDataForState={setEmptySgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>
            {/* <Grid item md={6} sm={12} xs={12}>
              <TextFieldReusable
                label="Empty IGST %"
                type="number"
                value={emptyigstPercentage}
                isRequired={true}
                setDataForState={setEmptyIgstPercentage}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid> */}
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                id="outlined-select-currency-native"
                select
                sx={{ width: { sm: "90%", xs: "100%" } }}
                size="small"
                label="Refund Type"
                defaultValue={orderType}
                SelectProps={{
                  native: true,
                }}
                helperText="Please select Refund Type"
                onChange={handleOrderTypeChange}
              >
                {props.orderTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <TextFieldReusable
                label="Description"
                type="text"
                value={description}
                isRequired={true}
                setDataForState={setDescription}
                setIsValid={setIsValid}
                isHintRequired={true}
                setErrorField={setErrorField}
              />
            </Grid>

            {props?.selectedoptions !== "Finished Goods" && (
              <>
                <div
                  style={{
                    borderBottom: "2px dashed #818281",
                    width: "100%",
                    margin: ".5rem",
                  }}
                ></div>
            <Grid item md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={<Radio />}
                  checked={bookingType == 1}
                  value={1}
                  label="Refill"
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Radio />}
                  checked={bookingType == 2}
                  value={2}
                  label="One time sale"
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Radio />}
                  checked={bookingType == 3}
                  value={3}
                  label="Both"
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            {bookingType == 1 || bookingType == 3 ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography>Refill Sale</Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextFieldReusable
                    label="MRP"
                    type="Number"
                    value={refillSale.mrp}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                        setErrorField={setErrorField}
                  />
                  <TextFieldReusable
                    label="Sale Price"
                    type="Number"
                    value={refillSale.sp}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                        setErrorField={setErrorField}
                  />
                  <TextFieldReusable
                    label="Deposit"
                    type="Number"
                    value={refillSale.deposit}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                        setErrorField={setErrorField}
                    isHintRequired={true}
                  />
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    sx={{
                      width: { sm: "90%", xs: "100%" },
                      marginTop: ".6rem",
                    }}
                    size="small"
                    label="Refund Type"
                    defaultValue={refillSale.refund_type}
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(event) =>
                      handelRefundTypeChanged(event, "Refill")
                    }
                  >
                    {refundTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextFieldReusable
                    label="Refund Amount"
                    type="Number"
                    value={refillSale.refund_amount}
                    isRequired={true}
                    setDataForState={setRefillSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  />
                </Box>
              </Grid>
            ) : null}
            {bookingType == 2 || bookingType == 3 ? (
              <Grid item md={12} sm={12} xs={12}>
                <Typography>One Time Sale</Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextFieldReusable
                    label="MRP"
                    type="Number"
                    value={oneTimeSale.mrp}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                        setErrorField={setErrorField}
                  />
                  <TextFieldReusable
                    label="Sale Price"
                    type="Number"
                    value={oneTimeSale.sp}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                        setErrorField={setErrorField}
                  />
                  {/* <TextFieldReusable
                    label="Deposit"
                    type="Number"
                    value={oneTimeSale.deposit}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                    isHintRequired={true}
                  /> */}
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    sx={{
                      width: { sm: "90%", xs: "100%" },
                      marginTop: ".6rem",
                    }}
                    size="small"
                    label="Refund Type"
                    defaultValue={oneTimeSale.refund_type}
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(event) =>
                      handelRefundTypeChanged(event, "one_time")
                    }
                  >
                    {refundTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextFieldReusable
                    label="Refund Amount"
                    type="Number"
                    value={oneTimeSale.refund_amount}
                    isRequired={true}
                    setDataForState={setOneTimeSale}
                    setIsValid={setIsValid}
                        setErrorField={setErrorField}
                    isHintRequired={true}
                  />
                </Box>
              </Grid>
            ) : null}
              </>
            )}
          </Grid>
          <div
            style={{
              borderBottom: "2px dashed #818281",
              width: "100%",
              margin: ".5rem",
            }}
          ></div>
          <Box>
            <Typography sx={{ margin: ".5rem 0", fontWeight: "500" }}>
              Required Components
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <Box>
                <Autocomplete
                  sx={{ minWidth: "230px" }}
                  options={availableComponents}
                  value={selectedComponent}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  onChange={(event, newValue) => {
                    setSelectedComponent({
                      ...newValue,
                      capacity: newValue?.capacity || "",
                      material: newValue?.material || "",
                      req_qty: "",
                    });
                  }}
                  getOptionLabel={(option) => option?.name || ""}
                  onInputChange={(event, newInputValue) => {
                    getProducts(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box sx={{ fontWeight: "500", fontSize: ".85rem" }}>
                        {" "}
                        {`${option?.name} (${option?.capacity}/${option?.material})`}
                      </Box>
                      {/* <Box>
                        {option?.capacity} {option?.material}
                      </Box> */}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        props?.selectedoptions !== "Finished Goods"
                          ? "Search Finished Goods"
                          : "Search Raw Materials"
                      }
                      variant={"outlined"}
                      margin="normal"
                      size="small"
                      sx={{ marginTop: "0px", padding: "0px" }}
                    />
                  )}
                />
              </Box>
              <Box>
                <TextField
                  size="small"
                  label="Required Qty"
                  color="success"
                  onChange={(e) => {
                    setSelectedComponent((prev) => ({
                      ...prev,
                      req_qty: e.target.value > 0 ? e.target.value : "",
                    }));
                  }}
                  value={selectedComponent?.req_qty || ""}
                  sx={{
                    backgroundColor: "white",
                    fontSize: "18px",
                    borderRadius: "5px",
                    width: { xs: "100%" },
                  }}
                />
              </Box>
              <Box>
                <Button
                  disabled={
                    !selectedComponent?.req_qty ||
                    !selectedComponent.capacity ||
                    !selectedComponent.material
                  }
                  onClick={setProcessingDetails}
                  size="small"
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <TableContainer sx={{ maxHeight: "30vh" }} component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Quantity
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedData?.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            sx={{ paddingY: 0 }}
                            component="th"
                            scope="row"
                          >
                            {`${row?.capacity}/${row?.material}`}
                          </TableCell>
                          <TableCell sx={{ paddingY: 0 }}>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => handelReduseQuantity(row)}
                            >
                              <RemoveIcon />
                            </IconButton>
                            {row.quantity}
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => handelAddQuantity(row)}
                            >
                              <AddIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell sx={{ paddingY: 0 }}>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Button
                                onClick={() => {
                                  handelDeleteSelectedProduct(row);
                                }}
                              >
                                <DeleteOutlineIcon color="error" />
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Box sx={{ w: "100%", textAlign: "center" }}>
                  {/* {!components?.length ? "No Components added" : ""} */}
                </Box>
              </TableContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCancle}
          >
            Close
          </Button>
              <Button
                onClick={handleUploadNewProduct}
                size="small"
                variant="contained"
              >
                {fileHandler.isFileUploaded !== undefined &&
                fileHandler.isFileUploaded === false
                  ? "Upload Image"
                  : "Uploaded Successfully"}
                <input
                  type="file"
                  ref={uploadNewProduct}
                  style={{ display: "none" }}
                  onChange={(e) => setUploadNewProductFile(e.target.files[0])}
                />
              </Button>
          <Button
            variant="contained"
            size="small"
            disabled={description.length < 15}
            onClick={handleAddProduct}
          >
            {updateFlag ? "Update" : "Add Product"}
          </Button>
          {updateFlag ? (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setConfirmDialog(true)}
            >
              Delete
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <ConformationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        dialogMsg={dialogMsg}
        handelProceed={handelProceedDelete}
      />
    </Box>
  );
}

export default AddnewProductDialog;
