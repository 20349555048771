import {
  AddCircleOutline,
  Download,
  DownloadForOffline,
  FilterList,
  FirstPage,
  LastPage,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import WFSAddCostDialog from "./WFSAddCostDialog";
import CommonOperationLevelCostFilterDialog from "./CommonOperationLevelCostFilterDialog";
import {
  GetNextOpsExpensesDetails,
  GetOperationLevelCostList,
  PostAddOperationLevelExpenseCost,
  PutUpdateOperationLevelExpenseCost,
} from "../Redux/Actions/adminOperationLevelCostAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_ROLES,
  expense_type,
  operation_type,
} from "../../Components/AppMeta/appMetaConfig";
import moment from "moment";
import { GetSupplierDetailsList } from "../Redux/Actions/supplierVehiclesActions";
import { GetWFSDetails } from "../Redux/Actions/adminEmptyCanOrderAction";
import { GetWfsDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import { toast } from "react-toastify";
import { getFiles } from "../Redux/Actions/fileHandlingAction";
import { CSVLink } from "react-csv";

function WFSOperatingLevelCostMain() {
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [openAddWFSDetailsDialog, setOpenAddWFSDetailsDialog] = useState(false);
  const [openCommonFilterDialog, setOpenCommonFilterDialog] =
    React.useState(false);
  const [operationLevelCostList, setOperationLevelCostList] = React.useState(
    []
  );
  const [filterProps, setFilterProps] = React.useState({});
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [singleOperationCostDetails, setSingleOperationCostDetails] =
    React.useState({});
  const [openEditSingleOperationCost, setOpenEditSingleOperationCost] =
    React.useState(false);
  const [filterExpenseType, setFilterExpenseType] = React.useState("");
  React.useEffect(() => {
    GetOperationExpensesCostList();
  }, [pageNumber, pageSize]);

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename:
      "DeliveryReport" +
      moment().tz("Asia/kolkata").format("YYYY-MM-DD HH:mm:ss") +
      ".csv",
    data: csvFileData,
  };
  function toCSV(expenseDetails) {
    const result = [];
    if (expenseDetails && expenseDetails.length) {
      for (const data of expenseDetails) {
        result.push({
          WFS_name: data.wfs_details?.company_name,
          WFS_address:
            data.wfs_details?.address?.Street +
            "\n" +
            data.wfs_details?.address?.Village +
            "\n" +
            data.wfs_details?.address?.District +
            "\n" +
            data.wfs_details?.address?.State +
            "\n" +
            data.wfs_details?.address?.Pincode +
            "\n",
          Expenses_Type: expenseTypeFun(data.expense_type),
          Month: monthNames(data.details?.month || "--"),
          Paid_date: moment(data.paid_date)
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY"),
          Amount: data.amount || "--",
          Due_date: moment(data.due_date)
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY"),
          Reason: data.details?.reason || "--",
          created_at: data.created_at,
          updated_at: data.updated_at,
          created_by:
            data.created_by?.role_id === ADMIN_ROLES.BW_ADMIN
              ? data.created_by?.name
              : data.created_by?.role_id === ADMIN_ROLES.BW_SUPERADMIN
              ? data.created_by?.name
              : data.created_by?.role_id === ADMIN_ROLES.BW_FINANCE
              ? data.created_by?.name
              : data.created_by?.role_id === ADMIN_ROLES.BW_FINANCEHEAD
              ? data.created_by?.name
              : "OTHERS",
          updated_by:
            data.updated_by?.role_id === ADMIN_ROLES.BW_ADMIN
              ? data.updated_by?.name
              : data.updated_by?.role_id === ADMIN_ROLES.BW_SUPERADMIN
              ? data.updated_by?.name
              : data.updated_by?.role_id === ADMIN_ROLES.BW_FINANCE
              ? data.updated_by?.name
              : data.updated_by?.role_id === ADMIN_ROLES.BW_FINANCEHEAD
              ? data.updated_by?.name
              : "OTHERS",
        });
      }
    }
    setCsvFileData(result);
  }

  const GetOperationExpensesCostList = () => {
    dispatch(
      GetOperationLevelCostList({
        page_number: pageNumber,
        page_size: pageSize,
        ops_type: operation_type.WFS,
        expense_type: filterProps.selectedExpenseType || undefined,
        amount: filterProps.amount || undefined,
        date: filterProps.paidDate || undefined,
        due_date: filterProps.nextDueDate || undefined,
        month: filterProps.selectMonth || undefined,
        supplier_id: filterProps.selectedSupplierId || undefined,
        warehouse_id: filterProps.selectedWarehouse || undefined,
      })
    ).then((data) => {
      if (data) {
        setOperationLevelCostList(data.data);
      }
    });
  };
  const handleAddDWFSDialog = () => {
    const wfsFilters = {
      page_number: 1,
      page_size: 200,
    };

    dispatch(GetWfsDetailsSku(wfsFilters));
    setOpenAddWFSDetailsDialog(true);
    setOpenEditSingleOperationCost(false);
  };

  const handleCloseAddWFSDialog = () => {
    setOpenAddWFSDetailsDialog(false);
  };

  const handleOpenCommonFilter = (data) => {
    setFilterExpenseType(data);
    setOpenCommonFilterDialog(true);
    const wfsFilters = {
      page_number: 1,
      page_size: 200,
    };
    dispatch(GetWfsDetailsSku(wfsFilters));
  };
  const handleCloseCommonFilterDialog = () => {
    setOpenCommonFilterDialog(false);
  };
  const handleEditWfsCost = (data) => {
    setSingleOperationCostDetails(data);
    const wfsFilters = {
      page_number: 1,
      page_size: 200,
    };
    dispatch(GetWfsDetailsSku(wfsFilters));
    setOpenAddWFSDetailsDialog(true);
    setOpenEditSingleOperationCost(true);
  };
  const expenseTypeFun = (expenses_type) => {
    switch (expenses_type) {
      case expense_type.RENT:
        return "RENT";
      case expense_type.WFI:
        return "WI-FI";
      case expense_type.ELECTRICITY:
        return "ELECTRICITY";
      case expense_type.CONSUMABLES:
        return "CONSUMABLES";
      case expense_type.ALLOWANCES:
        return "ALLOWANCES";

      case expense_type.FILLING_CHARGES:
        return "FILLING CHARGES";
      case expense_type.OTHERS:
        return "OTHERS";
      default:
        return "";
    }
  };
  const monthNames = (month) => {
    switch (month) {
      case 1:
        return "JAN";
      case 2:
        return "FEB";
      case 3:
        return "MARCH";
      case 4:
        return "APRIL";
      case 5:
        return "MAY";
      case 6:
        return "JUNE";
      case 7:
        return "JULY";
      case 8:
        return "AUG";
      case 9:
        return "SEP";
      case 10:
        return "OCT";
      case 11:
        return "NOV";
      case 12:
        return "DESC";
      default:
        return "";
    }
  };
  const handleSubmitAddWFSDialog = (props) => {
    console.log(props);

    if (openEditSingleOperationCost === false) {
      if (
        props.selectedExpenseType !== "" &&
        props.paidDate !== "" &&
        props.amount !== "" &&
        props.selectedWFS !== ""
      ) {
        console.log("---------Add------------------");
        const reqObj = {
          reason: props.purpose || undefined,
          month: props.selectMonth || undefined,
          wfs_id: props.selectedWFS,
          operation_type: operation_type.WFS,
          expense_type: props.selectedExpenseType,
          date: props.paidDate,
          due_date: props.nextDueDate || undefined,
          amount: parseInt(props.amount),
          payment_receipt: props.reciptUrl || null,
          account_number: props.accountNumber || null,
        };
        dispatch(PostAddOperationLevelExpenseCost(reqObj));
        setOpenAddWFSDetailsDialog(false);
        setTimeout(() => {
          GetOperationExpensesCostList();
        }, 1000);
      } else {
        toast.error("Please Fill The  Fields", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      if (
        props.selectedExpenseType !== "" &&
        props.paidDate !== "" &&
        props.amount !== "" &&
        props.selectedWFS !== ""
      ) {
        console.log("---------Update------------------");
        console.log(props);
        const reqObj = {
          reason: props.purpose,
          month: props.selectMonth,
          id: props.seqid,
          wfs_id: props.selectedWFS,
          operation_type: operation_type.WFS,
          expense_type: props.selectedExpenseType,
          date: props.paidDate,
          due_date: props.nextDueDate || undefined,
          amount: parseInt(props.amount),
          payment_receipt: props.reciptUrl || null,
          account_number: props.accountNumber || undefined,
        };
        dispatch(PutUpdateOperationLevelExpenseCost(reqObj));
        setOpenAddWFSDetailsDialog(false);
        setTimeout(() => {
          GetOperationExpensesCostList();
        }, 1000);
      } else {
        toast.error("Please Fill Fields", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const handleCommonFilterDialog = (props) => {
    console.log(props);
    setFilterProps(props);
    dispatch(
      GetOperationLevelCostList({
        page_number: pageNumber,
        page_size: pageSize,
        ops_type: operation_type.WFS,
        expense_type: props.selectedExpenseType || undefined,
        amount: props.amount || undefined,
        date: props.paidDate || undefined,
        due_date: props.nextDueDate || undefined,
        month: props.selectMonth || undefined,
        wfs_id: props.selectedWFS?.wfs_id || undefined,
      })
    ).then((data) => {
      if (data) {
        setOperationLevelCostList(data.data);
      } else {
        console.log("data", data);
        setOperationLevelCostList([]);
      }
    });
    setOpenCommonFilterDialog(false);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: "10px",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={handleAddDWFSDialog}
        >
          <AddCircleOutline /> Add
        </Button>
        <Button variant="contained" onClick={() => handleOpenCommonFilter(200)}>
          <FilterList />
          Filter
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "column",
            },
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Page Size:
          </InputLabel>
          <Select
            variant="standard"
            sx={{ fontSize: "12px", fontWeight: "bold", width: "80px" }}
            value={pageSize}
            label="Page Size"
            onChange={(event) => setPageSize(event.target.value)}
            // }
          >
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value="300">500</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              if (pageNumber - 1 > 0) {
                setPageNumber(() => pageNumber - 1);
              }
            }}
          >
            <FirstPage sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }} />
          </IconButton>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            {pageNumber}
          </Typography>
          <IconButton
            onClick={() => {
              if (
                operationLevelCostList.length === pageSize &&
                pageNumber + 1 > 0
              ) {
                setPageNumber(() => pageNumber + 1);
              }
            }}
          >
            <LastPage
              sx={{
                color:
                  operationLevelCostList.length === pageSize ? "blue" : "gray",
              }}
            />
          </IconButton>
        </Box>
        <Box sx={{ mt: "5px" }}>
          <CSVLink {...csvLink}>
            <DownloadForOffline
              onClick={() => toCSV(operationLevelCostList)}
              sx={{ fontSize: 33 }}
            />
          </CSVLink>
        </Box>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#f4ecf7" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  S.no
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  WFS Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  WFS Address
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Expense Type
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Month
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Paid Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Amount
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Next Due Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Purpose
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Account Number
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Created At
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Updated At
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Created by
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Updated by
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Download Receipt
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    border: "1.4px solid #ecf0f1",
                    textAlign: "center",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {operationLevelCostList !== undefined &&
              operationLevelCostList !== null &&
              operationLevelCostList.length > 0 ? (
                <>
                  {operationLevelCostList.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.wfs_details?.company_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.wfs_details?.address?.Street !== undefined &&
                          data.wfs_details?.address?.Street !== null
                            ? data.wfs_details?.address?.Street
                            : "" + "," + data.wfs_details?.address?.Village !==
                                undefined &&
                              data.wfs_details?.address?.Village !== null
                            ? data.wfs_details?.address?.Village
                            : ""}
                          <br />
                          {data.wfs_details?.address?.State !== undefined &&
                          data.wfs_details?.address?.State !== null
                            ? data.wfs_details?.address?.State
                            : "" + "," + data.wfs_details?.address?.District !==
                                undefined &&
                              data.wfs_details?.address?.District !== null
                            ? data.wfs_details?.address?.District
                            : ""}
                          <br />
                          {data.wfs_details?.address?.Pincode &&
                          data.wfs_details?.address?.Pincode
                            ? data.wfs_details?.address?.Pincode
                            : ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {expenseTypeFun(data.expense_type)}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {monthNames(data.details.month)}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {moment(data.paid_date).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.amount}
                        </TableCell>

                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.due_date !== null && data.due_date !== undefined
                            ? moment(data.due_date)
                                .tz("Asia/Kolkata")
                                .format("DD-MM-YYYY")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details.reason || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.details?.account_number
                            ? data.details?.account_number
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {moment(data.created_at).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {moment(data.updated_at).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.created_by?.role_id === ADMIN_ROLES.BW_ADMIN
                            ? "ADMIN"
                            : data.created_by?.role_id ===
                              ADMIN_ROLES.BW_SUPERADMIN
                            ? "SUPER ADMIN"
                            : data.created_by?.role_id ===
                              ADMIN_ROLES.BW_FINANCE
                            ? "FINANCE"
                            : data.created_by?.role_id ===
                              ADMIN_ROLES.BW_FINANCEHEAD
                            ? "FINANCE HEAD"
                            : "OTHERS"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.updated_by?.role_id === ADMIN_ROLES.BW_ADMIN
                            ? "ADMIN"
                            : data.updated_by?.role_id ===
                              ADMIN_ROLES.BW_SUPERADMIN
                            ? "SUPER ADMIN"
                            : data.updated_by?.role_id ===
                              ADMIN_ROLES.BW_FINANCE
                            ? "FINANCE"
                            : data.updated_by?.role_id ===
                              ADMIN_ROLES.BW_FINANCEHEAD
                            ? "FINANCE HEAD"
                            : "OTHERS"}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          {data.payment_receipt !== null ? (
                            <span
                              style={{ color: "#068fff", cursor: "pointer" }}
                              onClick={() =>
                                data.payment_receipt !== "" &&
                                dispatch(getFiles(data.payment_receipt))
                              }
                            >
                              &nbsp;
                              <Download />
                            </span>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1.4px solid #ecf0f1",
                            textAlign: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleEditWfsCost(data)}
                          >
                            edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={14} align="center">
                    <Typography sx={{ color: "red" }}>No Data Found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {openAddWFSDetailsDialog && (
        <WFSAddCostDialog
          openAddWFSDetailsDialog={openAddWFSDetailsDialog}
          handleCloseAddWFSDialog={handleCloseAddWFSDialog}
          handleSubmitAddWFSDialog={handleSubmitAddWFSDialog}
          singleOperationCostDetails={singleOperationCostDetails}
          openEditSingleOperationCost={openEditSingleOperationCost}
        />
      )}
      {openCommonFilterDialog && (
        <CommonOperationLevelCostFilterDialog
          openCommonFilterDialog={openCommonFilterDialog}
          handleCloseCommonFilterDialog={handleCloseCommonFilterDialog}
          filterExpenseType={filterExpenseType}
          handleCommonFilterDialog={handleCommonFilterDialog}
        />
      )}
    </Box>
  );
}

export default WFSOperatingLevelCostMain;
