import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const GetAdminBooknowMsg = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    // try {
    //   let response = await api.get(`/SSM/superservice/getBookNowMessage`);
    //   if (response.status === httpStatus.SUCCESS) {
    //     dispatch({
    //       type: actionType.GET_BOOKNOW_MESSAGE,
    //       payload: response.data,
    //     });
    //   } else {
    //     // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
    //   }
    // } catch (error) {
    //   CustomConsole(error);
    // }
  };
};

export const GetAdminSetupSettings = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/setupconfig/app_settings`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SETUP_SETTINGS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetAdminBankdowntimeMsg = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    // try {
    //   let response = await api.get(`/SSM/bankDownTime`);
    //   if (response.status === httpStatus.SUCCESS) {
    //     dispatch({
    //       type: actionType.GET_BANKDOWNTIME_MESSAGE,
    //       payload: response.data,
    //     });
    //   } else {
    //     // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
    //   }
    // } catch (error) {
    //   CustomConsole(error);
    // }
  };
};

export const UpdateBooknowMsg = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/superservice/updateBookNowMessage`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_BOOKNOW_MESSAGE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateBankdowntimeMsg = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/updatemessageBankDowntime`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_BANKDOWNTIME_MESSAGE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateSetupConfig = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/setupconfig/app_settings`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_SETUP_CONFIG,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const UpdateReferralCodeSettingAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/admin/referralCode/adminSettings`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_REFERRAL_CODE,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const UpdateReferralSettingAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/referral/settings`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_REFERRAL_SETTINGS,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        dispatch({
          type: actionType.UPDATE_REFERRAL_SETTINGS,
          payload: response.data,
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      dispatch({
        type: actionType.UPDATE_REFERRAL_SETTINGS,
        payload: error.response.data,
      });
    }
  };
};
export const GetReferalCodeSettings = (referalType) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      const referralTypeQry = referalType
        ? `?referral_type=${referalType}`
        : "";
      let response = await api.get(
        `/SSM/admin/referralCode/getReferralSettings` + referralTypeQry
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REFERRAL_SETTINGS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const DeliverySlotSettingAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/admin/deliverySlots/createSlots`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DELIVERY_SLOT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetDeliverySlotSettingAction = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/supplierAdmin/deliverySlots/getAvailableSlots`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DELIVERY_SLOT,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const PostForceConsumerAppsUpdate = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/admin/app_updates`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_FORCE_CONSUMER_APPS_UPDATE,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const PutInScanControlBlocking = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SCM/settings/inscan/control`, reqObj);
      // if (response.status === httpStatus.SUCCESS) {
      //   dispatch({
      //     type: actionType.PUT_BLOCK_INSCAN_SETTINGS,
      //     payload: response.data,
      //   });
      //   if (response.data.error === false) {
      //     toast.success(response.data.msg, {
      //       position: "bottom-right",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "colored",
      //     });
      //   }
      // } else {
      //   // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      //   toast.error(response.data.msg, {
      //     position: "bottom-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      // }
      //@Removed/console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_BLOCK_INSCAN_SETTINGS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const PostAddOrUpdateGiftCard = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/update/settings`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_ADD_UPDATE_GIFT_CARD,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetGiftCarData = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/giftCard/get/settings`);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_GIFT_CARD_DATA,
          payload: response.data,
        });
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      CustomConsole(error);
      return error.response.data;
    }
  };
};
export const PostCreateSingleUserGiftCard = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/create`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_CREATE_SINGLE_USER_GIFT_CARD,
          payload: response?.data,
        });

        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return response?.data;
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return response?.data;
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response?.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return response?.data;
      }
    } catch (error) {
      CustomConsole(error);

      toast.error(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return error?.response?.data;
    }
  };
};

export const GetListOfGiftCardDetails = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/giftCard/get/details`, {
        params: props,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_LIST_OF_GIFT_CARD_DETAILS,
          payload: response.data,
        });
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
};

export const GetPhysicalGiftCards = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/giftCard/get/batch`, {
        params: props,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const CreatePhysicalGiftCard = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/create/physical`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
          });
          return response?.data;
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
          });
          return response?.data;
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response?.data.msg || response?.data.message, {
          position: "bottom-right",
          autoClose: 3000,
        });
        return response?.data;
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error?.response?.data?.msg || error?.response?.data.message, {
        position: "bottom-right",
        autoClose: 3000,
      });
      return error?.response?.data;
    }
  };
};

//function to check user details
export const CheckUserAvailableDetails = (object) => {
  //CustomConsole("SearchAvailability: " + pincode);
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/verifyUser`, object);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        return response?.data;
      } else {
        toast.error(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response?.data;
      }
    } catch (error) {
      toast.dark("Unable to verify user details please try again later", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error?.response?.data;
    }
  };
};

export const SettlePhysicalGiftCard = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/giftCard/update/batch/payments`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        return response.data;
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const PhysicalGiftCardChangeStatus = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/giftCard/update/batch/status`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        if (response.data.error === false) {
          toast.success(response?.data?.msg, {
            position: "bottom-right",
            autoClose: 3000,
          });
          return response?.data;
        } else {
          toast.error(response?.data?.msg, {
            position: "bottom-right",
            autoClose: 3000,
          });
          return response?.data;
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
        });
        return response?.data;
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
      });
      return error?.response?.data;
    }
  };
};

export const PhysicalGiftCardEditDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/giftCard/update/batch/details`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        if (response.data.error === false) {
          toast.success(response?.data?.msg, {
            position: "bottom-right",
            autoClose: 3000,
          });
          return response?.data;
        } else {
          toast.error(response?.data?.msg, {
            position: "bottom-right",
            autoClose: 3000,
          });
          return response?.data;
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
        });
        return response?.data;
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
      });
      return error?.response?.data;
    }
  };
};
