import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

function GiftCardFilterDialog(props) {
  const [status, setStatus] = useState("");
  const [createdDate, setCreatedDate] = useState(moment().subtract(180, "days").format("YYYY-MM-DD"));
  const [expiryDate, setExpiryDate] = useState(moment().add(7, "days").format("YYYY-MM-DD"));
  const [giftCardId, setGiftCardId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [category, setCategory] = useState("");

  const handleCloseGiftCard = () => {
    props.handleCloseGiftCard();
  };
  const handleFilterGiftCard = () => {
    props.handleFilterGiftCard({
      status: status,
      createdDate: createdDate,
      expiryDate: expiryDate,
      giftCardId: giftCardId,
      phoneNumber: phoneNumber,
      category: category,
    });
  };

  const handleClearAll = () => {
    setStatus("");
    setCreatedDate("");
    setExpiryDate("");
    setGiftCardId("");
    setPhoneNumber("");
    setCategory("");
  };
  return (
    <Dialog open={props.openGiftCardFilterDialog}>
      <DialogTitle align="center">Filter</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <TextField
            label="Gift Card Id"
            variant="outlined"
            size="small"
            sx={{ width: 220 }}
            value={giftCardId}
            onChange={(event) => setGiftCardId(event.target.value)}
          />
          <TextField
            type="number"
            size="small"
            label="Phone Number"
            sx={{ width: 164 }}
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <FormControl sx={{ width: 220 }} size="small">
            <InputLabel>Select Status</InputLabel>
            <Select
              value={status}
              label="Select Month"
              onChange={(event) => setStatus(event.target.value)}
            >
              <MenuItem value={100}>PAYMENT_PENDING</MenuItem>
              <MenuItem value={200}>READY_TO_CLAIM</MenuItem>
              <MenuItem value={300}>ALREADY_CLAIMED</MenuItem>
              <MenuItem value={400}>EXPIRED</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="date"
            size="small"
            label="From Date"
            value={createdDate}
            onChange={(event) => setCreatedDate(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <FormControl sx={{ width: 220 }} size="small">
            <InputLabel>Select Category</InputLabel>
            <Select
              value={category}
              onChange={(event) => setCategory(event.target.value)}
              label="Select Category"
            >
              <MenuItem value={"RECEIVED"}>RECEIVED</MenuItem>
              <MenuItem value={"SENT"}>SENT</MenuItem>
            </Select>
          </FormControl>

          <TextField
            type="date"
            size="small"
            label="To Date"
            value={expiryDate}
            onChange={(event) => setExpiryDate(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClearAll}>
          Clear
        </Button>
        <Button variant="contained" color="error" onClick={handleCloseGiftCard}>
          close
        </Button>
        <Button variant="contained" onClick={handleFilterGiftCard}>
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GiftCardFilterDialog;
