import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RecycleSingleOrderDialog from "./RecycleSingleOrderDialog";
import {
  GetRequestedRecycleOrderList,
  PutAcceptOrRejectRecycleOrder,
} from "../Redux/Actions/RecycleOrdersAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import {
  ADMIN_ROLES,
  RECYCLE_STATUS_CODE,
} from "../../Components/AppMeta/appMetaConfig";
import RecycleFilterDialog from "../wfsCanOrders/RecycleFilterDialog";
import moment from "moment";

function AdminRecycleOrdersMain() {
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const recycleordersdetails = useSelector(
    (state) => state.recycleordersdetails
  );
  let products = skuContainerDetails?.getAvailableProducts?.filter(
    (type) =>
      type?.product_details?.capacity === "20_Liters" &&
      type?.product_details?.material === "PET"
  );
  console.log(products);
  const [openRecycleSingleOrderDialog, setOpenRecycleSingleOrderDialog] =
    useState(false);
  const [orderStatusSelect, setOrderStatusSelect] = React.useState("");
  const [openRecycleFilterDialog, setOpenRecycleFilterDialog] =
    React.useState(false);

  const [recycleSingleOrderDetails, setRecycleSingleOrderDetails] =
    React.useState({});
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const pageSizeList = [25, 50, 100, 200, 500, 1000, 2500, 5000];

  React.useEffect(() => {
    hitListOfOrdersApi();
    dispatch(GetAvailableProducts());
  }, [pageNumber, pageSize]);

  const hitListOfOrdersApi = () => {
    dispatch(
      GetRequestedRecycleOrderList({
        page_number: pageNumber,
        page_size: pageSize,
        status: orderStatusSelect || undefined,
      })
    );
  };

  const orderStatus = (order_status) => {
    switch (order_status) {
      case RECYCLE_STATUS_CODE.FINISHED:
        return "FINISHED";
      case RECYCLE_STATUS_CODE.CANCELLED:
        return "CANCELLED";
      case RECYCLE_STATUS_CODE.REJECTED:
        return "REJECTED";
      case RECYCLE_STATUS_CODE.ACCEPTED:
        return "ACCEPTED";
      case RECYCLE_STATUS_CODE.REQUESTED:
        return "REQUESTED";
      case RECYCLE_STATUS_CODE.STARTED:
        return "STARTED";
      default:
        return "";
    }
  };
  const handleCloseRecycleScanningDialog = () => {
    setOpenRecycleSingleOrderDialog(false);
  };
  const handleSingleRecycleOrder = (data, type) => {
    console.log(data);
    console.log(type);
    setRecycleSingleOrderDetails({ data: data, type: type });
    dispatch(
      GetRequestedRecycleOrderList({
        order_id: data.details?.order_id,
      })
    );
    setOpenRecycleSingleOrderDialog(true);
  };

  const handleAcceptOrReject = (typeofbutton) => {
    console.log(typeofbutton);
    if (typeofbutton.data === "ACCEPT") {
      const reqObj = {
        order_id: typeofbutton.order_id,
        is_accept: true,
      };
      dispatch(PutAcceptOrRejectRecycleOrder(reqObj)).then((data) => {
        console.log(data);
        if (data) {
          // dispatch(
          //   GetRequestedRecycleOrderList({
          //     order_id: typeofbutton.order_id,
          //   })
          // );
          hitListOfOrdersApi();
        }
      });
    } else {
      const reqObj = {
        order_id: typeofbutton.order_id,
        is_reject: true,
      };
      dispatch(PutAcceptOrRejectRecycleOrder(reqObj)).then((data) => {
        if (data) {
          dispatch(
            GetRequestedRecycleOrderList({
              page_number: pageNumber,
              page_size: pageSize,
            })
          );
        }
      });
      setOpenRecycleSingleOrderDialog(false);
    }
  };

  const handleCloseRecycleSingleDialog = () => {
    hitListOfOrdersApi();
    setOpenRecycleSingleOrderDialog(false);
  };

  const handleCloseRecycleFilter = () => {
    setOpenRecycleFilterDialog(false);
  };
  const handleFilterRecycleStatus = () => {
    hitListOfOrdersApi();
    setOpenRecycleFilterDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Box sx={{ mt: "5px" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpenRecycleFilterDialog(true)}
          >
            Filter
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "8px" }}>Page size:</InputLabel>
          <Select
            variant="standard"
            label="Page Size"
            value={pageSize}
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              width: "60px",
            }}
            onChange={(e) => {
              setPageSize(e.target.value);
            }}
          >
            {pageSizeList.map((data, key) => {
              return (
                <MenuItem value={data} key={key}>
                  {data}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        {/* Page Number */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "8px" }}>Page number:</InputLabel>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstPageIcon
              sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            />
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {pageNumber}
            </Typography>
            <LastPageIcon
              sx={{
                color:
                  pageNumber > 0 &&
                  recycleordersdetails?.recycleOrderList?.length === pageSize
                    ? "blue"
                    : "gray",
              }}
              onClick={() => {
                if (
                  pageNumber > 0 &&
                  recycleordersdetails?.recycleOrderList?.length === pageSize
                ) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#c6d0e3" }}>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                }}
                colSpan={3}
                align="center"
              >
                Quantity
              </TableCell>
              <TableCell
                colSpan={3}
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",

                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                WFS
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  border: "1.4px solid #ecf0f1",
                  borderBottom: "0px solid #ecf0f1",
                }}
              ></TableCell>
            </TableRow>
            <TableRow sx={{ bgcolor: "#c6d0e3" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                  padding: "0px",
                }}
              >
                S.no
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                  padding: "0px",
                }}
              >
                Order Id
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Products
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                Physical Damaged
              </TableCell>
              <TableCell
                sx={{
                  padding: "5px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Qr/Barcode Damaged
              </TableCell>
              <TableCell
                sx={{
                  padding: "5px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Expired
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  padding: "5px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Owner
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Phone
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Product Type
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Created On
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Created By
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Created By Role
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Updated By
              </TableCell>
              <TableCell
                sx={{
                  padding: "0px",
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                  borderTop: "0px solid #ecf0f1",
                }}
              >
                Updated By Role
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recycleordersdetails.recycleOrderList !== undefined &&
            recycleordersdetails?.recycleOrderList !== null &&
            recycleordersdetails?.recycleOrderList.length > 0 ? (
              <>
                {recycleordersdetails?.recycleOrderList.map((data, index) => {
                  let products =
                    skuContainerDetails?.getAvailableProducts?.filter(
                      (type) =>
                        type?.product_details?.capacity ===
                          data.details.capacity &&
                        type?.product_details?.material ===
                          data.details.material
                    );
                  return (
                    <TableRow
                      key={index}
                      onClick={() =>
                        handleSingleRecycleOrder(
                          data,
                          products[0].product_details.is_qr
                        )
                      }
                    >
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                          textDecoration: "underline",
                          color: "blue",
                        }}
                      >
                        {data.details.order_id}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.capacity + "-" + data.details.material}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.physical_damage}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.qr_barcode_damage}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details.expired}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.wfs_details.company_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.wfs_details.owner_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.wfs_details.owner_phone}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {orderStatus(data.status)}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {products[0].product_details.is_qr === true
                          ? "QR"
                          : "QR-LESS"}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {moment(data.created_at).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.created_user.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.created_user.role_id === ADMIN_ROLES.BW_WFSADMIN
                          ? "WFS INCHARGE"
                          : "Admin"}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.updated_user.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.updated_user.role_id === ADMIN_ROLES.BW_ADMIN
                          ? "ADMIN"
                          : "SUPER ADMIN"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              "No Data Found"
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <RecycleSingleOrderDialog
        openRecycleSingleOrderDialog={openRecycleSingleOrderDialog}
        handleCloseRecycleScanningDialog={handleCloseRecycleScanningDialog}
        recycleSingleOrderDetails={recycleSingleOrderDetails}
        handleAcceptOrReject={handleAcceptOrReject}
        handleCloseRecycleSingleDialog={handleCloseRecycleSingleDialog}
      />
      {openRecycleFilterDialog && (
        <RecycleFilterDialog
          openRecycleFilterDialog={openRecycleFilterDialog}
          setOrderStatusSelect={setOrderStatusSelect}
          orderStatusSelect={orderStatusSelect}
          handleClose={handleCloseRecycleFilter}
          handleFilter={handleFilterRecycleStatus}
        />
      )}
    </>
  );
}

export default AdminRecycleOrdersMain;
