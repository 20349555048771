/**
 * @author vinay kumar
 * @description Supplier Assigned Area Card
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay

import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomConsole from "../../CustomConsole";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  UpdateTransportCharge,
  GetDistributorDetails,
  RemoveTransportCharge,
} from "../Redux/Actions/appSettingsAction";
import { toast } from "react-toastify";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function DistributorAssignedAreaCard({
  pwdPincodeAreas,
  distriNum,
  adminRole,
  setAdminRoleId,
}) {
  CustomConsole(pwdPincodeAreas);
  CustomConsole(distriNum);

  const [areaName, setAreaName] = React.useState("");
  const [charge, setCharge] = React.useState("");
  const [id, setId] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [agreeDialog, setAgreeDialog] = React.useState(false);

  //toast
  const [deleteToast, setDeleteToast] = React.useState(false);
  const [chargeToast, setChargeToast] = React.useState(false);
  const [distributorDetails, setDistributorDetails] = React.useState([]);
  const dispatch = useDispatch();
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);

  React.useEffect(() => {
    if (deleteToast === true && appSettingsDetails.error === false) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(appSettingsDetails);
      setDeleteToast(false);
    }
    CustomConsole("in---");
    CustomConsole(appSettingsDetails);
    CustomConsole(chargeToast);
    if (chargeToast === true && appSettingsDetails.error === false) {
      toast.success(appSettingsDetails.updatingMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChargeToast(false);
    }
  }, []);

  const updateTransCharge = (data) => {
    setAreaName(data?.row?.Area);
    setCharge(data?.row?.TransPortcharges);
    setId(data.row?.queryId);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setAgreeDialog(false);
  };
  const updateTransChargeArea = () => {
    CustomConsole(
      `The area is ${areaName} and the charge is ${charge} and the id is ${id}`
    );
    const reqObj = {
      id: id,
      transportation_charges: charge,
    };
    if (reqObj.id !== "" && reqObj.transportation_charges !== "") {
      dispatch(UpdateTransportCharge(reqObj)).then((data) => {
        if (!data?.error) {
          dispatch(GetDistributorDetails(distriNum));
        }
      });
      setOpenDialog(false);
      setChargeToast(true);
    } else {
      toast.error("Transport charges cannot be empty", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  React.useEffect(() => {
    dispatch(GetDistributorDetails(distriNum));
  }, [distriNum]);

  pwdPincodeAreas.sort((a, b) => a.id - b.id);

  const deleteBtn = (data) => {
    setId(data?.row?.queryId);
    setAgreeDialog(true);
  };

  const removeTransCharge = () => {
    const reqObj = {
      id: id,
    };

    if (reqObj.id !== "") {
      dispatch(RemoveTransportCharge(reqObj)).then((data) => {
        if (!data?.error) {
      dispatch(GetDistributorDetails(distriNum));
        }
      });
      setDeleteToast(true);
    }
    setAgreeDialog(false);
  };

  const handleChangeCharges = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" || (Number(inputValue) >= 0 && !isNaN(inputValue))) {
      setCharge(e.target.value);
    }
  };

  React.useEffect(() => {
    let distributorData = [];
    pwdPincodeAreas !== undefined &&
      pwdPincodeAreas !== null &&
      pwdPincodeAreas.length > 0 &&
      pwdPincodeAreas.map((content, key) => {
        CustomConsole(content);
        distributorData[key] = {
          id: key + 1,
          Area: content.area_name,
          Pincode: content.pincode,
          TransPortcharges: content.transportation_charges,
          CreatedDate: moment(content.created_at).format("YYYY-MM-DD"),
          Edit: true,
          supplierId: content.supplier_id,
          queryId: content.id,
        };
      });
    setDistributorDetails(distributorData);
  }, [pwdPincodeAreas]);

  const columns = [
    {
      field: "id",
      headerName: "S No",
      headerClassName: "super-app-theme--header",
      width: 90,
    },
    {
      field: "Area",
      headerName: "Area",
      headerClassName: "super-app-theme--header",
      width: 200,
    },

    {
      field: "Pincode",
      headerName: "Pincode",
      headerClassName: "super-app-theme--header",
      width: 130,
    },
    {
      field: "TransPortcharges",
      headerName: "Transport Charges",
      headerClassName: "super-app-theme--header",
      width: 130,
    },

    {
      field: "CreatedDate",
      headerName: "Created Date",
      headerClassName: "super-app-theme--header",
      type: "number",
      width: 120,
    },

    {
      field: "Edit",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 100,

      renderCell: (params) => (
        <>
          <DeleteIcon
            onClick={() => {
              deleteBtn(params);
            }}
          />
          <EditIcon
            onClick={() => {
              updateTransCharge(params);
            }}
          />
        </>
      ),
    },
  ];

  const handleCellClick = (params) => {};
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          padding: "15px",
          gap: "10px",
          // width: { lg: "80%", md: "80%", xs: "360px" },
        }}
      >
        <Card
          // elevation={24}
          sx={{
            //  minWidth: "300px",
            //   backgroundColor: "#41638A",
            //   color: "white",
            maxHeight: "500px",
            overflow: "hidden",
            // paddingLeft: { xs: "90px" },
          }}
        >
          <DataGrid
            rows={distributorDetails}
            columns={columns}
            onCellClick={handleCellClick}
            checkboxSelection={false}
            hideFooter={false}
            disableRowSelectionOnClick
                          sx={{
              "& .MuiDataGrid-footerContainer": {
                position: "sticky",
                bottom: 0,
                zIndex: 1000,
                backgroundColor: "#f4f4f4",
              },
            }}
            components={{
              Toolbar: GridToolbar, // Adding a toolbar for filtering and searching
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pagination
            pageSizeOptions={[25, 30, 40, 50, 100]}
          />
        </Card>
      </Box>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>
          <b>{areaName}</b>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            variant="standard"
            type="number"
            onKeyDown={(e) =>
              (e.key === "e" || e.key === "-" || e.key === "+") &&
              e.preventDefault()
            }
            label="Transport Charge"
            value={charge}
            inputProps={{ pattern: "[0-9]*" }}
            onChange={handleChangeCharges}
          />
          <Button
            variant="contained"
            sx={{
              float: "right",
              marginTop: "8px",
            }}
            onClick={updateTransChargeArea}
          >
            Update
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={agreeDialog} onClose={handleClose}>
        <DialogTitle>Are you sure to delete area?</DialogTitle>
        <DialogActions>
          <Button onClick={removeTransCharge}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DistributorAssignedAreaCard;
