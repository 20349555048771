import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetAllBanners,
  UpdateBanners,
} from "../Redux/Actions/adminBannersAction";
import { UploadFile, getFiles } from "../Redux/Actions/fileHandlingAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomConsole from "../../CustomConsole";

export default function UpdateBannerDetailsDialog({
  setIsUpdateBannerDetailsDialog,
  isUpdateBannerDetailsDialog,
  bannerDetails,
  bannerId,
  pageNumber,
  createdAt,
  isEnabled,
}) {
  const dispatch = useDispatch();
  const bannersData = useSelector((state) => state.adminBannersData);

  const [message, setMessage] = React.useState("");
  const [bannerHeader, setBannerHeader] = React.useState("");
  const [displayOrder, setDisplayOrder] = React.useState(0);
  const [expiryHrs, setExpiryHrs] = React.useState(1);
  const [bannerStatus, setBannerStatus] = React.useState(false);
  const [updateBannersFlag, setUpdateBannersFlag] = React.useState(false);

  //refs for file upload
  const minScreenFileRef = React.useRef(null);
  const maxScreenFileRef = React.useRef(null);

  //image file states
  const [minScreenImgFile, setMinScreenImgFile] = React.useState("");
  const [maxScreenImgFile, setMaxScreenImgFile] = React.useState("");

  //image upload flags
  const [minScreenFlag, setMinScreenFlag] = React.useState(false);
  const [maxScreenFlag, setMaxScreenFlag] = React.useState(false);

  const fileUploadDetails = useSelector((state) => state.fileHandler);

  React.useEffect(() => {
    if (bannersData.error === false && updateBannersFlag === true) {

      setUpdateBannersFlag(false);
      setIsUpdateBannerDetailsDialog(false);
    }
  }, [bannersData]);

  React.useEffect(() => {
    setMessage(bannerDetails.message);
    setBannerHeader(bannerDetails.banner_header);
    setDisplayOrder(bannerDetails.display_order);
    setExpiryHrs(bannerDetails.expiry_date);
    setBannerStatus(bannerDetails.status);
    setMinScreenImgFile(bannerDetails.file_names[0]);
    setMaxScreenImgFile(bannerDetails.file_names[1]);
  }, [bannerDetails]);

  React.useEffect(() => {
    if (minScreenFlag && fileUploadDetails.isFileUploaded === true) {
      setMinScreenImgFile(fileUploadDetails.fileName);
      setMinScreenFlag(false);
    }
    if (maxScreenFlag && fileUploadDetails.isFileUploaded === true) {
      setMaxScreenImgFile(fileUploadDetails.fileName);
      setMaxScreenFlag(false);
    }
  }, [fileUploadDetails]);

  const handleClose = () => {
    setIsUpdateBannerDetailsDialog(false);
  };
  const handleChange = (event) => {
    setBannerStatus(event.target.checked);
  };
  const handleUpdateBtn = () => {
    const reqObj = {
      message: message,
      banner_header: bannerHeader,
      status: bannerStatus,
      display_order: displayOrder,
      expiry_date: expiryHrs,
      min_screen_width_file: minScreenImgFile,
      max_screen_width_file: maxScreenImgFile,
    };
    CustomConsole(reqObj);
    dispatch(UpdateBanners(bannerId, reqObj));
    setUpdateBannersFlag(true);
    setTimeout(() => {
      dispatch(GetAllBanners(pageNumber - 1, createdAt, isEnabled));
    }, 1500);
    // setUpdateBannerTypeFlag(true);
  };

  const handleRefUpload = (btn) => {
    if (btn === "btn-max") {
      maxScreenFileRef.current.click();
    } else if (btn === "btn-min") {
      minScreenFileRef.current.click();
    }
  };

  const minScreenFileChange = (e) => {
    // CustomConsole(e.target.files[0]);
    // dispatch(UploadFile(e.target.files[0]));
    // setMinScreenFlag(true);
    const file = e.target.files[0];
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtension)) {
        dispatch(UploadFile(file));
        setMinScreenFlag(true);
        // setLoading(true);
      } else {
        toast.dark("Please upload only images", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
        e.target.value = "";
        // setLoading(false);
      }
    }
  };
  const maxScreenFileChange = (e) => {
    // CustomConsole(e.target.files[0]);
    // dispatch(UploadFile(e.target.files[0]));
    // setMaxScreenFlag(true);
    const file = e.target.files[0];
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtension)) {
        dispatch(UploadFile(file));
        setMaxScreenFlag(true);
        // setLoading(true);
      } else {
        toast.dark("Please upload only images", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
        e.target.value = "";
        // setLoading(false);
      }
    }
  };

  const downloadFile = (eye) => {
    if (eye === "eye-min") {
      dispatch(getFiles(minScreenImgFile));
    } else if (eye === "eye-max") {
      dispatch(getFiles(maxScreenImgFile));
    }
  };

  return (
    <div>
      <Dialog
        open={isUpdateBannerDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Message"
                variant="outlined"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Banner header"
                variant="outlined"
                value={bannerHeader}
                onChange={(event) => setBannerHeader(event.target.value)}
              />
              <TextField
                type="number"
                id="outlined-basic"
                label="Display order"
                variant="outlined"
                value={displayOrder}
                onChange={(event) => setDisplayOrder(event.target.value)}
              />
              <TextField
                type="number"
                id="outlined-basic"
                label="Expiry hours"
                variant="outlined"
                value={expiryHrs}
                onChange={(event) => setExpiryHrs(event.target.value)}
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label={`${bannerStatus ? "Enabled" : "Disabled"}`}
                  onChange={handleChange}
                  checked={bannerStatus}
                />
              </FormGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                ref={minScreenFileRef}
                onChange={minScreenFileChange}
              />

              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleRefUpload("btn-min");
                }}
              >
                Upload image for small screen size
              </Button>
              {minScreenImgFile !== "" ? (
                <VisibilityIcon
                  sx={{
                    marginLeft: "20px",
                    marginTop: "5px",
                  }}
                  onClick={() => {
                    downloadFile("eye-min");
                  }}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                ref={maxScreenFileRef}
                onChange={maxScreenFileChange}
              />

              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleRefUpload("btn-max");
                }}
              >
                Upload image for max screen size
              </Button>
              {maxScreenImgFile !== "" ? (
                <VisibilityIcon
                  sx={{
                    marginLeft: "20px",
                    marginTop: "5px",
                  }}
                  onClick={() => {
                    downloadFile("eye-max");
                  }}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Button variant="outlined" size="small" onClick={handleUpdateBtn}>
                Update
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsUpdateBannerDetailsDialog(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
