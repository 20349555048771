import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

const PoFileDownload = React.forwardRef(({ data }) => {
  const componentRef = useRef();
  const printFn = useReactToPrint({
    content: () => componentRef?.current,
  });
  const isDraft = !data.order_id;
  useEffect(() => {
    if (data) {
      printFn();
    }
  }, [data]);
  return (
    <div ref={componentRef} style={styles.container}>
      {isDraft && (
        <div style={styles.watermark}>
          <p>Draft Order</p>
        </div>
      )}

      {/* Header Section */}
      <div style={styles.header}>
        <h2 style={styles.headerTitle}>Purchase Order</h2>
      </div>

      {/* Info Section */}
      <div style={styles.infoSection}>
        <div>
          <img
            src="https://www.bookwater.com/wp-content/uploads/2024/01/bw_logo_color_b_black_text_with_r.png"
            alt="BookWater Logo"
            style={styles.logo}
          />
          <div style={styles.addressContainer}>
            <address style={styles.address}>
              <p>
                <b>Recipient:</b>
              </p>
              <p>{data.bookwaterAddress.name}</p>
              <p>
                {data.bookwaterAddress.Unit},{" "}
                {data.bookwaterAddress.building_name}
              </p>
              <p>
                {data.bookwaterAddress.district} -{" "}
                {data.bookwaterAddress.pinCode}
              </p>
              <p>GSTIN/UIN: {data.bookwaterAddress.bookwaterGstIn}</p>
              <p>
                State Name: {data.bookwaterAddress.state} | Code:{" "}
                {data.bookwaterAddress.state_code}
              </p>
            </address>
            <address style={styles.address}>
              <p>
                <b>Vendor:</b>
              </p>
              <p>{data.mft_details.manufacturer_name}</p>
              <p>
                {data.mft_details.company_profile?.address?.address_line1},{" "}
                {data.mft_details.company_profile?.address?.address_line2}
              </p>
              <p>
                {data.mft_details.company_profile?.address?.city} -{" "}
                {data.mft_details.company_profile?.address?.pincode}
              </p>
              <p>{data.mft_details.company_profile?.address?.state}</p>
            </address>
          </div>
        </div>
        <div>
          <p>
            <strong>Voucher No:</strong> {data.order_id}
          </p>
          <p>
            <strong>Date:</strong> {new Date(data.created_at).toLocaleString()}
          </p>
        </div>
      </div>

      {/* Products Table */}
      <table style={styles.table}>
        <thead>
          <tr style={styles.tableHeader}>
            <th style={styles.tableCell}>S. No</th>
            <th style={styles.tableCell}>Products</th>
            <th style={styles.tableCell}>Price</th>
            <th style={styles.tableCell}>Quantity</th>
            <th style={styles.tableCell}>GST</th>
            <th style={styles.tableCell}>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.products.map((item, index) => (
            <tr key={index}>
              <td style={styles.tableCell}>{index + 1}</td>
              <td style={styles.tableCell}>
                <div>{item.details?.name || "N/A"}</div>
                <div>(HSN Code: {item.hsn_code})</div>
              </td>
              <td style={styles.tableCell}>{item.price || 0}</td>
              <td style={styles.tableCell}>{item.quantity || 0}</td>
              <td style={styles.tableCell}>
                <div>
                  SGST @ {item.sgst_perc || 0}% = {item.sgst_amt || 0}
                </div>
                <div>
                  CGST @ {item.cgst_perc || 0}% = {item.cgst_amt || 0}
                </div>
              </td>
              <td style={styles.tableCell}>{item.grand_total || 0}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="5" style={styles.summary}>
              Grand Total
            </td>
            <td style={styles.summary}>
              {data.order_summary?.order_total || 0}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Amount in Words */}
      <div>
        <p>
          <strong>Amount in Words:</strong> {data.letteralAmount} 
        </p>
      </div>

      {/* Declaration Section */}
      <div style={styles.declaration}>
        <p>
          <strong>Declaration:</strong>
        </p>
        <ol>
          <li>
            BookWater Tech Pvt Ltd approval must for Manufacture of Goods.
          </li>
          <li>
            Quality, Quantity, and Design must meet BookWater Tech Pvt Ltd
            requirements.
          </li>
          <li>
            Manufacturing should commence only after confirmation on Quality,
            Quantity, and Design from BookWater Tech Pvt Ltd.
          </li>
        </ol>
      </div>

      {/* Signature Section */}
      <div style={styles.signatureSection}>
        <p>
          <strong>For BookWater Tech Private Limited</strong>
        </p>
        <p style={styles.signature}>Authorized Signatory</p>
      </div>

      {/* Footer */}
      <div style={styles.footer}>
        <p>
          This is a computer-generated document and does not require a
          signature.
        </p>
      </div>
    </div>
  );
});

export default PoFileDownload;
const styles = {
  container: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  headerTitle: {
    margin: 0,
    fontSize: "24px",
  },
  watermark: {
    textAlign: "center",
    position: "fixed",
    width: "100%",
    top: "30%",
    left: "10%",
    transform: "translate(-50%, -50%)",
    opacity: 0.1,
    zIndex: 0,
    pointerEvents: "none",
    fontSize: "100px",
    color: "#4e4a4a",
    fontWeight: "bold",
    transformOrigin: "center",
    transform: "rotate(-45deg)",
  },
  infoSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "20px",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  address: {
    // width: "48%",
  },
  logo: {
    width: "150px",
    height: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  tableHeader: {
    backgroundColor: "#f4f4f4",
  },
  tableCell: {
    border: "1px solid #000",
    textAlign: "center",
    padding: "10px",
  },
  summary: {
    textAlign: "right",
    fontWeight: "bold",
  },
  declaration: {
    marginTop: "20px",
    borderTop: "1px solid #ccc",
    paddingTop: "10px",
  },
  signatureSection: {
    textAlign: "right",
    marginTop: "20px",
  },
  signature: {
    marginTop: "80px",
  },
  footer: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "14px",
    color: "#555",
  },
};
