import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  InputAdornment,
  TextField,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetWfsDetailsSku } from "../../Redux/Actions/skuWfsDetailsAction";
import { GetWfsLineDetails } from "../../Redux/Actions/adminLineDetailsAction";
import { GetIotLiveData } from "../../Redux/Actions/adminContainerDetailsAction";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { FirstPage, LastPage, Search } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import { toast } from "react-toastify";
import { appColors } from "../../../Components/Assests/AppTheme/appThemeConst";
import DateRangeFilterDialog from "../DateRangeFilterDialog";
import PrimaryButton from "../../../Components/Buttons/primaryButton";

function IotLiveDataDashBoard() {
  const dispatch = useDispatch();
  const [waterFillingDetails, setWaterFillingDetails] = useState([]);
  const [selectedWfsOptions, setSelectedWfsOption] = useState();
  const [iotDeviceList, setIotDeviceList] = useState([]);
  const [selectedIotOptions, setSelectedIotOption] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [liveIotData, setIotLiveData] = useState([]);
  const [csvFileData, setCsvFileData] = useState([]);
  const [previousKeys, setPreviousKeys] = useState([]);
  const buttonNames = ["Coustom Dates"];
  const [selectedButtons, setSelectedButtons] = useState(new Set());
  const [fromDate, setFromDate] = useState(
    moment().add(-1, "days").format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().add(1, "days").format("DD-MM-YYYY")
  );
  const [openDateRangeFilterDialog, setOpenDateRangeFilterDialog] = useState();
  useEffect(() => {
    dispatch(GetWfsDetailsSku({ page_number: 1, page_size: 100 })).then(
      (data) => {
        if (data?.data) {
          setWaterFillingDetails(data?.data);
          setSelectedWfsOption(data?.data[0]?.wfs_master_id);
        }
      }
    );
  }, []);

  //useEffect to get iot devices per water filling station details
  useEffect(() => {
    if (selectedWfsOptions) {
      dispatch(GetWfsLineDetails(selectedWfsOptions)).then((data) => {
        if (data?.data) {
          setIotDeviceList(data?.data);
          setSelectedIotOption(data?.data[0]?.iot_device_number);
        }
      });
    }
  }, [selectedWfsOptions]);

  //useEffect to get iot devices ph and tem details
  useEffect(() => {
    if (selectedIotOptions) {
      liveIotDataFunction();
    }
  }, [selectedIotOptions, pageNumber,fromDate,toDate]);

  //function to get live iot data
  const liveIotDataFunction = () => {
    let reqObj = {
      device_id: selectedIotOptions,
      page_size: pageSize,
      page_number: pageNumber,
      sort_order: "DESC",
      lastEvaluatedTimestamp: previousKeys
        ? previousKeys[pageNumber - 2]
        : null,
      from_date: fromDate,
      to_date: toDate,
    };
    dispatch(GetIotLiveData(reqObj)).then((response) => {
      if (response?.data) {
        const { data, lastEvaluatedKey } = response;
        if (lastEvaluatedKey && !previousKeys?.includes(lastEvaluatedKey)) {
          setPreviousKeys((prevKeys) => [...prevKeys, lastEvaluatedKey]);
        }
        const transformedData = data?.map((details) => {
          let avgTds = null;
          if (details?.tdsBsensor !== "NC") {
            const [min, max] = details?.tdsBsensor?.split("-").map(Number);
            avgTds = (min + max) / 2;
          }
          return {
            formattedDate: moment.unix(details.timestamp).format("DD-MMM-YY"),
            temp: details.temp,
            tds: avgTds,
            deviceId: details.DeviceID,
            // ph: details?.ph,
          };
        });
        setIotLiveData(transformedData);
      } else {
        setIotLiveData([]);
        setPreviousKeys([]);
      }
    });
  };
  console.log("wfsdetails................");
  console.log(waterFillingDetails);
  console.log(selectedWfsOptions);
  console.log(previousKeys);

  //handel change wfs text field
  const handleChangeWfsSelect = (e) => {
    setSelectedWfsOption(e.target.value);
    setPageNumber(1);
    setPageSize(10);
    setPreviousKeys([]);
  };

  //handel change function to get iot device details
  const handleChangeIotSelect = (e) => {
    setSelectedIotOption(e.target.value);
    setPageNumber(1);
    setPageSize(10);
    setPreviousKeys([]);
  };

  //function to handel onchange function
  const handelPageSizeChange = (e) => {
    if (e.target.value < 101 && e.target.value >= 0) {
      setPageSize(e.target.value);
    }
  };
  //function to search for page size
  const handleSearchPageSize = () => {
    handelPageSizeSearch();
  };

  //function to handel api call on pagesize search
  const handelPageSizeSearch = () => {
    if (pageSize >= 10) {
      liveIotDataFunction();
    } else {
      toast.dark("Please enter page size grater then 10", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename: "SkuPincodeReport",
    data: csvFileData,
  };

  //function to dounload csv files iot data
  function toCSV(iotLiveData) {
    const result = [];
    if (iotLiveData && iotLiveData?.length) {
      const csvData = iotLiveData.map((item) => ({
        ...item,
        Date: moment.unix(item.timestamp).format("D/MMM/YYYY h:mm:ss A"),
      }));
      setCsvFileData(csvData);
    }
  }

  //buttonFunction
  const ButtonFunction = () => {
    return (
      <Box>
        <PrimaryButton
          handleButtonClick={handelOpenDateRangeFilter}
          btnName="Select Date"
          color={appColors.blueColor}
        />
      </Box>
    );
  };

  //function to handel click
  const handelButtonClick = (value, index) => {
    setOpenDateRangeFilterDialog(true);
  };

  const handleGetDateRange = (props) => {
    setToDate(moment(props.toDate).format("DD-MM-YYYY"));
    setFromDate(moment(props.fromDate).format("DD-MM-YYYY"));
    setOpenDateRangeFilterDialog(false);
  };

  //function to get date range filter
  const handelOpenDateRangeFilter = () => {
    setOpenDateRangeFilterDialog(true);
  };

  
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <FormControl fullWidth>
              <Select
                value={selectedWfsOptions || ""}
                onChange={handleChangeWfsSelect}
                inputProps={{ "aria-label": "Without label" }}
                size="small"
              >
                {waterFillingDetails?.map((data) => (
                  <MenuItem
                    value={data?.wfs_master_id}
                    key={data?.wfs_master_id}
                  >
                    {data?.company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText>Select Water Filling Station</FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <FormControl fullWidth>
              <Select
                value={selectedIotOptions || ""}
                onChange={handleChangeIotSelect}
                inputProps={{ "aria-label": "Without label" }}
                size="small"
              >
                {iotDeviceList
                  ?.filter((data) => data?.iot_device_number !== "")
                  .map((data) => (
                    <MenuItem
                      value={data?.iot_device_number}
                      key={data?.line_id}
                    >
                      {data?.iot_device_number}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormHelperText>Select Iot Device</FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          {ButtonFunction()}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // flexWrap: "wrap",
            }}
          >
            <Typography
              sx={{ color: "black", fontSize: "1.3rem", textAlign: "start" }}
            >
              IOT Data
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: "80px",
                  maxHeight: "10px",
                }}
              >
                <TextField
                  size="small"
                  value={pageSize}
                  label="Page size"
                  onChange={handelPageSizeChange}
                  InputProps={{
                    maxLength: 10,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search
                          cursor="pointer"
                          size="20px"
                          onClick={handleSearchPageSize}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (pageNumber - 1 > 0) {
                      setPageNumber(() => pageNumber - 1);
                    }
                  }}
                >
                  <FirstPage
                    sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                  />
                </IconButton>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  {pageNumber}
                </Typography>
                <IconButton
                  sx={{ maxHeight: "50px" }}
                  onClick={() => {
                    if (pageSize == liveIotData?.length) {
                      setPageNumber(() => pageNumber + 1);
                    }
                  }}
                >
                  <LastPage
                    sx={{
                      color: pageSize == liveIotData?.length ? "blue" : "gray",
                    }}
                  />
                </IconButton>
              </Box>
              {/* <CSVLink {...csvLink}>
              <SimCardDownloadRoundedIcon onClick={() => toCSV(liveIotData)} />
            </CSVLink> */}
            </Box>{" "}
          </Box>
        </Grid>
        <Grid container>
          {liveIotData?.length > 0 ? (
            <>
              <Grid item xs={12} lg={6}>
                <div style={{ width: "100%", height: "15rem" }}>
                  <ResponsiveContainer>
                    <LineChart data={liveIotData}>
                      <CartesianGrid stroke="#ccc" />
                      <XAxis dataKey="formattedDate" />
                      <YAxis />
                      <Tooltip />
                      <Legend />

                      <Line
                        type="monotone"
                        dataKey="tds"
                        stroke="#82ca9d"
                        name="TDS LSL: 50 ppm & USL: 300 ppm"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div style={{ width: "100%", height: "15rem" }}>
                  <ResponsiveContainer>
                    <LineChart data={liveIotData}>
                      <CartesianGrid stroke="#ccc" />
                      <XAxis dataKey="formattedDate" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="temp"
                        stroke="#8884d8"
                        name="Temperature (°C)"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <div style={{ width: "100%", height: "15rem" }}>
                  <ResponsiveContainer>
                    <LineChart data={liveIotData}>
                      <CartesianGrid stroke="#ccc" />
                      <XAxis dataKey="formattedDate" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="ph"
                        stroke="#FF0000"
                        name="PH LSL: 6.5 & USL: 8.5 ppm"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div> */}
              </Grid>
            </>
          ) : (
            <Typography
              sx={{ color: "#808089", fontWeight: "bold", textAlign: "center" }}
            >
              IOT Details Not Found!
            </Typography>
          )}
        </Grid>
      </Grid>
      <DateRangeFilterDialog
        openDateRangeFilterDialog={openDateRangeFilterDialog}
        handleCloseDateRange={() => setOpenDateRangeFilterDialog(false)}
        handleGetDateRange={handleGetDateRange}
        setToDate={setToDate}
        setFromDate={setFromDate}
        toDate={toDate}
        fromDate={fromDate}
      />
    </Box>
  );
}

export default IotLiveDataDashBoard;
