import * as React from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Style } from "./style";
import {
  Paper,
  Tooltip,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Chip,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useDispatch, useSelector } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  GetAdminSetupSettings,
  GetGiftCarData,
  GetReferalCodeSettings,
  PostAddOrUpdateGiftCard,
  PostForceConsumerAppsUpdate,
  PutInScanControlBlocking,
  UpdateReferralSettingAction,
  UpdateSetupConfig,
} from "../Redux/Actions/AdminSettingsAction";
import { ToastContainer, toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Campaign from "@mui/icons-material/Campaign";
import moment from "moment";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LocalOffer from "@mui/icons-material/LocalOffer";
import { UpdateReferralCodeSettingAction } from "../Redux/Actions/AdminSettingsAction";
import DepartureBoard from "@mui/icons-material/DepartureBoard";
import Refresh from "@mui/icons-material/Refresh";
import { DeliverySlotSettingAction } from "../Redux/Actions/AdminSettingsAction";
import { GetDeliverySlotSettingAction } from "../Redux/Actions/AdminSettingsAction";
import { soldBySelect } from "../../Components/AppMeta/appMetaConfig";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CsaRateforAll from "../AdminDistributors/CsaRateforAll";
import Pincode from "./Pincode";
import Blocking from "./Blockings/Blocking";

import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import PaymentBlocking from "./Blockings/PaymentBlocking";
import BookingBlock from "./Blockings/BookingBlock";

import {
  BlockBooking,
  GetBlockedBookings,
} from "../Redux/Actions/appSettingsAction";
import { useState } from "react";
import { Add, Close, DeleteForever, FileUpload } from "@mui/icons-material";
import {
  StoreImageFiles,
  UploadFile,
  UploadFileGiftCard,
} from "../Redux/Actions/fileHandlingAction";
import ImageLoader from "../SupplierDeliveries/ImageLoader";
import useProductImages from "../../Components/Custom/useProductImages";
import ImageViewer from "../../Components/Custom/imageLoader";
import RenderGiftCards from "./GiftCards/RenderGiftCard";

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});
export default function AdminMainSettings(props) {
  const refferralTypes = {
    CONSUMER_REFERRER: 101, //person who is referring (For Order completion)
    CONSUMER_REFERREE: 103, //being referred (For Order completion)
    INFLUENCER: 102, //For order completion
    SIGNUP_REFEREE: 1001, //For signup (Only once)
    SIGNUP_REFERRER: 1002, //For Signup (Only once)
  };
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [blockStart, setBlockStart] = React.useState(null);
  const [blockEnd, setBlockEnd] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [addNewGreeting, setAddNewGreeting] = React.useState(false);
  const dispatch = useDispatch();

  const settingsDetails = useSelector((state) => state.adminSettingsData);
  const uploadImage = React.useRef(null);
  const fileHandler = useSelector((state) => state.fileHandler);
  CustomConsole("--------settingsDetails-----------------");
  CustomConsole(settingsDetails);
  const [updateMsgFlag, setUpdateMsgFlag] = React.useState(false);
  //!! Gift Card
  const [giftPayload, setGiftPayload] = React.useState({
    isEnable: false,
    minimum_amount: "",
    maximum_amount: "",
    expiry_day_count: "",
    one_wallet_equals_to: "",
    one_waterdrops_equals_to: "",
    greetings: [],
    consumer_to_consumer_redeem_through: "WALLET",
    consumer_self_buy: false,
    consumer_self_redeem: false,
    physical_giftcard_template: [],
  });
  const [addGreetingCheck, setAddGreetingCheck] = useState(false);
  console.log("----addGreetingCheck------", addGreetingCheck);
  const [newaddedCategory, setNewaddedCategory] = React.useState({
    newGrettings: [],
  });

  // State to handle new greeting inputs
  const [newCategory, setNewCategory] = useState("");
  const [newImages, setNewImages] = useState([]); // Array to store images for new category
  const [uploadReciptFile, setUploadReciptFile] = React.useState("");
  const [reciptUrl, setReciptUrl] = React.useState("");
  const [uploadReciptBtn, setUploadReciptBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [existingDeliveryslot, setExistingDeliveryslot] = React.useState("");
  const [blockInScanRadio, setBlockInScanRadio] = React.useState(1);
  const [fromTimeDS, setFromTimeDS] = React.useState(null);
  const [toTimeDS, setToTimeDS] = React.useState(null);
  const [startDeliverySlot, setStartDeliverySlot] = React.useState("7:00");
  const [endDeliverySlot, setEndDeliverySlot] = React.useState("");
  const [numberOfDeliverySlots, setNumberOfDeliverySlots] = React.useState(0);
  const [alignment, setAlignment] = React.useState("getS");
  const [gstNumEr, setGstNumEr] = React.useState(false);
  const [companyPanEr, setCompanyPanEr] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("0");
  const [newUploadedImages, setNewUploadedImages] = useState({});
  const classes = useStyles();
  const [referralTabValue, setReferralTabValue] = React.useState("ROS");
  // For Signup
  const [signUpReferralRewards, setSignUpReferralRewards] = React.useState({
    referee: 0,
    referrer: 0,
  });
  // For Order Completion
  const [influencerRewards, setInfluencerRewards] = React.useState({
    water_drops: 0,
    water_drop_worth: 0,
  });
  const [refereeReferrerRewards, setRefereeReferrerRewards] = React.useState({
    water_drops: 0,
    water_drop_worth: 0,
    referee_limit: 0,
    referrer_limit: 0,
  });
  //Android Force Update
  const [andtiodUpdate, setAndtiodUpdate] = React.useState({
    consumer_version: "",
    update_mandatory: "",
    msg: "",
    header: "",
    force_app_tour: "",
  });
  //Admin Force Update
  const [adminAppUpdate, setAdminAppUpdate] = React.useState({
    admin_version: "",
    update_mandatory: "",
    msg: "",
    header: "",
    force_app_tour: "",
  });
  CustomConsole(andtiodUpdate);
  //Ios Force Update
  const [iosUpdate, setIosUpdate] = React.useState({
    consumer_version: "",
    update_mandatory: "",
    msg: "",
    header: "",
    force_app_tour: "",
  });

  //Force Update Consumer Web App
  const [webAppUpdate, setWebAppUpdate] = React.useState({
    consumer_version: "",
    force_app_tour: "",
  });

  const [finalAndriodData, setFinalAndriodData] = React.useState();
  const [finslIosDataUpdate, setFinslIosDataUpdate] = React.useState();
  const [finalWebData, setFinalWebData] = React.useState();
  const [storeAllImages, setStoreAllImages] = React.useState([]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState(0);
  console.log(selectedCategoryIndex);
  const [isClicked, setIsClicked] = useState(false);
  const [fileSetFlag, setFileSetFlag] = React.useState(false);
  const [uploadingFiles, setUploadingFiles] = React.useState([]);
  const [listOfGrettings, setListOfGrettings] = React.useState([]);
  const [newAddedGreeting, setNewAddedGreeting] = React.useState([]);
  console.log("newAddedGreeting", newAddedGreeting);
  const { images, isLoading, error } = useProductImages(
    storeAllImages,
    "GiftCardImages"
  );

  const selectedCategory = listOfGrettings?.[selectedCategoryIndex];
  const renderData = [
    {
      amount: 100,
      id: "BWGC696281290932",
      branch: "Book water",
      password: "136761",
    },
    {
      amount: 100,
      id: "BWGC181713970931",
      branch: "Bookwater",
      password: "198134",
    },
  ];
  const fontStylingSettings = {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
  };

  const iconsStyling = {
    fontSize: "17px",
    marginLeft: "5px",
  };

  // Handler function to update specific key in the object For Influencer
  const handleSignUpReferralRewards = (key, value) => {
    // Update only the specified key in the object
    setSignUpReferralRewards((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  // Handler function to update specific key in the object For Influencer
  const handleChangeInfluencerRewards = (key, value) => {
    // Update only the specified key in the object
    setInfluencerRewards((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  // Handler function to update specific key in the object For Influencer
  const handleChangeRefereeReferrerRewards = (key, value) => {
    // Update only the specified key in the object
    setRefereeReferrerRewards((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // const [soldBy, setSoldBy] = React.useState("");
  // const [companyName, setCompanyName] = React.useState("");
  // const [panNumber, setPanNumber] = React.useState("");
  // const [gstNumber, setGstNumber] = React.useState("");
  // const [address, setAddress] = React.useState("");

  // const [deliveryStartEndTime, setDeliveryStartEndTime] = React.useState(<Dayjs | null>(dayjs('2022-04-17T15:30'));
  const [deliveryStartEndTime, setDeliveryStartEndTime] =
    React.useState("07:00PM");
  React.useEffect(() => {
    CustomConsole(settingsDetails);
    if (settingsDetails.error === false && updateMsgFlag === true) {
      // toast.success(settingsDetails.setUpConfigMsg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setUpdateMsgFlag(false);
      dispatch(GetAdminSetupSettings());
    }
    if (settingsDetails.error === true && updateMsgFlag === true) {
      toast.error(settingsDetails.setUpConfigMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [settingsDetails]);
  React.useEffect(() => {
    setStartTime(props?.settingsData?.adminSettings?.order_block_start_time);
    setEndTime(props?.settingsData?.adminSettings?.order_block_end_time);
  }, [settingsDetails]);
  React.useEffect(() => {
    if (settingsDetails.getGiftCarddata) {
      console.log(
        "settingsDetails",
        settingsDetails.getGiftCarddata?.gift_card_settings
      );
      setGiftPayload({
        isEnable: settingsDetails.getGiftCarddata?.gift_card_settings?.isEnable,
        minimum_amount:
          settingsDetails.getGiftCarddata?.gift_card_settings?.minimum_amount,
        maximum_amount:
          settingsDetails.getGiftCarddata?.gift_card_settings?.maximum_amount,
        expiry_day_count:
          settingsDetails.getGiftCarddata?.gift_card_settings?.expiry_day_count,
        one_wallet_equals_to:
          settingsDetails.getGiftCarddata?.gift_card_settings
            ?.one_wallet_equals_to,
        one_waterdrops_equals_to:
          settingsDetails.getGiftCarddata?.gift_card_settings
            ?.one_waterdrops_equals_to,
        greetings:
          settingsDetails.getGiftCarddata?.gift_card_settings?.greetings,
        consumer_to_consumer_redeem_through:
          settingsDetails?.getGiftCarddata?.gift_card_settings
            ?.consumer_to_consumer_redeem_through,
        consumer_self_buy:
          settingsDetails?.getGiftCarddata?.gift_card_settings
            ?.consumer_self_buy,
        consumer_self_redeem:
          settingsDetails?.getGiftCarddata?.gift_card_settings
            ?.consumer_self_redeem,
        physical_giftcard_template:
          settingsDetails?.getGiftCarddata?.gift_card_settings
            ?.physical_giftcard_template,
      });

      const storeImages = [
        ...(settingsDetails.getGiftCarddata?.gift_card_settings?.greetings?.flatMap(
          (data) => data.images
        ) || []),
        ...(settingsDetails.getGiftCarddata?.gift_card_settings
          ?.physical_giftcard_template || []),
      ];

      setStoreAllImages(storeImages);
      setListOfGrettings(
        settingsDetails.getGiftCarddata?.gift_card_settings?.greetings
      );
      if (
        settingsDetails.getGiftCarddata?.gift_card_settings?.greetings !==
          undefined &&
        settingsDetails.getGiftCarddata?.gift_card_settings?.greetings !==
          null &&
        settingsDetails.getGiftCarddata?.gift_card_settings?.greetings.length >
          0
      ) {
        setSelectedCategoryIndex(0);
      }
    }
  }, [settingsDetails.getGiftCarddata]);
  React.useEffect(() => {
    setGiftPayload((prev) => {
      return {
        ...prev,
        greetings: listOfGrettings,
      };
    });
  }, [listOfGrettings]);
  React.useEffect(() => {
    CustomConsole("----------adminSettings----------------");
    CustomConsole(settingsDetails.adminSettings?.updates?.consumer_android);
    CustomConsole(settingsDetails.adminSettings?.updates?.consumer_web);
    if (settingsDetails.adminSettings?.updates?.consumer_android) {
      setAndtiodUpdate({
        update_mandatory:
          settingsDetails?.adminSettings?.updates?.consumer_android
            ?.update_mandatory,
        consumer_version:
          settingsDetails.adminSettings?.updates?.consumer_android
            ?.consumer_version,
        header:
          settingsDetails.adminSettings?.updates?.consumer_android?.header,
        msg: settingsDetails.adminSettings?.updates?.consumer_android?.msg,
        force_app_tour:
          settingsDetails.adminSettings?.updates?.consumer_android
            ?.force_app_tour,
      });
    }
    if (settingsDetails?.adminSettings?.updates?.admin_web) {
      setAdminAppUpdate({
        update_mandatory:
          settingsDetails.adminSettings?.updates?.admin_web?.update_mandatory,
        admin_version:
          settingsDetails.adminSettings?.updates?.admin_web?.admin_version,
        header: settingsDetails.adminSettings?.updates?.admin_web?.header,
        msg: settingsDetails.adminSettings?.updates?.admin_web?.msg,
        force_app_tour:
          settingsDetails.adminSettings?.updates?.admin_web?.force_app_tour,
      });
    }
    if (settingsDetails.adminSettings?.updates?.consumer_ios) {
      setIosUpdate({
        update_mandatory:
          settingsDetails.adminSettings?.updates?.consumer_ios
            ?.update_mandatory,
        consumer_version:
          settingsDetails.adminSettings?.updates?.consumer_ios
            ?.consumer_version,
        header: settingsDetails.adminSettings?.updates?.consumer_ios?.header,
        msg: settingsDetails.adminSettings?.updates?.consumer_ios?.msg,
        force_app_tour:
          settingsDetails.adminSettings?.updates?.consumer_ios?.force_app_tour,
      });
    }
    if (settingsDetails.adminSettings?.updates?.consumer_web) {
      setWebAppUpdate({
        consumer_version:
          settingsDetails.adminSettings?.updates?.consumer_web.consumer_version,
        force_app_tour:
          settingsDetails.adminSettings?.updates?.consumer_web?.force_app_tour,
      });
    }
    if (settingsDetails.adminSettings?.updates) {
      setBlockInScanRadio(
        settingsDetails.adminSettings?.updates?.consumer_order_inscan_control
      );
    }
  }, [settingsDetails.adminSettings]);

  // const [signUpReferralRewards, setSignUpReferralRewards] = React.useState({
  //   referee: 0,
  //   referrer: 0,
  // });
  // // For Order Completion
  // const [influencerRewards, setInfluencerRewards] = React.useState({
  //   water_drops: 0,
  //   water_drop_worth: 0,
  // });
  // const [refereeReferrerRewards, setRefereeReferrerRewards] = React.useState({
  //   water_drops: 0,
  //   water_drop_worth: 0,
  //   referee_limit: 0,
  //   referrer_limit: 0,
  // });
  React.useEffect(() => {
    CustomConsole(signUpReferralRewards);
    CustomConsole(influencerRewards);
    CustomConsole(refereeReferrerRewards);
  }, [signUpReferralRewards, influencerRewards, refereeReferrerRewards]);
  //ReferralSettings
  React.useEffect(() => {
    CustomConsole(settingsDetails.referralData);
    if (settingsDetails.referralData && settingsDetails.referralData.length) {
      for (const data of settingsDetails.referralData) {
        CustomConsole(data);
        switch (data.referral_type) {
          case refferralTypes.CONSUMER_REFERREE:
            let existingRefereeData = refereeReferrerRewards;
            existingRefereeData.referee_limit = data.reward_limit;
            existingRefereeData.water_drops = data.referral_water_drops;
            existingRefereeData.water_drop_worth = data.each_drop_worth;
            setRefereeReferrerRewards(existingRefereeData);
            break;
          case refferralTypes.CONSUMER_REFERRER:
            let existinReferrerData = refereeReferrerRewards;
            existinReferrerData.referrer_limit = data.reward_limit;
            existinReferrerData.water_drops = data.referral_water_drops;
            existinReferrerData.water_drop_worth = data.each_drop_worth;
            setRefereeReferrerRewards(existinReferrerData);
            break;
          case refferralTypes.INFLUENCER:
            let existingInfluencerData = influencerRewards;
            existingInfluencerData.water_drops = data.referral_water_drops;
            existingInfluencerData.water_drop_worth = data.each_drop_worth;
            setInfluencerRewards(existingInfluencerData);
            break;
          case refferralTypes.SIGNUP_REFEREE:
            let existingSignupRefereeData = signUpReferralRewards;
            existingSignupRefereeData.referee = data.referral_water_drops;
            setSignUpReferralRewards(existingSignupRefereeData);
            break;
          case refferralTypes.SIGNUP_REFERRER:
            let existingSignupReferrerData = signUpReferralRewards;
            existingSignupReferrerData.referrer = data.referral_water_drops;
            setSignUpReferralRewards(existingSignupReferrerData);
            break;
          default:
            break;
        }
      }
    }
  }, [settingsDetails.referralData]);
  React.useEffect(() => {
    dispatch(GetReferalCodeSettings());
    dispatch(GetGiftCarData());
  }, []);
  React.useEffect(() => {
    dispatch(GetReferalCodeSettings());
  }, [settingsDetails.updateReferralSettings]);
  React.useEffect(() => {
    handleExisitingDeliverySlots();
  }, []);
  const handleUpdate = () => {
    const reqObj = {
      c_fine_percent: props.consumerCancelFee,
      cancellation_fee: props.cancellationfee,
      conv_cgst_percent: props.convFeeCGST,
      conv_gst_code: props.convFeeGSTCode,
      conv_sgst_percent: props.convFeeSGST,
      disable_consumer_can_resubmission: props.consumerDisableTime,
      disable_distributor_can_resubmission: props.distributorDisableTime,
      disable_wfs_can_resubmission: props.wfsDisableTime,
      five_ltr_max: props.fiveltrmax,
      five_ltr_min: props.fiveltrmin,
      fivehnd_ml_max: props.fivehndmlmax,
      fivehnd_ml_min: props.fivehndmlmin,
      gateway_fee: props.gateWayFee,
      gatewayfee_cgst_percent: props.gatewayFeeCGST,
      gatewayfee_gst_code: props.gateWayGSTCode,
      gatewayfee_sgst_percent: props.gatewayFeeSGST,
      ltb_conv_fee_percent: props.convFeeLTBooking,
      ltb_order_accept_timing: props.supplierOrderAcceptanceInterval,
      notify_consumer_can_resubmission: props.consumerNotifyTime,
      notify_distributor_can_resubmission: props.distributorNotifyTime,
      notify_wfs_can_resubmission: props.wfsNotifyTime,
      one_ltr_max: props.oneltrmax,
      one_ltr_min: props.oneltrmin,
      order_block_end_time: endTime,
      order_block_start_time: startTime,
      pdw_cgst_percent: props.pwtConvFeeCGST,
      pdw_gst_code: props.pwtConvFeeGSTCode,
      pdw_sgst_percent: props.pwtConvFeeSGST,
      pdw_igst_percent: props.pwtConvFeeIGST,
      postpaidcharges: props.postpaidPercentage,
      postpaidconvfee: props.postpaidconvfee,
      s_fine_percent: props.supplierCancelFee,
      sb_conv_fee_percent: props.convFeeSTBooking,
      st_cgst_percent: props.stcgstpercent,
      st_gst_code: props.stgstcode,
      st_sgst_percent: props.stsgstpercent,
      timeframe: props.timeFrame,
      twenty_ltr_max: props.twentyltrmax,
      twenty_ltr_min: props.twentyltrmin,
      two_ltr_max: props.twoltrmax,
      two_ltr_min: props.twoltrmin,
      twohnd_ml_max: props.twohndmlmax,
      twohnd_ml_min: props.twohndmlmin,
      wt_cgst_percent: props.wtcgstpercent,
      wt_gst_code: props.wtgstcode,
      wt_sgst_percent: props.wtscgst,
      extra_charges_singleday: props.extraCharges,
      minimum_wallet_amount: props.minimumWallet,
      otp_less_delivery_disclaimer: props.otpMsg,
      max_allowed_discount_percentage: props.minCanPrice,
      extra_charges_hsn_code: props.extraChargesHSNCode,
      extra_charges_cgst_percent: props.extraChargesCGST,
      extra_charges_sgst_percent: props.extraChargesSGST,
      base_watercan_price: props.baseWaterPrice,
      fillingstation_settlement_price: props.wfsSettlement,
      distributor_settlement_price: props.distributorSettlement,
      wallet_alert_amount: props.walletRechargeLmt,
      sold_by: props.soldBy,
      sold_by_details: {
        company_name: props.companyName,
        address: props.address,
        pan_no: props.panNumber,
        gst_no: props.gstNumber,
      },
    };
    console.log("reqObj.pdw_igst_percent:", reqObj.pdw_igst_percent);
    CustomConsole(reqObj);
    dispatch(UpdateSetupConfig(reqObj));
    setUpdateMsgFlag(true);
  };

  React.useEffect(() => {
    CustomConsole(`Block start time: ${startTime}`);
    CustomConsole(`Block end time: ${endTime}`);
  }, [startTime, endTime]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [updateReferralFlag, setUpdateReferralFlag] = React.useState(false);
  const clickUpdate = () => {
    if (props.referralEachWaterDropWorth.trim() !== "") {
      CustomConsole(props.refferaltype);
      setUpdateReferralFlag(true);
      props.setUpdateReferral(true);
      let reqObj = {
        referral_type: props.refferaltype,
        referral_water_drops: props.referralWaterDrops,
        is_active: props.isActive,
        reward_limit: props.referralRewardlimit,
        each_drop_worth: props.referralEachWaterDropWorth,
      };
      dispatch(UpdateReferralCodeSettingAction(reqObj));
    } else {
      toast.error("Please Fill All Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleUpdateOrderCompletionReferralRewards = () => {
    CustomConsole("-- handleUpdateOrderCompletionReferralRewards --");
    CustomConsole(influencerRewards);
    CustomConsole(refereeReferrerRewards);
    const reqData = [
      {
        referral_type: refferralTypes.CONSUMER_REFERREE,
        referral_water_drops: refereeReferrerRewards.water_drops,
        each_drop_worth: refereeReferrerRewards.water_drop_worth,
        reward_limit: refereeReferrerRewards.referee_limit,
      },
      {
        referral_type: refferralTypes.CONSUMER_REFERRER,
        referral_water_drops: refereeReferrerRewards.water_drops,
        each_drop_worth: refereeReferrerRewards.water_drop_worth,
        reward_limit: refereeReferrerRewards.referrer_limit,
      },
      {
        referral_type: refferralTypes.INFLUENCER,
        referral_water_drops: influencerRewards.water_drops,
        each_drop_worth: influencerRewards.water_drop_worth,
        reward_limit: 1,
      },
    ];
    CustomConsole(reqData);
    dispatch(UpdateReferralSettingAction({ data: reqData }));
  };
  const handleUpdateSignUpReferralRewards = () => {
    CustomConsole("-- handleUpdateSignUpReferralRewards --");
    CustomConsole(signUpReferralRewards);
    const reqData = [
      {
        referral_type: refferralTypes.SIGNUP_REFEREE,
        referral_water_drops: signUpReferralRewards.referee,
        each_drop_worth: 0,
        reward_limit: 0,
      },
      {
        referral_type: refferralTypes.SIGNUP_REFERRER,
        referral_water_drops: signUpReferralRewards.referrer,
        each_drop_worth: 0,
        reward_limit: 0,
      },
    ];
    CustomConsole(reqData);
    dispatch(UpdateReferralSettingAction({ data: reqData }));
  };
  const handleChangeReferralTabs = (event, newValue) => {
    setReferralTabValue(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const walletAmtRechargeLimitFun = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    e.target.value = numericValue;
    props.setWalletRechargeLmt(e.target.value);
  };

  React.useEffect(() => {
    if (updateReferralFlag) {
      // setTimeout(() => {
      // dispatch(GetReferalCodeSettings());
      // }, 1000);

      setUpdateReferralFlag(false);
    }
  }, [updateReferralFlag]);

  const handleExisitingDeliverySlots = () => {
    dispatch(GetDeliverySlotSettingAction());
  };

  const handleDeliveryslotsAdd = () => {
    CustomConsole("---- handleDeliveryslotsAdd -----");
    if (
      startDeliverySlot !== "" &&
      endDeliverySlot !== "" &&
      numberOfDeliverySlots !== ""
    ) {
      let reqObj = {
        start_end_time:
          `${startDeliverySlot} AM` + "-" + `${endDeliverySlot} PM`,
        number_of_slots: numberOfDeliverySlots,
      };
      dispatch(DeliverySlotSettingAction(reqObj));
      CustomConsole(reqObj);
    } else {
      let reqObj = {
        start_end_time:
          `${startDeliverySlot} AM` + "-" + `${endDeliverySlot} PM`,
        number_of_slots: numberOfDeliverySlots,
      };
      CustomConsole(reqObj);
      toast.error("Fill all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    CustomConsole(startDeliverySlot + "-" + endDeliverySlot);
  };

  const handleDeliverySlots = (newValue) => {
    CustomConsole("--- handleDeliverySlots  ----");
    setFromTimeDS(newValue);
    if (newValue != null) {
      setStartDeliverySlot(() => `${newValue.$H}:${newValue.$m}`);
      CustomConsole(`START TIME: ${newValue.$H}:${newValue.$m}`);
    }
    CustomConsole(newValue);
  };
  const handleChangeDeliveryEndTime = (newValue) => {
    CustomConsole("--- handleChangeDeliveryEndTime  ----");
    setToTimeDS(newValue);
    if (newValue != null) {
      setEndDeliverySlot(() => `${newValue.$H}:${newValue.$m}`);
      CustomConsole(`END TIME: ${newValue.$H}:${newValue.$m}`);
    }
    CustomConsole(newValue);
  };

  const ReferralEachWaterDropWorth = (event) => {
    if (event.target.value >= 0) {
      props.setReferralEachWaterDropWorth(event.target.value);
    }
  };

  // React.useEffect(() => {
  //   dispatch(GetReferalCodeSettings(101));
  // }, []);

  const handleReferralTypeChange = (event) => {
    props.setRefferaltype(event.target.value);
    // dispatch(GetReferalCodeSettings(event.target.value));
  };

  const handleChangeNumberOfSlots = (event) => {
    if (event.target.value >= 0 && event.target.value <= 45)
      setNumberOfDeliverySlots(event.target.value);
  };

  const handleDeliverySlotUi = (event, newAlignment) => {
    if (newAlignment === "getS") {
      handleExisitingDeliverySlots();
    }
    setAlignment(newAlignment);
  };
  const handleChangeSelectSoldBy = (event) => {
    props.setSoldBy(event.target.value);
  };

  const handleChangeGstNumber = (e) => {
    const isValidGstNumber =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        e.target.value
      );
    props.setGstNumber(e.target.value);
    setGstNumEr(!isValidGstNumber);
  };

  const handleChangePanValidation = (e) => {
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(e.target.value);
    props.setPanNumber(e.target.value);
    setCompanyPanEr(!isValidPanNumber);
  };

  const handleChangeandriodUpdateRadio = (event) => {
    CustomConsole("--------event------------");
    CustomConsole(typeof event.target.value);
    if (event.target.value === "true") {
      CustomConsole("--------Yes------------");
      setAndtiodUpdate((prev) => ({
        ...prev,
        update_mandatory: true,
      }));
    } else if (event.target.value === "false") {
      CustomConsole("--------No------------");
      setAndtiodUpdate((prev) => ({
        ...prev,
        update_mandatory: false,
      }));
    }
  };

  const handleChangeAdminUpdateRadio = (event) => {
    CustomConsole("--------event------------");
    CustomConsole(typeof event.target.value);
    if (event.target.value === "true") {
      CustomConsole("--------Yes------------");
      setAdminAppUpdate((prev) => ({
        ...prev,
        update_mandatory: true,
      }));
    } else if (event.target.value === "false") {
      CustomConsole("--------No------------");
      setAdminAppUpdate((prev) => ({
        ...prev,
        update_mandatory: false,
      }));
    }
  };
  const handleChangeIosUpdateRadio = (event) => {
    if (event.target.value === "true") {
      setIosUpdate((prev) => ({
        ...prev,
        update_mandatory: true,
      }));
    } else {
      setIosUpdate((prev) => ({
        ...prev,
        update_mandatory: false,
      }));
    }
  };

  const handleChangeandriodForceAppTourRadio = (event) => {
    if (event.target.value === "true") {
      setAndtiodUpdate((prev) => ({
        ...prev,
        force_app_tour: true,
      }));
    } else {
      setAndtiodUpdate((prev) => ({
        ...prev,
        force_app_tour: false,
      }));
    }
  };

  //function to control force app touroption for admin
  const handleChangeAdminForceAppTourRadio = (event) => {
    if (event.target.value === "true") {
      setAdminAppUpdate((prev) => ({
        ...prev,
        force_app_tour: true,
      }));
    } else {
      setAdminAppUpdate((prev) => ({
        ...prev,
        force_app_tour: false,
      }));
    }
  };

  const handleChangeIosForceAppTourRadio = (event) => {
    if (event.target.value === "true") {
      setIosUpdate((prev) => ({
        ...prev,
        force_app_tour: true,
      }));
    } else {
      setIosUpdate((prev) => ({
        ...prev,
        force_app_tour: false,
      }));
    }
  };

  const handleChangeWebAppForceAppTourRadio = (event) => {
    if (event.target.value === "true") {
      setWebAppUpdate((prev) => ({
        ...prev,
        force_app_tour: true,
      }));
    } else {
      setWebAppUpdate((prev) => ({
        ...prev,
        force_app_tour: false,
      }));
    }
  };

  React.useEffect(() => {
    CustomConsole(andtiodUpdate);
    if (
      (andtiodUpdate.update_mandatory === true ||
        andtiodUpdate.update_mandatory === false) &&
      (andtiodUpdate.force_app_tour === true ||
        andtiodUpdate.force_app_tour === false) &&
      andtiodUpdate.consumer_version !== "" &&
      andtiodUpdate.header !== "" &&
      andtiodUpdate.msg !== ""
    ) {
      CustomConsole("-----Andriod----Filled-----------------");
      setFinalAndriodData(andtiodUpdate);
    } else {
      CustomConsole("-----Andriod----Fill All Data-----------------");
    }
    if (
      (iosUpdate.update_mandatory === true ||
        iosUpdate.update_mandatory === false) &&
      (iosUpdate.force_app_tour === true ||
        iosUpdate.force_app_tour === false) &&
      iosUpdate.consumer_version !== "" &&
      iosUpdate.header !== "" &&
      iosUpdate.msg !== ""
    ) {
      setFinslIosDataUpdate(iosUpdate);
    } else {
      CustomConsole("-----IOS----Fill All Data-----------------");
    }
    CustomConsole(webAppUpdate.force_app_tour);
    if (
      webAppUpdate.force_app_tour === true ||
      (webAppUpdate.force_app_tour === false &&
        webAppUpdate.consumer_version !== "")
    ) {
      setFinalWebData(webAppUpdate);
    } else {
      CustomConsole("-----Web----Fill All Data-----------------");
    }
  }, [andtiodUpdate, iosUpdate, webAppUpdate]);
  React.useEffect(() => {}, [
    finalAndriodData,
    finslIosDataUpdate,
    finalWebData,
  ]);

  const handleConsumerAppsUpdate = () => {
    const reqObj = {
      consumer_android: finalAndriodData || undefined,
      consumer_ios: finslIosDataUpdate || undefined,
      consumer_web: finalWebData || undefined,
      admin_web: adminAppUpdate || undefined,
    };
    CustomConsole("----------reqObjDataForceUpdate--------------");
    CustomConsole(reqObj);
    dispatch(PostForceConsumerAppsUpdate(reqObj));
    setUpdateMsgFlag(true);
  };
  const handleUpdateBlockInScanning = () => {
    const reqObj = {
      order_type: parseInt(blockInScanRadio),
    };
    CustomConsole(reqObj);
    dispatch(PutInScanControlBlocking(reqObj));
    setUpdateMsgFlag(true);
  };

  // Handle toggle for isEnable
  const handleToggle = () => {
    setGiftPayload((prev) => ({ ...prev, isEnable: !prev.isEnable }));
  };

  // Handle input changes for numbers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGiftPayload((prev) => ({
      ...prev,
      [name]: value, // Convert value to number
    }));
  };

  // Handle image changes for existing greetings
  const handleImageChange = (greetingIdx, imageIdx, value) => {
    const updatedGreetings = [...giftPayload.greetings];
    updatedGreetings[greetingIdx].images[imageIdx] = value;
    setGiftPayload((prev) => ({
      ...prev,
      greetings: updatedGreetings,
    }));
  };

  // Handle changes for new category input
  const handleNewCategoryChange = (e) => {
    setNewCategory(e.target.value);
  };

  // Handle changes for new images input
  const handleNewImageChange = (index, value) => {
    console.log(index);
    console.log(value);
    const updatedImages = [...newImages];
    updatedImages[index] = value;
    setNewImages(updatedImages);
  };

  // Add new image input field for the new category
  const addNewImageField = () => {
    setNewImages([...newImages, ""]);
  };

  // Add new greeting object to the payload
  const addGreeting = () => {
    setAddNewGreeting(true);
    if (newCategory && newImages.length) {
      const newGreeting = {
        category: newCategory,
        images: newImages,
      };

      let existing =
        settingsDetails.getGiftCarddata?.gift_card_settings?.greetings?.filter(
          (item) => item.category === newGreeting.category
        );

      console.log("existing", existing);
      if (existing?.length) {
        // already exisying
        console.log("already exisying");
        toast.error("Already Gift Card Name in Exiting List", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        console.log("newGrettings", newaddedCategory.newGrettings);
        console.log("category", newGreeting.category);
        if (newGreeting.category) {
          console.log("-----filter---------", newaddedCategory);
          let newGrettingsAdding = newaddedCategory?.newGrettings?.filter(
            (item) => item.category === newGreeting.category
          );
          console.log("---newGrettingsAdding----", newGrettingsAdding);
          if (newGrettingsAdding?.length) {
            console.log("-All---Added------");
            toast.error("Already Added Category Name", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            setNewaddedCategory((prev) => ({
              ...prev,
              newGrettings: [...(prev.newGrettings || []), newGreeting],
            }));
            setGiftPayload((prev) => ({
              ...prev,
              greetings: [...(prev.greetings || []), newGreeting],
            }));
          }
        } else {
          console.log("---newGrettings---");
        }

        // Reset the form for new category and images
        setNewCategory("");
        setUploadingFiles([]);
        setNewImages([]);
      }
    }
  };
  //function for handling file upload opreation
  const handleUploadRecipt = () => {
    uploadImage.current.click();
  };
  //useEffect for handling file upload operation
  React.useEffect(() => {
    if (uploadReciptFile !== "") {
      dispatch(UploadFile(uploadReciptFile));
      setUploadReciptBtn(true);
      setUploadReciptFile("");
      setUploadFlags(true);
    }
  }, [uploadReciptFile]);
  //file handler
  React.useEffect(() => {
    console.log(fileHandler);
    if (fileHandler.isFileUploaded === true && uploadReciptBtn === true) {
      setReciptUrl(fileHandler.fileName);
      setUploadReciptBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Recipt  uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);
  const handlesubmitgitcard = () => {
    console.log("payload", giftPayload);
    if (
      giftPayload.expiry_day_count !== "" &&
      giftPayload.maximum_amount !== "" &&
      giftPayload.minimum_amount !== "" &&
      giftPayload.one_wallet_equals_to !== ""
    ) {
      dispatch(PostAddOrUpdateGiftCard(giftPayload));
      setTimeout(() => {
        dispatch(GetGiftCarData());
      }, 1000);
      setNewaddedCategory([]);
    } else {
      toast.error("Please fill all required fields", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleCLickCategory = (data, index) => {
    console.log("datas", data);
    // setSelectedCategory(data);
    setSelectedCategoryIndex(index);
    // setIsClicked(!isClicked); // Toggle the clicked state
  };
  const handleAddgitcardImg = (e) => {
    if (uploadingFiles.length <= 4) {
      dispatch(UploadFileGiftCard(e.target.files[0])).then((data) => {
        if (data.filename) {
          dispatch(
            StoreImageFiles(
              `image_${data.filename}`,
              URL.createObjectURL(e.target.files[0])
            )
          );
          setNewUploadedImages((prev) => {
            return {
              ...prev,
              [data.filename]: e.target.files[0],
            };
          });
          setListOfGrettings((prev) => {
            let images = prev?.[selectedCategoryIndex].images || [];
            if (images.indexOf(data.filename) === -1)
              images?.push(data.filename);
            prev[selectedCategoryIndex].images = images;
            return JSON.parse(JSON.stringify(prev));
          });
        }
      });
      setFileSetFlag(true);
    } else {
      toast.error("Already uploaded 5 files", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //function to add gift card template
  const handleAddgitcardTemplate = (e) => {
    if (uploadingFiles.length <= 4) {
      dispatch(UploadFileGiftCard(e.target.files[0])).then((data) => {
        if (data.filename) {
          dispatch(
            StoreImageFiles(
              `image_${data.filename}`,
              URL.createObjectURL(e.target.files[0])
            )
          );

          setGiftPayload((prev) => ({
            ...prev,
            physical_giftcard_template: [
              ...(prev.physical_giftcard_template || []),
              data?.filename,
            ],
          }));
        }
      });
    } else {
      toast.error("Already uploaded 5 files", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  React.useEffect(() => {
    console.log("giftPayload////////////////");
    console.log(giftPayload);
  }, [giftPayload]);
  const handleDeletegitcardImg = (removeImg, index) => {
    console.log("dataaa", removeImg);
    setListOfGrettings((prev) => {
      let images = prev?.[selectedCategoryIndex].images?.filter(
        (item, itemIndex) => itemIndex !== index
      );
      prev[selectedCategoryIndex].images = images;
      return JSON.parse(JSON.stringify(prev));
    });
  };

  //function to delete gift card image
  const handleDeletePhysicalgitcardImg = (data) => {
    let deletedFiles = giftPayload?.physical_giftcard_template?.filter(
      (image) => data !== image
    );
    setGiftPayload((prev) => ({
      ...prev,
      physical_giftcard_template: deletedFiles,
    }));
  };
  const uploadingImages = (e) => {
    if (uploadingFiles.length <= 4) {
      dispatch(UploadFileGiftCard(e.target.files[0])).then((data) => {
        if (data.filename) {
          setUploadingFiles((prev) => [...prev, data.filename]);
          CustomConsole(uploadingFiles);
          setNewImages((prev) => [...prev, data.filename]);
          setFileSetFlag(false);
        }
      });
      setFileSetFlag(true);
    } else {
      toast.error("Already uploaded 5 files", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // React.useEffect(() => {
  //   CustomConsole(fileHandler);
  //   if (fileSetFlag && !fileHandler.error) {
  //     setUploadingFiles([...uploadingFiles, fileHandler.fileName]);
  //     CustomConsole(uploadingFiles);
  //     setNewImages(uploadingFiles);
  //     setFileSetFlag(false);
  //   }
  // }, [fileHandler]);

  const removeFile = (data) => {
    setUploadingFiles((prev) => prev.filter((obj) => obj !== data));
  };

  //function to select gift card value
  const consumerRedeemOption = (event) => {
    const selectedValue = event.target.value;
    setGiftPayload((prev) => ({
      ...prev,
      consumer_to_consumer_redeem_through: selectedValue,
    }));
  };

  //function to select self redeem option
  const consumerSelfRedeemOption = (event) => {
    const selectedValue = event.target.value;

    setGiftPayload((prev) => ({
      ...prev,
      consumer_self_redeem: selectedValue,
      consumer_self_buy:selectedValue
    }));
  };

  //function to select consumer self buy option
  const consumerSelfBuyOption = (event) => {
    const selectedValue = event.target.value;
    setGiftPayload((prev) => ({
      ...prev,
      consumer_self_buy: selectedValue,
    }));
  };
  return (
    <>
      <Box>
        {" "}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={fontStylingSettings}>Common</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "10px",
                // justifyContent: "space-evenly",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Maximum discount allowed(In %)"
                variant="outlined"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
                value={props.minCanPrice}
                onChange={(e) => {
                  props.setMinCanPrice(e.target.value);
                }}
                // sx={{ marginRight: "1em", marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Minimum water wallet amount"
                variant="outlined"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 5);
                }}
                value={props.minimumWallet}
                onChange={(event) => props.setminimumWallet(event.target.value)}
                // sx={{ marginRight: "1em", marginBottom: "1em" }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Water wallet recharge alert"
                value={props.walletRechargeLmt}
                onChange={walletAmtRechargeLimitFun}
                // sx={{ marginRight: "1em", marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Extra charges for single order"
                variant="outlined"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
                value={props.extraCharges}
                onChange={(event) => props.setextraCharges(event.target.value)}
                // sx={{ marginRight: "1em", marginBottom: "1em" }}
              />

              <TextField
                id="outlined-basic"
                variant="outlined"
                label="OTP Less Delivery Msg"
                value={props.otpMsg}
                onChange={(event) => props.setOtpMsg(event.target.value)}
                // sx={{ marginRight: "1em", marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Extra charges HSN code"
                variant="outlined"
                value={props.extraChargesHSNCode}
                onChange={(event) =>
                  props.setExtraChargesHSNCode(event.target.value)
                }
              />
              <TextField
                id="outlined-basic"
                label="Extra charges CGST %"
                variant="outlined"
                value={props.extraChargesCGST}
                onChange={(event) =>
                  props.setExtraChargesCGST(event.target.value)
                }
              />
              <TextField
                id="outlined-basic"
                label="Extra charges SGST %"
                variant="outlined"
                value={props.extraChargesSGST}
                onChange={(event) =>
                  props.setExtraChargesSGST(event.target.value)
                }
              />
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ width: 1, boxShadow: 3, mb: 2 }}>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={{
              "&:hover": {
                backgroundColor: "#d8e5f8de",
              },
              backgroundColor: "#d4dee2",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel1bh-header"
            >
              <Typography sx={fontStylingSettings}>Delivery Slots</Typography>
              <DepartureBoard sx={iconsStyling} />
            </AccordionSummary>
            <AccordionDetails>
              <Box
              // sx={{
              //   display: "flex",
              //   justifyContent: "space-around",
              //   flexWrap: "wrap",
              // }}
              >
                <Box>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleDeliverySlotUi}
                  >
                    <ToggleButton value="getS">Get Slots</ToggleButton>
                    <ToggleButton value="addS">Add Slots</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {alignment === "getS" ? (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "60px",
                      }}
                    >
                      <FormControl sx={{ minWidth: "250px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Existing Delivery Slots
                        </InputLabel>
                        <Select
                          sx={{ marginBottom: "1em" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={existingDeliveryslot}
                          label=" Existing Delivry Slots"
                          onChange={(event) =>
                            setExistingDeliveryslot(event.target.value)
                          }
                        >
                          {settingsDetails.getDeliverySlots.map((data) => {
                            return (
                              <MenuItem value={0}>
                                {data.startTimeSlot} - {data.endTimeSlot}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Box sx={{ margin: "10px" }}>
                        <Tooltip title="Refresh">
                          <Refresh onClick={handleExisitingDeliverySlots} />
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ marginBottom: "10px", marginTop: "10px" }}>
                      <TextField
                        id="outlined-basic"
                        label="Number of Slots"
                        variant="outlined"
                        value={numberOfDeliverySlots}
                        onChange={handleChangeNumberOfSlots}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <>
                            <Box sx={{ margin: "10px" }}>
                              <TimePicker
                                label="Delivery Slot Start Time (AM)"
                                value={fromTimeDS}
                                ampm={false}
                                onChange={handleDeliverySlots}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Box>
                            <Box sx={{ margin: "10px" }}>
                              <TimePicker
                                label="Delivery Slot End Time (PM)"
                                value={toTimeDS}
                                ampm={false}
                                onChange={handleChangeDeliveryEndTime}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Box>
                          </>
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={handleDeliveryslotsAdd}
                        sx={{ margin: "20px" }}
                      >
                        add
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ width: 1, boxShadow: 3, mb: 2 }}>
          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
            sx={{
              "&:hover": {
                backgroundColor: "#d8e5f8de",
              },
              backgroundColor: "#d4dee2",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12bh-content"
              id="panel12bh-header"
            >
              <Typography sx={fontStylingSettings}>Block Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Blocking /> */}
              <TabContext value={tabValue}>
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <TabList
                    variant="scrollable"
                    onChange={handleTabChange}
                    className={classes.tabUi}
                  >
                    <Tab label="Scanning" value="0" />
                    <Tab label="Payments" value="1" />
                    <Tab label="Booking" value="2" />
                    <Tab label="Timing" value="3" />
                    <Tab label="Auto Cancel" value="4" />
                  </TabList>
                </Box>
                <TabPanel value="0">
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <FormControl>
                        <FormLabel>Block In-Scan</FormLabel>
                        <RadioGroup
                          row
                          value={blockInScanRadio}
                          onChange={(e) => setBlockInScanRadio(e.target.value)}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="PRE-PAID"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="POSTPAID"
                          />
                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="BOTH"
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="NONE"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ m: 2 }}>
                      {" "}
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleUpdateBlockInScanning}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="1">
                  <PaymentBlocking />
                </TabPanel>
                <TabPanel value="2">
                  <BookingBlock />
                </TabPanel>
                <TabPanel value="3">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start time"
                          value={blockStart}
                          onChange={(newValue) => {
                            CustomConsole(
                              newValue != null && newValue.$d != null
                            );
                            const hours =
                              newValue != null &&
                              newValue.$d != null &&
                              newValue.$d
                                .getHours()
                                .toString()
                                .padStart(2, "0");
                            const minutes =
                              newValue != null &&
                              newValue.$d != null &&
                              newValue.$d
                                .getMinutes()
                                .toString()
                                .padStart(2, "0");
                            const seconds =
                              newValue != null &&
                              newValue.$d != null &&
                              newValue.$d
                                .getSeconds()
                                .toString()
                                .padStart(2, "0");
                            const textValue =
                              hours + ":" + minutes + ":" + seconds;
                            CustomConsole(textValue);
                            setStartTime(textValue);
                            setBlockStart(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <Box>
                        <Typography>{props.blockStart}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="End time"
                          value={blockEnd}
                          onChange={(newValue) => {
                            CustomConsole(
                              newValue != null &&
                                newValue.$d != null &&
                                newValue.$d
                            );
                            const hours =
                              newValue != null &&
                              newValue.$d != null &&
                              newValue.$d
                                .getHours()
                                .toString()
                                .padStart(2, "0");
                            const minutes =
                              newValue != null &&
                              newValue.$d != null &&
                              newValue.$d
                                .getMinutes()
                                .toString()
                                .padStart(2, "0");
                            const seconds =
                              newValue != null &&
                              newValue.$d != null &&
                              newValue.$d
                                .getSeconds()
                                .toString()
                                .padStart(2, "0");
                            const textValue =
                              hours + ":" + minutes + ":" + seconds;
                            CustomConsole(textValue);
                            setEndTime(textValue);
                            setBlockEnd(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <Box>
                        <Typography>{props.blockEnd}</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ padding: "10px" }}>
                      <Button variant="contained" onClick={handleUpdate}>
                        Update
                      </Button>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="4">
                  <AutoCancel />
                </TabPanel>
              </TabContext>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ width: 1, boxShadow: 3, mb: 2 }}>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            sx={{
              "&:hover": {
                backgroundColor: "#d8e5f8de",
              },
              backgroundColor: "#d4dee2",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel1bh-header"
            >
              <Typography sx={fontStylingSettings}>Sold By</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <FormControl sx={{ minWidth: 250 }}>
                      <InputLabel>Select Sold By</InputLabel>
                      <Select
                        value={props.soldBy}
                        label="Select Sold By"
                        onChange={handleChangeSelectSoldBy}
                      >
                        <MenuItem value={soldBySelect.SOLD_BY_BOOKWATER}>
                          BookWater
                        </MenuItem>
                        <MenuItem value={soldBySelect.SOLD_BY_DISTRIBUTOR}>
                          Distributor
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    {props.soldBy === 1 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Typography>Sold By :</Typography>
                        <TextField
                          value={props.companyName}
                          onChange={(event) =>
                            props.setCompanyName(event.target.value)
                          }
                          label="Company Name"
                        />
                        <TextField
                          value={props.panNumber}
                          // onChange={(event) =>
                          //   props.setPanNumber(event.target.value)
                          // }
                          onChange={handleChangePanValidation}
                          error={companyPanEr}
                          label="PAN Number"
                        />
                        <TextField
                          value={props.gstNumber}
                          // onChange={(event) => props.setGstNumber(event.target.value)}
                          onChange={handleChangeGstNumber}
                          error={gstNumEr}
                          label="GST Number"
                        />
                        <TextField
                          value={props.address}
                          onChange={(event) =>
                            props.setAddress(event.target.value)
                          }
                          multiline
                          rows={4}
                          label="Address"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Button variant="contained" onClick={handleUpdate}>
                  Update
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ width: 1, boxShadow: 3, mb: 2 }}>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            sx={{
              "&:hover": {
                backgroundColor: "#d8e5f8de",
              },
              backgroundColor: "#d4dee2",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel1bh-header"
            >
              <Typography sx={fontStylingSettings}>
                Referral Settings
                <Campaign />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext
                  value={referralTabValue}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChangeReferralTabs}>
                      <Tab label="Signup" value="ROS" />
                      <Tab label="Order Completion" value="ROOC" />
                    </TabList>
                  </Box>
                  <TabPanel value="ROOC">
                    <Box>
                      {/* Referee & Referrer Reward Details for Order Completion  & Water Drop Worth*/}

                      <Paper elevation={12} sx={{ margin: "10px" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "brown",
                          }}
                        >
                          Consumer Referee & Referrer rewards
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <TextField
                            error={
                              refereeReferrerRewards.referrer_limit < 0 ||
                              refereeReferrerRewards.referrer_limit === ""
                            }
                            label="Referrer reward limit"
                            type="number"
                            variant="standard"
                            value={refereeReferrerRewards.referrer_limit}
                            onChange={(event) =>
                              handleChangeRefereeReferrerRewards(
                                "referrer_limit",
                                event.target.value
                              )
                            }
                            sx={{ margin: "15px" }}
                          />
                          <TextField
                            error={
                              refereeReferrerRewards.referee_limit < 0 ||
                              refereeReferrerRewards.referee_limit === ""
                            }
                            label="Referee reward limit"
                            type="number"
                            variant="standard"
                            value={refereeReferrerRewards.referee_limit}
                            onChange={(event) =>
                              handleChangeRefereeReferrerRewards(
                                "referee_limit",
                                event.target.value
                              )
                            }
                            sx={{ margin: "15px" }}
                          />
                          <TextField
                            error={
                              refereeReferrerRewards.water_drops < 0 ||
                              refereeReferrerRewards.water_drops === ""
                            }
                            label="Water drops per order"
                            type="number"
                            variant="standard"
                            value={refereeReferrerRewards.water_drops}
                            onChange={(event) =>
                              handleChangeRefereeReferrerRewards(
                                "water_drops",
                                event.target.value
                              )
                            }
                            sx={{ margin: "15px" }}
                          />
                          <TextField
                            error={
                              refereeReferrerRewards.water_drop_worth < 0 ||
                              refereeReferrerRewards.water_drop_worth === ""
                            }
                            label="Worth of each waterdrop"
                            type="number"
                            variant="standard"
                            value={refereeReferrerRewards.water_drop_worth}
                            onChange={(event) =>
                              handleChangeRefereeReferrerRewards(
                                "water_drop_worth",
                                event.target.value
                              )
                            }
                            sx={{ margin: "15px" }}
                          />
                        </Box>
                      </Paper>

                      {/* Influencer Reward ponits and Worth */}
                      <Paper elevation={12} sx={{ margin: "10px" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "brown",
                          }}
                        >
                          Influencer rewards
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <TextField
                            error={
                              influencerRewards.water_drops < 0 ||
                              influencerRewards.water_drops === ""
                            }
                            label="Water drops per order"
                            type="number"
                            variant="standard"
                            value={influencerRewards.water_drops}
                            onChange={(event) =>
                              handleChangeInfluencerRewards(
                                "water_drops",
                                event.target.value
                              )
                            }
                            sx={{ margin: "15px" }}
                          />
                          <TextField
                            error={
                              influencerRewards.water_drop_worth < 0 ||
                              influencerRewards.water_drop_worth === ""
                            }
                            label="Worth of each waterdrop"
                            type="number"
                            variant="standard"
                            value={influencerRewards.water_drop_worth}
                            onChange={(event) =>
                              handleChangeInfluencerRewards(
                                "water_drop_worth",
                                event.target.value
                              )
                            }
                            sx={{ margin: "15px" }}
                          />
                        </Box>
                      </Paper>
                      <Button
                        sx={{ marginTop: "20px" }}
                        variant="contained"
                        onClick={handleUpdateOrderCompletionReferralRewards}
                      >
                        Update
                      </Button>
                    </Box>
                  </TabPanel>
                  <TabPanel value="ROS">
                    <Box
                      sx={{
                        diaply: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "30px",
                      }}
                    >
                      <TextField
                        error={
                          signUpReferralRewards.referrer < 0 ||
                          signUpReferralRewards.referrer === ""
                        }
                        label="Water drops for referrer"
                        type="number"
                        variant="standard"
                        value={signUpReferralRewards.referrer}
                        onChange={(event) =>
                          handleSignUpReferralRewards(
                            "referrer",
                            event.target.value
                          )
                        }
                        sx={{ margin: "15px" }}
                      />
                      <TextField
                        error={
                          signUpReferralRewards.referee < 0 ||
                          signUpReferralRewards.referee === ""
                        }
                        label="Water drops for referee"
                        type="number"
                        variant="standard"
                        value={signUpReferralRewards.referee}
                        onChange={(event) =>
                          handleSignUpReferralRewards(
                            "referee",
                            event.target.value
                          )
                        }
                        sx={{ margin: "15px" }}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      onClick={handleUpdateSignUpReferralRewards}
                    >
                      Update
                    </Button>
                  </TabPanel>
                </TabContext>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            {" "}
            <Typography sx={fontStylingSettings}>
              convenience Fee setting
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Conv fee long term booking"
                  variant="outlined"
                  value={props.convFeeLTBooking}
                  onChange={(event) =>
                    props.setConvFeeLTBooking(event.target.value)
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Conc fee single booking"
                  variant="outlined"
                  value={props.convFeeSTBooking}
                  onChange={(event) =>
                    props.setConvFeeSTBooking(event.target.value)
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Conv fee CGST (%)"
                  variant="standard"
                  value={props.convFeeCGST}
                  onChange={(event) => props.setConvFeeCGST(event.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  label="Conv fee SGST (%)"
                  variant="standard"
                  value={props.convFeeSGST}
                  onChange={(event) => props.setConvFeeSGST(event.target.value)}
                />
              </Box>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Conv fee GST code"
                  variant="outlined"
                  value={props.convFeeGSTCode}
                  onChange={(event) =>
                    props.setConvFeeGSTCode(event.target.value)
                  }
                />
              </Box>
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            {" "}
            <Typography sx={fontStylingSettings}>Time settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Supplier order acceptance intervel(minutes)"
                variant="outlined"
                value={props.supplierOrderAcceptanceInterval}
                onChange={(event) =>
                  props.setSupplierOrderAcceptanceInterval(event.target.value)
                }
                sx={{ marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Consumer notify time"
                variant="outlined"
                value={props.consumerNotifyTime}
                onChange={(event) =>
                  props.setConsumerNotifyTime(event.target.value)
                }
                sx={{ marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Consumer disable time"
                variant="outlined"
                value={props.consumerDisableTime}
                onChange={(event) =>
                  props.setConsumerDisabletTime(event.target.value)
                }
                sx={{ marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Distributor notify time"
                variant="outlined"
                value={props.distributorNotifyTime}
                onChange={(event) =>
                  props.setDistributorNotifyTime(event.target.value)
                }
                sx={{ marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="Distributor disable time"
                variant="outlined"
                value={props.distributorDisableTime}
                onChange={(event) =>
                  props.setDistributorDisableTime(event.target.value)
                }
                sx={{ marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="WFS notify time"
                variant="outlined"
                value={props.wfsNotifyTime}
                onChange={(event) => props.setWfsNotifyTime(event.target.value)}
                sx={{ marginBottom: "1em" }}
              />
              <TextField
                id="outlined-basic"
                label="WFS disable time"
                variant="outlined"
                value={props.wfsDisableTime}
                onChange={(event) =>
                  props.setWfsDisableTime(event.target.value)
                }
                sx={{ marginBottom: "1em" }}
              />
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ width: 1, boxShadow: 3, mb: 2 }}>
          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
            sx={{
              "&:hover": {
                backgroundColor: "#d8e5f8de",
              },
              backgroundColor: "#d4dee2",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel1bh-header"
            >
              <Typography sx={fontStylingSettings}>
                Settlement Settings
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Distributor Settlement Price"
                  variant="outlined"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  value={props.distributorSettlement}
                  onChange={(event) =>
                    props.setdistributorSettlement(event.target.value)
                  }
                  sx={{ marginRight: "1em", marginBottom: "1em" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Base Watercan Price"
                  variant="outlined"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  value={props.baseWaterPrice}
                  onChange={(event) =>
                    props.setbaseWaterPrice(event.target.value)
                  }
                  sx={{ marginRight: "1em", marginBottom: "1em" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Filling Station Settlement Price"
                  variant="outlined"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  value={props.wfsSettlement}
                  onChange={(event) =>
                    props.setwfsSettlement(event.target.value)
                  }
                  sx={{ marginRight: "1em", marginBottom: "1em" }}
                />
              </Box>
              <Box sx={{ padding: "10px" }}>
                <Button variant="contained" onClick={handleUpdate}>
                  Update
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            {" "}
            <Typography sx={fontStylingSettings}>
              PDW convenience Fee
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px" }}>
                <TextField
                  id="outlined-basic"
                  label="PDW CGST (%)"
                  variant="standard"
                  value={props.pwtConvFeeCGST}
                  onChange={(event) =>
                    props.setPwtConvFeeCGST(event.target.value)
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="PDW SGST (%)"
                  variant="standard"
                  value={props.pwtConvFeeSGST}
                  onChange={(event) =>
                    props.setPwtConvFeeSGST(event.target.value)
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="PDW IGST (%)"
                  variant="standard"
                  value={props.pwtConvFeeIGST}
                  onChange={(event) =>
                    props.setPwtConvFeeIGST(event.target.value)
                  }
                />
              </Box>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="PDW GST code"
                  variant="outlined"
                  value={props.pwtConvFeeGSTCode}
                  onChange={(event) =>
                    props.setPwtConvFeeGSTCode(event.target.value)
                  }
                />
              </Box>
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            {" "}
            <Typography sx={fontStylingSettings}>
              Cancellation fee setting
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              id="outlined-basic"
              label="Supplier cancel fee %"
              variant="outlined"
              value={props.supplierCancelFee}
              onChange={(event) =>
                props.setSupplierCancelFee(event.target.value)
              }
              sx={{ marginRight: "1em", marginBottom: "1em" }}
            />
            <TextField
              id="outlined-basic"
              label="Consumer cancel fee %"
              variant="outlined"
              value={props.consumerCancelFee}
              onChange={(event) =>
                props.setConsumerCancelFee(event.target.value)
              }
            />{" "}
            <Box sx={{ padding: "10px" }}>
              <Button variant="contained" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            {" "}
            <Typography sx={fontStylingSettings}>Force Apps Update</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {" "}
              <Paper sx={{ padding: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "start", m: 1, fontWeight: "bold" }}
                  >
                    Admin App
                  </Typography>
                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Update Is mandatory{" "}
                    </FormLabel>
                    <RadioGroup
                      value={adminAppUpdate.update_mandatory}
                      onChange={handleChangeAdminUpdateRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="Admin App Current Version"
                    variant="outlined"
                    size="small"
                    value={adminAppUpdate.admin_version}
                    onChange={(event) =>
                      setAdminAppUpdate((prev) => ({
                        ...prev,
                        admin_version: event.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Message"
                    variant="outlined"
                    size="small"
                    value={adminAppUpdate.msg}
                    onChange={(event) =>
                      setAdminAppUpdate((prev) => ({
                        ...prev,
                        msg: event.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Header"
                    variant="outlined"
                    size="small"
                    value={adminAppUpdate.header}
                    onChange={(event) =>
                      setAdminAppUpdate((prev) => ({
                        ...prev,
                        header: event.target.value,
                      }))
                    }
                  />
                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Force App Tour{" "}
                    </FormLabel>
                    <RadioGroup
                      value={adminAppUpdate.force_app_tour}
                      onChange={handleChangeAdminForceAppTourRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Paper>
              <Paper sx={{ padding: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "start", m: 1, fontWeight: "bold" }}
                  >
                    Android
                  </Typography>
                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Update Is mandatory{" "}
                    </FormLabel>
                    <RadioGroup
                      value={andtiodUpdate.update_mandatory}
                      onChange={handleChangeandriodUpdateRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="Android Current Version"
                    variant="outlined"
                    size="small"
                    value={andtiodUpdate.consumer_version}
                    onChange={(event) =>
                      setAndtiodUpdate((prev) => ({
                        ...prev,
                        consumer_version: event.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Message"
                    variant="outlined"
                    size="small"
                    value={andtiodUpdate.msg}
                    onChange={(event) =>
                      setAndtiodUpdate((prev) => ({
                        ...prev,
                        msg: event.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Header"
                    variant="outlined"
                    size="small"
                    value={andtiodUpdate.header}
                    onChange={(event) =>
                      setAndtiodUpdate((prev) => ({
                        ...prev,
                        header: event.target.value,
                      }))
                    }
                  />
                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Force App Tour{" "}
                    </FormLabel>
                    <RadioGroup
                      value={andtiodUpdate.force_app_tour}
                      onChange={handleChangeandriodForceAppTourRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Paper>
              <Paper sx={{ padding: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "start", m: 1, fontWeight: "bold" }}
                  >
                    iOS
                  </Typography>
                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Update Is mandatory{" "}
                    </FormLabel>
                    <RadioGroup
                      value={iosUpdate.update_mandatory}
                      onChange={handleChangeIosUpdateRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="Current Version"
                    variant="outlined"
                    size="small"
                    value={iosUpdate.consumer_version}
                    onChange={(event) =>
                      setIosUpdate((prev) => ({
                        ...prev,
                        consumer_version: event.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Message"
                    variant="outlined"
                    size="small"
                    value={iosUpdate.msg}
                    onChange={(event) =>
                      setIosUpdate((prev) => ({
                        ...prev,
                        msg: event.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Header"
                    variant="outlined"
                    size="small"
                    value={iosUpdate.header}
                    onChange={(event) =>
                      setIosUpdate((prev) => ({
                        ...prev,
                        header: event.target.value,
                      }))
                    }
                  />
                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Force App Tour{" "}
                    </FormLabel>
                    <RadioGroup
                      value={iosUpdate.force_app_tour}
                      onChange={handleChangeIosForceAppTourRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Paper>
              <Paper sx={{ padding: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "start", m: 1, fontWeight: "bold" }}
                  >
                    Web App
                  </Typography>

                  <FormControl>
                    <FormLabel sx={{ textAlign: "start" }}>
                      Force App Tour{" "}
                    </FormLabel>
                    <RadioGroup
                      value={webAppUpdate.force_app_tour}
                      onChange={handleChangeWebAppForceAppTourRadio}
                      row
                      sx={{ gap: "30px" }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="Current Version"
                    variant="outlined"
                    size="small"
                    value={webAppUpdate.consumer_version}
                    onChange={(event) =>
                      setWebAppUpdate((prev) => ({
                        ...prev,
                        consumer_version: event.target.value,
                      }))
                    }
                  />
                </Box>
              </Paper>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Button variant="contained" onClick={handleConsumerAppsUpdate}>
                Update
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel16"}
          onChange={handleChange("panel16")}
          sx={Style.boxStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            {" "}
            <Typography sx={fontStylingSettings}>Gift Card</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                marginTop: 4,
                margin: "auto",
              }}
            >
              {/* Toggle Enable Switch */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "flex-start", xs: "center" },
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={giftPayload.isEnable}
                      onChange={(e) =>
                        setGiftPayload((prev) => ({
                          ...prev,
                          isEnable: !prev.isEnable,
                        }))
                      }
                    />
                  }
                  label="Enable"
                  sx={{ marginBottom: 2 }}
                />
              </Box>
              {/* Input fields for settings */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {/* Minimum Amount */}
                <TextField
                  size="small"
                  label="Minimum Amount*"
                  sx={{ width: "10rem" }}
                  name="minimum_amount"
                  onInput={(e) => {
                    e.target.value = Math.max(1, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  type="number"
                  value={giftPayload.minimum_amount}
                  onChange={handleInputChange}
                />

                {/* Maximum Amount */}
                <TextField
                  size="small"
                  label="Maximum Amount*"
                  sx={{ width: "10rem" }}
                  name="maximum_amount"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(1, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  value={giftPayload.maximum_amount}
                  onChange={handleInputChange}
                />

                {/* Expiry Day Count */}
                <TextField
                  size="small"
                  label="Expiry Day Count*"
                  name="expiry_day_count"
                  sx={{ width: "10rem" }}
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(1, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  value={giftPayload.expiry_day_count}
                  onChange={handleInputChange}
                />

                {/* One Wallet Equals To */}
                <TextField
                  size="small"
                  label="One Rupee(₹) Equals To"
                  name="one_wallet_equals_to"
                  sx={{ width: "10rem" }}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <span
                        style={{
                          marginLeft: "5px",
                          color: "gray",
                          fontWeight: "bold",
                          fontSize: ".7rem",
                        }}
                      >
                        WaterWallet
                      </span>
                    ),
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(1, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  value={giftPayload.one_wallet_equals_to}
                  onChange={handleInputChange}
                />

                {/* One Waterdrops Equals To */}
                <TextField
                  size="small"
                  label="One Rupee(₹) Equals To"
                  name="one_waterdrops_equals_to"
                  sx={{ width: "10rem" }}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <span
                        style={{
                          marginLeft: "5px",
                          color: "gray",
                          fontWeight: "bold",
                          fontSize: ".7rem",
                        }}
                      >
                        WaterDrops
                      </span>
                    ),
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(1, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  value={giftPayload.one_waterdrops_equals_to}
                  onChange={handleInputChange}
                />
              </Box>
              <div
                style={{
                  borderBottom: "2px dashed #808080",
                  marginTop: "1rem",
                }}
              ></div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent:"space-between",
                  flexDirection: { xs: "column", md: "row" },
                  flexWrap: "wrap",
                  gap: 2,
                  paddingTop: ".5rem",
                }}
              >
                <FormControl>
                  <FormLabel sx={{ textAlign: "start" }}>
                    Consumer Redeem Option{" "}
                  </FormLabel>
                  <RadioGroup
                    value={giftPayload?.consumer_to_consumer_redeem_through}
                    onChange={consumerRedeemOption}
                    row
                    sx={{ gap: "30px" }}
                  >
                    <FormControlLabel
                      value="WALLET"
                      control={<Radio />}
                      label="Wallet"
                    />
                    <FormControlLabel
                      value="WATER_DROPS"
                      control={<Radio />}
                      label="Water Drops"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ textAlign: "start" }}>
                    Consumer Self-Redeem and Self-Buying Option
                  </FormLabel>
                  <RadioGroup
                    value={giftPayload?.consumer_self_redeem}
                    onChange={consumerSelfRedeemOption}
                    row
                    sx={{ gap: "30px" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Activate"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Deactivate"
                    />
                  </RadioGroup>
                </FormControl>

            
              </Box>
              <div
                style={{
                  borderBottom: "2px dashed #808080",
                  marginTop: "1rem",
                }}
              ></div>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: ".5rem 0",
                }}
              >
                {/* <FormControlLabel
                  label="ADD GREETING"
                  control={
                    <Checkbox
                      checked={addGreetingCheck}
                      onChange={(e) => setAddGreetingCheck(e.target.checked)}
                    />
                  }
                />{" "} */}
                <Typography sx={fontStylingSettings}>Add Greeting</Typography>
              </Box>

              {/* Section to add new greeting */}

              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <>
                    <TextField
                      size="small"
                      label="Enter Greeting Name *"
                      value={newCategory}
                      onChange={handleNewCategoryChange}
                      sx={{ marginBottom: 2 }}
                    />

                    {/* Image inputs for new greeting */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        justifyContent: "center",
                      }}
                    >
                      {/* {newImages.map((image, idx) => ( */}
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Box>
                          <Tooltip title="You can upload max of 5 files" arrow>
                            <Button
                              variant="contained"
                              component="label"
                              size="small"
                              sx={{
                                backgroundColor: "#3498DB",
                              }}
                            >
                              <FileUpload /> Upload images
                              <input
                                hidden
                                multiple
                                type="file"
                                onChange={uploadingImages}
                              />
                            </Button>
                          </Tooltip>
                        </Box>
                        <Box>
                          {uploadingFiles && uploadingFiles.length > 0
                            ? uploadingFiles.map((data) => {
                                return (
                                  <Box
                                    // key={idx + 1}
                                    sx={{
                                      padding: "5px",
                                      backgroundColor: "#0a0c4a",
                                      borderRadius: "20px",
                                      marginTop: "5px",
                                      // fontSize: appTheme.fontSizexs,
                                      fontWeight: "700",
                                      display: "flex",
                                      gap: "20px",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                      color: "white",
                                    }}
                                  >
                                    Uploaded :{data}
                                    <Close
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        removeFile(data);
                                      }}
                                    />
                                  </Box>
                                );
                              })
                            : null}
                        </Box>
                      </Box>
      </Box>
                  </>

                  {/* Add Greeting Button */}
                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={addGreeting}
                      size="small"
                    >
                      Add Greetings
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {newaddedCategory.newGrettings !== undefined &&
                    newaddedCategory.newGrettings !== null &&
                    newaddedCategory.newGrettings?.map((data) => {
                      return (
                        <>
                          {" "}
                          <Paper elevation={10} sx={{ padding: "10px" }}>
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ fontWeight: "bold" }}>
                                Greeting Name :
                              </Typography>
                              <Typography>{data.category}</Typography>
                            </Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Images :
                            </Typography>
                            {data.images?.map((img) => {
                              return <li>{img}</li>;
                            })}
                          </Paper>
    </>
  );
                    })}
                </Box>
              </>
              <div style={{ borderBottom: "2px dashed #808080" }}></div>
              {/* Greetings Section */}

              {listOfGrettings !== undefined &&
              listOfGrettings !== null &&
              listOfGrettings.length > 0 ? (
                <Box>
                  <Box mt={1}>
                    <Typography sx={fontStylingSettings}>
                      LIST OF GREETINGS:
                    </Typography>
                    <Box
                      sx={{
                        m: 2,
                        display: "flex",
                        // justifyContent: "space-around",
                        flexDirection: "row",
                        gap: "30px",
                        overflow: "auto",
                      }}
                    >
                      {listOfGrettings !== undefined &&
                        listOfGrettings !== null &&
                        listOfGrettings.length > 0 &&
                        listOfGrettings?.map((greeting, index) => (
                          <div key={greeting.category}>
                            <Box
                              onClick={() =>
                                handleCLickCategory(greeting, index)
                              }
                              elevation={20}
                              sx={{
                                padding: "5px",
                                cursor: "pointer",
                                backgroundColor:
                                  greeting.category ===
                                  selectedCategory?.category
                                    ? "#5dade2"
                                    : "#d6eaf8",
                                border:
                                  greeting.category ===
                                  selectedCategory?.category
                                    ? "2px solid #21618c"
                                    : "none",
                                color:
                                  greeting.category ===
                                  selectedCategory?.category
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {greeting?.category}
                            </Box>
                          </div>
                        ))}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "flex-start",
                        flexWrap:"wrap",
                        // ml: 2,
                        height: "140px",
                        width: "100%",
                        overflowX: "auto",
                      }}
                    >
                      {selectedCategory?.images !== undefined &&
                        selectedCategory?.images !== null &&
                        selectedCategory.images?.map((data, imageIndex) => {
                          // data = newUploadedImages.
                          return (
                            <Box
                              sx={{
                                minWidth: "4rem",
                                minHeight: "4rem",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <DeleteForever
                                  sx={{
                                    color: "red",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                  onClick={() =>
                                    handleDeletegitcardImg(data, imageIndex)
                                  }
                                />
                              </Box>
                              <ImageViewer filename={data} />
                            </Box>
                          );
                        })}
                      <Box>
                        <Tooltip title="upload file" arrow>
                          <Button
                            variant="contained"
                            component="label"
                            size="small"
                            sx={{
                              backgroundColor: "#3498DB",
                            }}
                          >
                            <Add /> Img
                            <input
                              hidden
                              multiple
                              type="file"
                              onChange={handleAddgitcardImg}
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}

              <div style={{ borderBottom: "2px dashed #808080" }}></div>
              {/* Greetings Section */}

              {listOfGrettings !== undefined &&
              listOfGrettings !== null &&
              listOfGrettings.length > 0 ? (
                <Box>
                  <Box mt={1}>
                    <Typography sx={fontStylingSettings}>
                      ADD PHYSICAL GIFT CARD TEMPLATE:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "flex-start",
                        // ml: 2,
                        // height: "15rem",
                        // width: "70vw",
                        overflowX: "scroll",
                      }}
                    >
                      {giftPayload?.physical_giftcard_template !== undefined &&
                        giftPayload?.physical_giftcard_template !== null &&
                        giftPayload.physical_giftcard_template?.map(
                          (data, imageIndex) => {
                            // data = newUploadedImages.
                            return (
                              <Box
                                sx={{
                                  minWidth: "4rem",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <DeleteForever
                                    sx={{
                                      color: "red",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                    onClick={() =>
                                      handleDeletePhysicalgitcardImg(
                                        data,
                                        imageIndex
                                      )
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: "50%",
                                    // height: "4rem",
                                  }}
                                >
                                  <RenderGiftCards
                                    templateFileName={data}
                                    renderData={renderData}
                                    page={false}
                                  />
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      <Box>
                        <Tooltip title="upload file" arrow>
                          <Button
                            variant="contained"
                            component="label"
                            size="small"
                            sx={{
                              backgroundColor: "#3498DB",
                            }}
                          >
                            <Add /> Img
                            <input
                              hidden
                              multiple
                              type="file"
                              onChange={handleAddgitcardTemplate}
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}
              <Button
                variant="contained"
                size="small"
                onClick={() => handlesubmitgitcard()}
                sx={{ mt: "10px" }}
              >
                submit
              </Button>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

const AutoCancel = () => {
  const dispatch = useDispatch();
  const [autocancelBlock, setAutocancelBlock] = useState({});
  const blockedBookingData = useSelector(
    (state) => state.appSettingsDetails.blockedBookings
  );
  React.useEffect(() => {
    if (blockedBookingData) {
      //@Removed/console.log(
      //   blockedBookingData.auto_cancel,
      //   "blockedBookingDatablockedBookingData"
      // );
    }
    setAutocancelBlock(blockedBookingData.auto_cancel);
  }, [blockedBookingData]);
  const updateAutoCancel = (event) => {
    //  e.preventDefault();
    const reqObj = {
      auto_cancel: { is_blocked: true, reason: autocancelBlock.reason },
    };

    dispatch(BlockBooking(reqObj));
  };
  const deleteAutoCancel = (event) => {
    //  e.preventDefault();
    const reqObj = { auto_cancel: { is_blocked: false, reason: "" } };

    dispatch(BlockBooking(reqObj));
  };
  React.useEffect(() => {
    dispatch(GetBlockedBookings());
  }, []);
  return (
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      {blockedBookingData?.auto_cancel?.is_blocked && (
        <Box>
          <Chip
            label={`Reason: ${blockedBookingData?.auto_cancel?.reason}`}
            color="warning"
            onDelete={deleteAutoCancel}
          />
        </Box>
      )}
      <Box>
        <TextField
          id="outlined-multiline-flexible"
          label="Reason.."
          variant="outlined"
          required
          multiline
          value={autocancelBlock?.reason || ""}
          onChange={(e) =>
            setAutocancelBlock((prev) => ({ ...prev, reason: e.target.value }))
          }
        />
      </Box>
      <Box>
        <Button variant="contained" onClick={updateAutoCancel}>
          {blockedBookingData?.auto_cancel?.is_blocked ? "Update" : "Block"}
        </Button>
      </Box>
    </Box>
  );
};
