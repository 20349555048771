import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import WfsCompletedBatchCard from "../../Components/Cards/WfsCompletedBatchCard";
import CompletedBatchEditDialog from "../../Components/DialogBoxes/WfsCanOrdersDialog/CompletedBatchEditDialog";
import { PutWfsEditBatchDetails } from "../Redux/Actions/adminWfsCanOrdersAction";
import { GetWfsCompletedBatchDetails } from "../Redux/Actions/adminWfsCanOrdersAction";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import { toast } from "react-toastify";
import WfsBatchFilterDialog from "../../Components/Dialog/wfsBatchDetailsFilterDialog";
import { GetWfsBatchDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import WfsCreateBatchDialog from "../../Components/Dialog/wfsCreateBatch";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { GetWfsOngoingBatchLineDetails } from "../Redux/Actions/wfsOnGoingBatchAction";
import CustomConsole from "../../CustomConsole";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function WfsCompletedBatchDetails(props) {
  const loggedInUserRole =
    localStorage.getItem("roleId") &&
    localStorage.getItem("roleId") !== "undefined"
      ? parseInt(localStorage.getItem("roleId"))
      : 0;
  const [editOpenDialog, setEditOpenDialog] = React.useState(false);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const wfsStockDetails = useSelector((state) => state.userDetailsUpdate);
  const skuWfsDetails = useSelector((state) => state.skuWfsDetails);
  CustomConsole("----------skuWfsDetails------------------");
  CustomConsole(skuWfsDetails);
  const [wfsbatchmasterid, setWfsbatchmasterid] = React.useState("");
  const [wfsBatchDetails, setWfsBatchDetails] = React.useState([]);
  const [waterQualitydetailsid, setWaterQualitydetailsid] = React.useState("");
  const [phoneNumberWfs, setPhoneNumberWfs] = React.useState(props.phoneNumber);
  const [wfsMftDate, setWfsMftDate] = React.useState("");
  const [wfsExpiryDate, setWfsExpiryDate] = React.useState("");
  const [openBatchFilterDialog, setOpenBatchFilter] = React.useState(false);
  const [createBatchDialog, setCreateBatchDialog] = React.useState(false);
  const dispatch = useDispatch();
  const adminWfsCanOrdersData = useSelector(
    (state) => state.adminWfsCanOrdersData
  );
  CustomConsole(adminWfsCanOrdersData);
  const [batchFilter, setBatchFilter] = React.useState({
    page_number: 1,
    page_size: 2,
    wfs_id:
      wfsStockDetails.assignedWfsAddress?.wfs_id === undefined
        ? wfsDetails.data?.wfs_id
        : wfsStockDetails.assignedWfsAddress?.wfs_id,
    material: "",
    capacity: "",
    batch_id: "",
    is_batch_started: "",
    is_batch_stopped: "",
    is_settled: "",
    sort_updated_at: "",
    sort_created_at: "",
    sort_batch_id: "DESC",
    sort_quantity: "",
    sort_batch_start_time: "",
    sort_material: "",
    sort_capacity: "",
    sort_batch_stop_time: "",
  });

  //useEffect for getting initial data for batch details
  React.useEffect(() => {
    // if (loggedInUserRole !== ADMIN_ROLES.BW_WFSADMIN) {
    dispatch(GetWfsBatchDetailsSku(batchFilter));
    // }
  }, [batchFilter.page_number, batchFilter.page_size]);

  //useEffect for getting initial data for batch details
  React.useEffect(() => {
    if (loggedInUserRole !== ADMIN_ROLES.BW_WFSADMIN) {
      dispatch(GetAvailableProducts());
    }
  }, []);

  //useEffect for getting initial data for batch details
  React.useEffect(() => {
    const reqObj =
      wfsStockDetails.assignedWfsAddress?.wfs_id === undefined
        ? wfsDetails.data?.wfs_id
        : wfsStockDetails.assignedWfsAddress?.wfs_id;
    dispatch(GetWfsOngoingBatchLineDetails(reqObj));
    // return () => {
    //   wfsStockDetails.assignedWfsAddress = [];
    // };
  }, [wfsStockDetails.assignedWfsAddress]);
  //function for handling edit batch submit dialog
  const handleCompletedBatchEdit = (props) => {
    CustomConsole(props);
    CustomConsole("---------------handleCompletedBatchEdit----------------");
    setWaterQualitydetailsid(props.waterQuality_details_id);
    setWfsbatchmasterid(props.wfs_batch_master_id);
    setWfsMftDate(props.mft_date);
    setWfsExpiryDate(props.exp_date);
    CustomConsole(wfsExpiryDate);
    CustomConsole(wfsMftDate);
    setEditOpenDialog(true);
  };

  //function for handling edit batch dialog closing
  const handleCloseDialog = () => {
    CustomConsole("---------------handleCloseDialog----------------");
    setEditOpenDialog(false);
  };

  //function for handling edit data
  const handleSubmitEditData = (props) => {
    const reqObj = {
      mft_date: props.Manufacturing_date,
      exp_date: props.Expiry_date,
      water_quality_details_id: waterQualitydetailsid,
      wfs_batch_master_id: wfsbatchmasterid,
      quality_report: props.WQReportUrl,
      phone: phoneNumberWfs,
    };
    CustomConsole(reqObj);
    dispatch(PutWfsEditBatchDetails(reqObj));
    setTimeout(() => {
      dispatch(GetWfsBatchDetailsSku(props.batchFilter));
    }, 2000);
    setEditOpenDialog(false);
  };
  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setBatchFilter((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };
  const handlePageSize = (event) => {
    setBatchFilter((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };

  const handleSearchPageSize = () => {
    dispatch(GetWfsBatchDetailsSku(batchFilter));
  };

  //function for handling filter dialog
  const handelCloseFilterDialog = () => {
    setOpenBatchFilter(false);
  };

  //function for submiting filter dialog
  const handelSubmitFilterDialog = () => {
    setOpenBatchFilter(false);
    handleSearchPageSize();
  };

  //function for closing create batch dialog
  const handelCloseCreateBatchDialog = () => {
    setCreateBatchDialog(false);
  };

  // React.useEffect(() => {
  //   setWfsBatchDetails(skuWfsDetails.wfsBatchDetails);
  // }, [skuWfsDetails]);

  //useEffect for refreshing data
  // React.useEffect(() => {
  //   if (skuWfsDetails.editBatchError === false) {
  //     dispatch(GetWfsBatchDetailsSku(batchFilter));
  //     handelCloseCreateBatchDialog();
  //     skuWfsDetails.editBatchError = true;
  //   }
  // }, [skuWfsDetails.editBatchError]);

  //useEffect for refreshing data
  React.useEffect(() => {
    setWfsBatchDetails(skuWfsDetails.wfsBatchDetails);
    if (
      skuWfsDetails.cancelBatchError === false ||
      skuWfsDetails.createBatchError === false ||
      skuWfsDetails.editBatchError === false
    ) {
      // dispatch(GetWfsBatchDetailsSku(batchFilter));
      skuWfsDetails.cancelBatchError = true;
    }
  }, [skuWfsDetails]);

  //useEffect for refreshing data
  React.useEffect(() => {
    if (adminWfsCanOrdersData.editBatchError === false) {
      dispatch(GetWfsBatchDetailsSku(batchFilter));
      adminWfsCanOrdersData.editBatchError = true;
    }
  }, [adminWfsCanOrdersData.editBatchError]);

  //useEffect for refreshing data
  // React.useEffect(() => {
  //   if (skuWfsDetails.createBatchError === false) {
  //     dispatch(GetWfsBatchDetailsSku(batchFilter));
  //     skuWfsDetails.createBatchError = true;
  //   }
  // }, [skuWfsDetails.createBatchError]);
  return (
    <Material.Box>
      <Material.Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: { xs: "column", md: "row" },
          rowGap: 2,
        }}
      >
        {loggedInUserRole !== ADMIN_ROLES.BW_WFSADMIN ? (
          <Material.Button
            onClick={() => setCreateBatchDialog(true)}
            size="small"
            variant="contained"
          >
            Create Batch
          </Material.Button>
        ) : (
          <Material.Typography></Material.Typography>
        )}

        <Material.Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "end",
            gap: "10px",
          }}
        >
          <Material.Button
            onClick={() => setOpenBatchFilter(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Material.Button>
          {/* <Material.TextField
            size="small"
            value={batchFilter.page_size}
            sx={{ width: "100px" }}
            label="Page size"
            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          /> */}
          {/* <Material.FormControl sx={{ width: "100px" }} size="small">
            <Material.InputLabel>Page Size</Material.InputLabel>
            <Material.Select
              value={batchFilter.page_size}
              label="Page Size"
              onChange={handlePageSize}
            >
              {" "}
              <Material.MenuItem value={25}>25</Material.MenuItem>
              <Material.MenuItem value={50}>50</Material.MenuItem>
              <Material.MenuItem value={100}>100</Material.MenuItem>
              <Material.MenuItem value={200}>200</Material.MenuItem>
            </Material.Select>
          </Material.FormControl> */}
          <FormControl sx={{ width: "100px" }} size="small">
            <InputLabel>Page Size</InputLabel>
            <Select
              value={batchFilter.page_size}
              label="Page Size"
              onChange={handlePageSize}
            >
              <Material.MenuItem value={2}>2</Material.MenuItem>
              <Material.MenuItem value={5}>5</Material.MenuItem>
              <Material.MenuItem value={10}>10</Material.MenuItem>
            </Select>
          </FormControl>

          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.IconButton
              size="small"
              onClick={() => {
                if (batchFilter.page_number - 1 > 0) {
                  setBatchFilter((prev) => ({
                    ...prev,
                    page_number: prev.page_number - 1,
                  }));
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{
                  color: batchFilter.page_number - 1 > 0 ? "blue" : "gray",
                }}
              />
            </Material.IconButton>
            <Material.Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {batchFilter.page_number}
            </Material.Typography>
            <Material.IconButton
              size="small"
              onClick={() => {
                if (
                  skuWfsDetails.wfsBatchDetails?.length == batchFilter.page_size
                ) {
                  setBatchFilter((prev) => ({
                    ...prev,
                    page_number: prev.page_number + 1,
                  }));
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color:
                    skuWfsDetails.wfsBatchDetails?.length >=
                    batchFilter.page_size
                      ? "blue"
                      : "gray",
                }}
              />
            </Material.IconButton>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      {wfsBatchDetails !== null &&
      wfsBatchDetails !== undefined &&
      wfsBatchDetails.length > 0 ? (
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            maxHeight: "550px",
            overflow: "auto",
            gap: 2,
          }}
        >
          {wfsBatchDetails?.map((data, key) => {
            return (
              <WfsCompletedBatchCard
                key={key}
                batch_id={data.batch_id}
                manufacturing_date={data.created_at}
                waterQuality_details_id={
                  data.water_quality_details?.water_quality_details_id ===
                  undefined
                    ? ""
                    : data.water_quality_details?.water_quality_details_id
                }
                Quality_Report={
                  data.water_quality_details?.quality_report === undefined
                    ? ""
                    : data.water_quality_details?.quality_report
                }
                quantity={data.quantity}
                batch_start_time={data.batch_start_time}
                batch_stop_time={data.batch_stop_time}
                wfs_batch_master_id={data.wfs_batch_master_id}
                container_capacity={data.container_capacity}
                container_material={data.container_material}
                linked_container_qty={data.linked_container_qty}
                is_batch_stopped={data.is_batch_stopped}
                is_batch_started={data.is_batch_started}
                exp_date={
                  data.water_quality_details?.exp_date === undefined
                    ? ""
                    : data.water_quality_details?.exp_date
                }
                mft_date={
                  data.water_quality_details?.mft_date === undefined
                    ? ""
                    : data.water_quality_details?.mft_date
                }
                ph={
                  data.water_quality_details?.ph === undefined
                    ? ""
                    : data.water_quality_details?.ph
                }
                tds={
                  data.water_quality_details?.tds === undefined
                    ? ""
                    : data.water_quality_details?.tds
                }
                handleEdit={handleCompletedBatchEdit}
                batchFilter={batchFilter}
              />
            );
          })}
        </Material.Box>
      ) : (
        <Material.Typography sx={{ fontWeight: "bold" }}>
          {" "}
          No completed batch details found for particular WFS!
        </Material.Typography>
      )}
      {editOpenDialog ? (
        <CompletedBatchEditDialog
          phoneNumberWfs={phoneNumberWfs}
          editOpenDialog={editOpenDialog}
          handleSubmit={handleSubmitEditData}
          handleClose={handleCloseDialog}
          wfsMftDate={wfsMftDate}
          wfsExpiryDate={wfsExpiryDate}
        />
      ) : (
        ""
      )}
      <WfsBatchFilterDialog
        open={openBatchFilterDialog}
        onClose={handelCloseFilterDialog}
        onSubmit={handelSubmitFilterDialog}
        batchFilter={batchFilter}
        setBatchFilter={setBatchFilter}
      />
      <WfsCreateBatchDialog
        open={createBatchDialog}
        onClose={handelCloseCreateBatchDialog}
        type="add"
      />
    </Material.Box>
  );
}

export default WfsCompletedBatchDetails;
