import useAxios from "../../Axios/useAxios";
import CustomConsole from "../../../CustomConsole";
import actionType from "./actionType";
import { toast } from "react-toastify";

export const CreateBannerTypes = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/banners/createType`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_BANNER_TYPE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UpdateBannerTypes = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/banners/updateType`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_BANNER_TYPE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetBannerTypes = (pageNumber, createdAt, isEnabled) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  CustomConsole(pageNumber, createdAt, isEnabled);
  const errorResponse = {
    error: true,
    data: [],
  };
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/banners/getTypes?page_number=${pageNumber}&created_at=${createdAt}&is_enabled=${isEnabled}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BANNER_TYPES,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_BANNER_TYPES,
        payload: errorResponse,
      });
    }
  };
};
export const GetParticularBannerType = (bannerType) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/banners/getBannerType?banner_type_id=${bannerType}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PARTICULAR_BANNER_TYPE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const CreateBanners = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  const reqObj1 = {
    banner_type: 1,
    message: "This is an offer",
    banner_header: "Do you want offer?",
    expiry_date: 1,
    status: true,
    display_order: 1,
    min_screen_width_file: "hellocheck.jpg",
    max_screen_width_file: "hellocheck.jpg",
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/banners/createBanner`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_BANNERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const UpdateBanners = (bannerId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/banners/updateBanner/${bannerId}`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_BANNERS,
          payload: response.data,
        });
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      CustomConsole(error);
    }
  };
};

export const GetAllBanners = (pageNumber, createdAt, isEnabled, bannerType) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();
  const errorResponse = {
    error: true,
    data: [],
  };
  return async (dispatch) => {
    CustomConsole(
      `/SSM/banners/getAllBanners?page_number=${pageNumber}&is_enabled=${isEnabled}&created_at=${createdAt}&banner_type=${bannerType}`
    );
    try {
      let response = await api.get(
        `/SSM/banners/getAllBanners?page_number=${pageNumber}&is_enabled=${isEnabled}&created_at=${createdAt}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ALL_BANNERS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_ALL_BANNERS,
        payload: errorResponse,
      });
    }
  };
};
export const GetParticularBannerDetails = (bannerId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/banners/getBannerDetails?banner_id=${bannerId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PARTICULAR_BANNER_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetBannerTypeList = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/banners/consumer/getBannerTypeList`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BANNERTYPE_LIST,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
