/**
 * @author Lebansty Valan
 * @date 03/05/2023
 * @description Home page form admin webapp to show the graphical representation of the datas.
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Button,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  Input,
  InputAdornment,
  Alert,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TopBarDetails from "./TopBarDetails";
import BarGraph from "../../Components/Graphs/BarGraph";
import DonutPie from "../../Components/Graphs/DonutPie";
import {
  ADMIN_ROLES,
  ORDER_GRAPH,
  graphTypes,
} from "../../Components/AppMeta/appMetaConfig";
import AdmiNewDashboardMain from "../AdminNewDashboard/AdmiNewDashboardMain";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";

import SlicePie from "../../Components/Graphs/SlicePie";
import {
  GetActiveSupplierList,
  GetGraphData,
  GetSalesDataForGraphView,
  GetSalesQuantityDataForGraphView,
  GetSalesRevenueDataForGraphView,
  GetSuppliersOrderData,
} from "../Redux/Actions/adminHomeAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card } from "@material-ui/core";
import DelivertDataHomePageCard from "../../Components/Cards/DelivertDataHomePageCard";
import OrderDataHomePageCard from "../../Components/Cards/OrderDataHomePageCard";
import {
  GetBeatPlanWfsStockDetails,
  GetPincodeOrderDetailsAction,
  GetPincodeOrdersCount,
} from "../Redux/Actions/ConsumerDeliveriesPlanningAction";
import DeliveryPlanFilterDialog from "../../Components/DialogBoxes/ConsumerDeliveryPlanDialog/DeliveryPlanFilterDialog";
import BeatPlanTable from "../../Components/Cards/OrdersToDeliverCards/BeatPlanTable";
import AllEmptyFillCansCard from "../../Components/Cards/AllEmptyFillCansCard";
import WfsStockDetailsBeatPlan from "../../Components/Cards/WfsStockDetailsBeatPlan";
import OrderTimingTable from "./OrderTimingTable";
import ScannerConnectDescriptionDialog from "../../Components/Dialog/ScannerConnectDescriptionDialog";
import RevenueData from "./RevenueData";
import ServiceAreaDeliveryData from "./ServiceAreaDeliveryData";
import { GetSupplierDeliveryAgentDetails } from "../Redux/Actions/supliersAction";
import Chart from "react-google-charts";
import { DatasetOutlined } from "@mui/icons-material";
import DateFilterDialog from "./DateFilterDialog";
import MonthSelectionFilter from "./MonthSelectionFilter";
import { GetUserProfile } from "../Redux/Actions/profileAction";

function Home(props) {
  const dispatch = useDispatch();
  const graphData = useSelector((state) => state.homePageGraph);
  const profileData = useSelector((state) => state.profile);
  const [supplierId, setSupplierId] = React.useState("");
  const [selectedSupplier, setSelectedSupplier] = React.useState(0);
  const [selectedDriver, setSelectedDriver] = React.useState(0);
  const [tabvalueHome, setTabvalueHome] = React.useState("NewDashboard");
  const [deliveryFromDate, setDeliveryFromDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [deliveryToDate, setDeliveryToDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [revenuFromDate, setRevenueFromDate] = React.useState(
    moment().add(-29, "days").format("YYYY-MM-DD")
  );
  const [revenueToDate, setRevenueToDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [revenueType, setRevenueType] = React.useState(1);
  const [salesFromDate, setSalesFromDate] = React.useState(
    moment().add(-6, "days").format("YYYY-MM-DD")
  );
  const [salesToDate, setSalesToDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [deliveryData, setDeliveryData] = React.useState({
    data: [],
    options: [],
  });
  const [supplierDeliveryTimings, setSupplierDeliveryTimings] = React.useState({
    data: [],
    options: [],
  });
  const [driverDeliveryData, setDriverDeliveryData] = React.useState({
    data: [],
    options: [],
  });
  const [driverDeliveryTimings, setDriverDeliveryTimings] = React.useState({
    data: [],
    options: [],
  });
  const [allrevenue, setAllRevenue] = React.useState({
    data: [],
    options: [],
  });

  const [monthlySalesData, setMonthlySalesData] = React.useState({
    data: [],
    options: [],
  });
  const [monthArray, setMonthArray] = React.useState([
    moment().month() - 4,
    moment().month() - 3,
    moment().month() - 2,
    moment().month() - 1,
    moment().month(),
    moment().month() + 1,
  ]);
  const [openMonthSelectionDialog, setOpenMonthSelectionDialog] =
    React.useState(false);
  const [b2cRevenue, setb2cRevenue] = React.useState({
    data: [],
    options: [],
  });
  const [openSupplierDateFilter, setOpenSupplierDateFilter] =
    React.useState(false);
  const [openRevenueDateFilter, setOpenRevenueDateFilter] =
    React.useState(false);
  const [openB2BB2CRevenueDateFilter, setOpenB2BB2CRevenueDateFilter] =
    React.useState(false);
  const [driverId, setDriverId] = React.useState("");
  const [agentId, setAgentId] = React.useState("");

  // React.useEffect(() => {
  //   if (profileData.user !== undefined && profileData.user !== null) {
  //     // setSupplierId(profileData.user);
  //     CustomConsole(profileData.user);
  //     CustomConsole(profileData.user.supplier_id);
  //     CustomConsole(profileData.user.driver_id);
  //     setSupplierId(profileData.user.supplier_id);
  //     setDriverId(profileData.user.driver_id);
  //   }
  // }, [graphData]);

  // Store UseEffect
  React.useEffect(() => {
    setAgentId(
      profileData.user !== undefined &&
        profileData.user !== null &&
        profileData.user.driver_id
    );
  }, [profileData]);

  React.useEffect(() => {
    CustomConsole(profileData, "profileDataprofileData");
    setSupplierId(
      profileData.user !== undefined &&
        profileData.user !== null &&
        profileData.user.supplier_id
    );
  }, [profileData]);
  React.useEffect(() => {
    CustomConsole("graphData: ");
    CustomConsole(graphData);
    if (!graphData.error) {
      if (
        graphData.supplierList &&
        graphData.supplierList.length > 0 &&
        selectedSupplier === 0
      ) {
        CustomConsole("------------Admin Supplier----------");
        setSelectedSupplier(graphData.supplierList[0].supplier_id);
      } else if (
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_DISTRIBUTER ||
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_SUPPLIERMANAGER
      ) {
        CustomConsole("------------Supplier----------");
        setSelectedSupplier(supplierId);
      }
      if (
        graphData.driverList &&
        graphData.driverList.length > 0 &&
        selectedDriver === 0
      ) {
        CustomConsole("------------Admin Delivery Agent----------");
        setSelectedDriver(graphData.driverList[0].driver_id);
      } else if (
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_DELIVERYAGENT ||
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_SUPPLIER_LOADERS
      ) {
        CustomConsole("------------Delivery Agent----------");
        setSelectedDriver(agentId);
      }
      if (
        graphData.supplierOrdersData &&
        graphData.supplierOrdersData.orders &&
        graphData.supplierOrdersData.replacement_orders &&
        graphData.supplierOrdersData.return_orders
      ) {
        CustomConsole("graphData.supplierOrdersData:");
        CustomConsole(graphData.supplierOrdersData);
        // const data = ;
        // data: [
        //   ["Order Status", "Regular", "Return", "Replacement"],
        //   [
        //     "Delivered",
        //     graphData.supplierOrdersData.orders.delivered_order,
        //     graphData.supplierOrdersData.return_orders.delivered_order,
        //     graphData.supplierOrdersData.replacement_orders.delivered_order,
        //   ],
        //   [
        //     "Ongoing",
        //     graphData.supplierOrdersData.orders.ongoing_order,
        //     graphData.supplierOrdersData.return_orders.ongoing_order,
        //     graphData.supplierOrdersData.replacement_orders.ongoing_order,
        //   ],
        //   [
        //     "Cancelled",
        //     graphData.supplierOrdersData.orders.cancelled_order,
        //     graphData.supplierOrdersData.return_orders.cancelled_order,
        //     graphData.supplierOrdersData.replacement_orders.cancelled_order,
        //   ],
        // ]
        // const options = ;

        setDeliveryData({
          data: [
            ["Order Type", "D", "C", "O"],

            [
              "Regular",
              parseInt(graphData.supplierOrdersData.orders.delivered_order),
              parseInt(graphData.supplierOrdersData.orders.cancelled_order),
              parseInt(graphData.supplierOrdersData.orders.ongoing_order),
            ],
            [
              "Return",
              parseInt(
                graphData.supplierOrdersData.return_orders.delivered_order
              ),
              parseInt(
                graphData.supplierOrdersData.return_orders.cancelled_order
              ),
              parseInt(
                graphData.supplierOrdersData.return_orders.ongoing_order
              ),
            ],
            [
              "Replacement",
              parseInt(
                graphData.supplierOrdersData.replacement_orders.delivered_order
              ),
              parseInt(
                graphData.supplierOrdersData.replacement_orders.cancelled_order
              ),
              parseInt(
                graphData.supplierOrdersData.replacement_orders.ongoing_order
              ),
            ],
          ],
          options: {
            chart: {
              title: "Distributor delivery details",
              subtitle: "Delivered, Ongoing, and Cancelled",
            },
          },
        });
      } else {
        setDeliveryData({ data: [], options: {} });
      }
      // supplierDeliveryTimings
      if (
        graphData.supplierOrdersData &&
        graphData.supplierOrdersData.deliveries_time &&
        graphData.supplierOrdersData.deliveries_time.length > 0
      ) {
        let data = [["Time Slot", "Quick", "On-Time", "Delayed"]];
        for (const details of graphData.supplierOrdersData.deliveries_time) {
          const tempArray = [
            details?.time_slot,
            details?.quick,
            details?.on_time,
            details?.late,
          ];
          data.push(tempArray);
        }
        setSupplierDeliveryTimings({
          data: data,
          options: {
            chart: {
              title: "Distributor delivery timings",
              subtitle: "Delivered orders",
            },
          },
        });
      } else {
        setSupplierDeliveryTimings({ data: [], options: {} });
      }
      // driverDeliveryTimings
      if (
        graphData.driverOrdersData &&
        graphData.driverOrdersData.orders &&
        graphData.driverOrdersData.replacement_orders &&
        graphData.driverOrdersData.return_orders
      ) {
        CustomConsole(graphData.driverOrdersData);
        setDriverDeliveryData({
          data: [
            ["Order Type", "D", "C", "O"],

            [
              "Regular",
              parseInt(graphData.driverOrdersData.orders.delivered_order),
              parseInt(graphData.driverOrdersData.orders.cancelled_order),
              parseInt(graphData.driverOrdersData.orders.ongoing_order),
            ],
            [
              "Return",
              parseInt(
                graphData.driverOrdersData.return_orders.delivered_order
              ),
              parseInt(
                graphData.driverOrdersData.return_orders.cancelled_order
              ),
              parseInt(graphData.driverOrdersData.return_orders.ongoing_order),
            ],
            [
              "Replacement",
              parseInt(
                graphData.driverOrdersData.replacement_orders.delivered_order
              ),
              parseInt(
                graphData.driverOrdersData.replacement_orders.cancelled_order
              ),
              parseInt(
                graphData.driverOrdersData.replacement_orders.ongoing_order
              ),
            ],
          ],
          options: {
            chart: {
              title: "Driver delivery Details",
              subtitle: "Delivered, Ongoing, and Cancelled",
            },
          },
        });
      } else {
        setDriverDeliveryData({ data: [], options: {} });
      }
      if (
        graphData.driverOrdersData &&
        graphData.driverOrdersData.deliveries_time &&
        graphData.driverOrdersData.deliveries_time.length > 0
      ) {
        let data = [["Time Slot", "Quick", "On-Time", "Delayed"]];
        for (const details of graphData.driverOrdersData.deliveries_time) {
          const tempArray = [
            details?.time_slot,
            details?.quick,
            details?.on_time,
            details?.late,
          ];
          data.push(tempArray);
        }
        setDriverDeliveryTimings({
          data: data,
          options: {
            chart: {
              title: "Driver delivery timings",
              subtitle: "Delivered orders",
            },
          },
        });
      } else {
        setDriverDeliveryTimings({ data: [], options: {} });
      }
      //PieChart Data
      if (graphData.b2b_b2c_revenue && graphData.b2b_b2c_revenue.length > 0) {
        // Show Data
        setAllRevenue({
          data: [
            ["Type", "Revenue"],
            ["B2B", graphData.b2b_b2c_revenue[0]?.b2b_revenue],
            ["B2C", graphData.b2b_b2c_revenue[0]?.b2c_revenue],
          ],
          options: {
            // title: "B2B v/s B2C Revenue",
            is3D: true,
          },
        });
      }
      // b2crevenue
      CustomConsole("graphData.b2c_quantity_revenue:");
      CustomConsole(graphData.b2c_quantity_revenue);
      if (
        graphData.b2c_quantity_revenue &&
        graphData.b2c_quantity_revenue.sales_revenue &&
        graphData.b2c_quantity_revenue.sales_revenue.length > 0
      ) {
        let data = [["Date", "Amount", "Quantity"]];
        for (const details of graphData.b2c_quantity_revenue.sales_revenue) {
          const tempArray = [
            details.date,
            parseInt(details.weekly_revenue),
            parseInt(details.weekly_sales),
          ];
          data.push(tempArray);
        }
        // Show Data
        setb2cRevenue({
          data: data,
          options: {
            chart: {
              isStacked: true,
            },
          },
        });
      } else {
        setb2cRevenue({ data: [], options: {} });
      }
      // setMonthlySalesData
      if (
        graphData.b2b_b2b_quantity &&
        graphData.b2b_b2b_quantity.b2b_b2c_sales &&
        graphData.b2b_b2b_quantity.b2b_b2c_sales.length > 0
      ) {
        let data = [["Month", "B2B", "B2C"]];
        for (const details of graphData.b2b_b2b_quantity.b2b_b2c_sales) {
          const tempArray = [
            details.month,
            parseInt(details.monthly_b2b_sales),
            parseInt(details.monthly_b2c_sales),
          ];
          data.push(tempArray);
        }
        // Show Data
        setMonthlySalesData({
          data: data,
          options: {},
        });
      } else {
        setMonthlySalesData({ data: [], options: {} });
      }
    }
  }, [graphData]);

  //Component on Mount UseEffect
  React.useEffect(() => {
    CustomConsole(
      "localStorage.getItem(roleId): " + localStorage.getItem("roleId")
    );
    if (
      parseInt(localStorage.getItem("roleId")) !== ADMIN_ROLES.BW_DISTRIBUTER &&
      parseInt(localStorage.getItem("roleId")) !==
        ADMIN_ROLES.BW_SUPPLIERMANAGER &&
      parseInt(localStorage.getItem("roleId")) !==
        ADMIN_ROLES.BW_DELIVERYAGENT &&
      parseInt(localStorage.getItem("roleId")) !==
        ADMIN_ROLES.BW_SUPPLIER_LOADERS
    ) {
      // dispatch(GetActiveSupplierList({ page_number: 0, page_size: 50 }));
      setTimeout(() => {
        handleGetRevenueData();
        setTimeout(() => {
          handleGetSalesDataForGraphView();
          setTimeout(() => {
            handleGetSalesQuantityDataForGraphView();
          }, [100]);
        }, [100]);
      }, [100]);
    } else if (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_DISTRIBUTER ||
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_SUPPLIERMANAGER
    ) {
      // dispatch(GetUserProfile());
      CustomConsole("---------DISTRIBUTER---------");
      CustomConsole(selectedSupplier);
      dispatch(
        GetSuppliersOrderData({
          params: {
            supplier_id: selectedSupplier,
            from_date: deliveryFromDate,
            to_date: deliveryToDate,
          },
          is_supplier: true,
        })
      );
    } else if (
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_DELIVERYAGENT ||
      parseInt(localStorage.getItem("roleId")) ===
        ADMIN_ROLES.BW_SUPPLIER_LOADERS
    ) {
      // dispatch(GetUserProfile());
      CustomConsole("---------DELIVERY AGENT---------");
      CustomConsole(selectedDriver);
      // dispatch(
      //   GetSuppliersOrderData({
      //     params: {
      //       delivery_agent_id: selectedDriver,
      //       from_date: deliveryFromDate,
      //       to_date: deliveryToDate,
      //     },
      //     is_supplier: false,
      //   })
      // );
    }
  }, []);

  const getSupplierDeliveryData = () => {
    if (selectedSupplier && parseInt(selectedSupplier) > 0) {
      // dispatch(
      //   GetSuppliersOrderData({
      //     params: {
      //       supplier_id: selectedSupplier,
      //       from_date: deliveryFromDate,
      //       to_date: deliveryToDate,
      //     },
      //     is_supplier: true,
      //   })
      // );
    }
  };
  const getDriverDeliveryData = () => {
    if (selectedDriver && parseInt(selectedDriver) > 0) {
      //Get the Driver's Delivery Graph Details
      // dispatch(
      //   GetSuppliersOrderData({
      //     params: {
      //       delivery_agent_id: selectedDriver,
      //       from_date: deliveryFromDate,
      //       to_date: deliveryToDate,
      //     },
      //     is_supplier: false,
      //   })
      // );
    }
  };

  React.useEffect(() => {
    //Get Delivery Agent IDs
    if (selectedSupplier && parseInt(selectedSupplier) > 0) {
      //Hit the delivery Details Graph API for Distributor
      // getSupplierDeliveryData();
      // dispatch(
      //   GetSupplierDeliveryAgentDetails({
      //     supplier_id: selectedSupplier,
      //   })
      // );
    }
  }, [selectedSupplier]);

  React.useEffect(() => {}, []);
  React.useEffect(() => {
    getDriverDeliveryData();
  }, [selectedDriver]);

  const handleGetDeliveryData = () => {
    setOpenSupplierDateFilter(false);
    getSupplierDeliveryData();
    getDriverDeliveryData();
  };
  const handleGetRevenueData = () => {
    setOpenRevenueDateFilter(false);
    // dispatch(
    //   GetSalesDataForGraphView({
    //     params: { from_date: revenuFromDate, to_date: revenueToDate },
    //     graph_type: graphTypes.B2B_B2C_REVENUE,
    //   })
    // );
  };
  const handleGetSalesDataForGraphView = () => {
    setOpenB2BB2CRevenueDateFilter(false);
    CustomConsole("revenueType");
    CustomConsole(revenueType);
    // dispatch(
    //   GetSalesRevenueDataForGraphView({
    //     params: {
    //       is_b2c: parseInt(revenueType) === 1 ? true : false,
    //       filterType: 1,
    //       from_date: salesFromDate,
    //       to_date: salesToDate,
    //       graph_type: 101,
    //     },
    //     graph_type: graphTypes.B2C_QUANTITY_REVENUE,
    //   })
    // );
  };
  const handleGetSalesQuantityDataForGraphView = () => {
    // setOpenB2BB2CRevenueDateFilter(false);
    setOpenMonthSelectionDialog(false);
    CustomConsole("monthArray");
    CustomConsole(monthArray);

    // dispatch(
    //   GetSalesQuantityDataForGraphView({
    //     params: {
    //       filterType: 2,
    //       data: monthArray,
    //       graph_type: 102,
    //     },
    //     graph_type: graphTypes.B2B_B2C_QUANTITY_REVENUE,
    //   })
    // );
  };

  const handleChangeTabValueHome = (event, newValue) => {
    setTabvalueHome(newValue);
  };

  return (
    <>
      <Box sx={{ mt: "53px" }}>
        {[
          ADMIN_ROLES.BW_ADMIN,
          ADMIN_ROLES.BW_SUPERADMIN,
          ADMIN_ROLES.BW_FINANCE,
          ADMIN_ROLES.BW_FINANCEHEAD,
          ADMIN_ROLES.BW_SALESHEAD,
          ADMIN_ROLES.BW_OPSHEAD,
          ADMIN_ROLES.BW_CSLEAD,
          ADMIN_ROLES.BW_CSHEAD,
        ].includes(parseInt(localStorage.getItem("roleId"))) ? (
          <AdmiNewDashboardMain />
        ) : (
          <Alert severity="error">Access Denied</Alert>
        )}
      </Box>
    </>
  );
}

export default Home;
