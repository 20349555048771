import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

//api--1 function for getting available picode areas
// export const AddingNewPincodes = (reqObj) => {
//   const httpStatus = {
//     SUCCESS: 200,
//     BAD_REQUEST: 400,
//   };
//   let api = useAxios();

//   return async (dispatch) => {
//     try {
//       let response = await api.post(`/SCM/admin/addPincodeDetails`, reqObj);
//       if (response.status === httpStatus.SUCCESS) {
//         dispatch({
//           type: actionType.ADDING_NEW_PINCODE,
//           payload: response.data,
//         });
//       }
//     } catch (error) {
//       toast.dark(error.response.data.msg, {
//         position: "bottom-right",
//         autoClose: 5000,
//         type: "info",
//       });
//     }
//   };
// };

//api--2 to get all the pincodes
export const SkuAvailablePincodes = (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    // Remove empty keys from params
    // const filteredReqObj = Object.fromEntries(
    //   Object.entries(params || {}).filter(([key, value]) => value !== "")
    // );
    // let response = await api.get(
    //   `/OM/operations/wfs/orders?${new URLSearchParams(filteredReqObj)}`
    // );
    try {
      let response = await api.get(`/TM/pincode/areas`, { params: params });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_AVAILABLE_PINCODES,
          payload: response.data,
        });
        return;
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "info",
      });
    }
    dispatch({
      type: actionType.GET_AVAILABLE_PINCODES,
      payload: {},
    });
  };
};

//api--2 adding new Picode and service area
export const AddPincodeSeriviceAreaSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/pincode/add`, reqObj);
      console.log("picode responce.....");

      console.log(response);
      if (response.status === httpStatus.SUCCESS && !response.data.error) {
        dispatch({
          type: actionType.ADD_PINCODE_SERVICEAREA_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === httpStatus.BAD_REQUEST) {
        toast.error(
          "Bad Request: " +
            (error.response?.data?.message ||
              error.response?.data?.msg ||
              "Unknown error"),
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } else {
        // Handle other errors
        toast.error(
          "An error occurred: " +
            (error.response?.data?.message ||
              error.response?.data?.msg ||
              "Unknown error"),
          {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
          }
        );
      }
    }
  };
};

//api--3 Edit or update pincode price
export const UpdatePincodePriceSku = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/pincode/locality/add/products`, reqObj);
      if (response.status === httpStatus.SUCCESS && !response.data.error) {
        dispatch({
          type: actionType.UPDATE_PINCODE_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//api--4 Deleting locality from pincode
export const DeleteLocalityFromPincode = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/pincode/remove/localities`, reqObj);
      if (response.status === httpStatus.SUCCESS && !response.data.error) {
        dispatch({
          type: actionType.DELETE_LOCALITY,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//api--4 Deleting product from locality
export const DeleteProductFromLocality = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/TM/pincode/locality/remove/products`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS && !response.data.error) {
        dispatch({
          type: actionType.DELETE_PRODUCT_LOCALITY,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//api--5 Updating the zone_name and zone_type for  the respective pincodes
export const UpdateZoneForPincode = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/pincode/update-zone`, reqObj);
      if (response.status === httpStatus.SUCCESS && !response.data.error) {
        dispatch({
          type: actionType.UPDATE_PINCODE_SKU,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
