/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.173
 * @Date 18-11-2024
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Chip } from "@mui/material";
import CustomTable from "../../Components/Tables/CoustomTable";
import {
  AddRawMaterials,
  RawMaterialStockDetails,
  UpdateRawMaterials,
} from "../Redux/Actions/bookWaterStockAction";
import { tabelConfig } from "../../Components/AppMeta/appTabelConfig";
import PrimaryButton from "../../Components/Buttons/primaryButton";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import CoustomAddItemsDialog from "../../Components/Custom/addItemsDialog";
import { textFieldConfig } from "../../Components/AppMeta/appTextFieldConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { PRODUCT_TYPES } from "../../Components/AppMeta/appMetaConfig";
const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
export default function BookwaterStockDetails({
  selectedoptions,
  setOpenAddOrUpdateProduct,
}) {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [fieldValidity, setFieldValidity] = useState({});
  const [FineshedGoodsData, setFineshedGoodsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  //fetch raw material data
  const fetchRawMaterialData = () => {
    dispatch(RawMaterialStockDetails()).then((data) => {
      if (data?.data) {
        setRawMaterialData(data?.data?.data);
      }
    });
  };
  //useeffectt for getting raw material and product stock
  useEffect(() => {
    if (selectedoptions === "Raw Material") {
      fetchRawMaterialData();
    } else if (selectedoptions === "Finished Goods") {
      dispatch(GetAvailableProducts()).then((data) => {
        setFineshedGoodsData(data?.data);
        if (data?.data) {
        }
      });
    }
  }, [selectedoptions]);

  console.log("rawMaterialData....");
  console.log(rawMaterialData);
  const handleOnClickAddButton = (props) => {
    console.log("props for checking");
    console.log(props);
  };

  //button componenet rawmaterial
  const RawMaterialButton = (props) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <EditIcon
          onClick={() => {
            handelOnClickEditRawMaterial(props);
          }}
        />
      </Box>
    );
  };

  //button componenet rawmaterial
  const RawMaterialSubmitButton = (props) => {

    return (
      <>
        {updateFlag && (
          <Box>
            <PrimaryButton
              btnName={props?.status ? "Deactivate" : "Activate"}
              handleButtonClick={() =>
                handelSubmitAddRawMaterial(props, "deactive")
              }
              color="red"
            />
          </Box>
        )}
        <Box>
          <PrimaryButton
            btnName="Submit"
            handleButtonClick={() => handelSubmitAddRawMaterial(props)}
            color={appColors.commonBtnColor}
          />
        </Box>
      </>
    );
  };
  //function to add raw material
  const handelAddRawMaterial = () => {
    setOpenDialog(true);
    setUpdateFlag(false);
  };

  //function to open finsed product details
  const handelAddFinsedProduct = () => {
    setOpenAddOrUpdateProduct(true);
  };

  //function to close dialog
  const handelClose = () => {
    setOpenDialog(false);
  };

  //function to handel submit button for raw material
  const handelSubmitAddRawMaterial = (props, deactive) => {
    console.log(props);
    console.log(fieldValidity);
    const error = Object.keys(fieldValidity).filter(
      (data) => fieldValidity[data] === true
    );
    console.log(error);
    console.log(Object.keys(fieldValidity));

    let reqObj = {
      name: props?.name,
      units: props?.units,
      material: props?.material,
      product_type: PRODUCT_TYPES.PRODUCT_TYPE_RAW_MATERIAL,
      other_details: {
        empty_cgst_perc: props?.empty_cgst_perc,
        empty_sgst_perc: props?.empty_sgst_perc,
        empty_hsn_code: props?.empty_hsn_code,
      },
    };
    //if update flag is false means it is add functionlity
    if (!updateFlag) {
      dispatch(AddRawMaterials(reqObj)).then((data) => {
        console.log(data);
        if (!data?.error) {
          setOpenDialog(false);
          setFormValues({});
          fetchRawMaterialData();
        }
      });
    } else {
      reqObj.id = props?.id;
      if (deactive === "deactive" && props.status) {
        reqObj.status = false;
      } else if (deactive === "deactive" && props.status) {
        reqObj.status = true;
      }
      dispatch(UpdateRawMaterials(reqObj)).then((data) => {
        console.log(data);
        if (!data?.error) {
          setOpenDialog(false);
          setFormValues({});
          fetchRawMaterialData();
        }
      });
    }
  };

  //function to handel on click edit raw material
  const handelOnClickEditRawMaterial = (params) => {
    const { name, units, material, other_details, id, status } = params?.row;
    console.log(params);
    setFormValues({
      name: name,
      units: units,
      material: material,
      empty_cgst_perc: other_details?.empty_cgst_perc,
      empty_sgst_perc: other_details?.empty_sgst_perc,
      empty_hsn_code: other_details?.empty_hsn_code,
      id: id,
      status: status,
    });
    setUpdateFlag(true);
    setOpenDialog(true);
  };

  //dummy function
  const tableRowClickFunction = () => {};

  //order status function
  const OrderStatusFunction = (data) => {
    console.log(data);
    const { label, color } = getStatusDetails(data?.row?.status);
    return (
      <Box>
        <Chip label={label} color={color} variant="outlined" />
      </Box>
    );
  };

  // Function to get color and label based on status
  const getStatusDetails = (status) => {
    console.log(status);
    switch (status) {
      case true:
        return { label: "Active", color: "info" };
      case false:
        return { label: "Deactive", color: "warning" };
      default:
        return { label: "In Progress", color: "info" }; // Water Blue
    }
  };
  return (
    <>
      <Box sx={{ width: "100%", overflow: "hidden", boxShadow: 2 }}>
        {selectedoptions === "Raw Material" && (
          <CustomTable
            tableHead={tabelConfig.rawMaterialTabel.headerData}
            tableData={rawMaterialData}
            tableDataKey={[
              ...tabelConfig.rawMaterialTabel.tabelDataKey,
              {
                component: (props) => {
                  return OrderStatusFunction(props);
                },
              },
              {
                component: (props) => {
                  return RawMaterialButton(props);
                },
              },
            ]}
            addButton={true}
            addButtonName="+ Raw material"
            handleOnClickAddButton={handelAddRawMaterial}
            pagination="100"
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            pageSize={pageSize}
            tableRowClickFunction={tableRowClickFunction}
          />
        )}
        {openDialog && (
          <CoustomAddItemsDialog
            openDialog={openDialog}
            handelClose={handelClose}
            dialogTittle="Add/Update Raw Materials"
            fieldsConfig={textFieldConfig.addRawMaterial}
            submitButton={RawMaterialSubmitButton}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldValidity={fieldValidity}
            setFieldValidity={setFieldValidity}
          />
        )}
      </Box>
    </>
  );
}
