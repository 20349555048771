import { Close, Phone } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import bgImage from "../../../Components/Assests/Images/bw_bg.svg";
import moment from "moment";
import CustomTable from "../../../Components/Tables/CoustomTable";
import { tabelConfig } from "../../../Components/AppMeta/appTabelConfig";
import CoustomTextField from "../../../Components/TextFiled/coustomTextField";
import {
  appRegex,
  GIFTCARD_STATUS,
} from "../../../Components/AppMeta/appMetaConfig";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import CoustomAddItemsDialog from "../../../Components/Custom/addItemsDialog";
import { textFieldConfig } from "../../../Components/AppMeta/appTextFieldConfig";
import PrimaryButton from "../../../Components/Buttons/primaryButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { appColors } from "../../../Components/Assests/AppTheme/appThemeConst";
import { useDispatch } from "react-redux";
import {
  GetListOfGiftCardDetails,
  GetPhysicalGiftCards,
  PhysicalGiftCardChangeStatus,
  PhysicalGiftCardEditDetails,
  SettlePhysicalGiftCard,
} from "../../Redux/Actions/AdminSettingsAction";
import RenderGiftCards from "./RenderGiftCard";
import useProductImages from "../../../Components/Custom/useProductImages";
import { ButtonGroup } from "@mui/material";
import { CSVLink } from "react-csv";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import SelectedGiftCardDialog from "../../../Components/Dialog/physicalGiftCardView";
import ConformationDialog from "../../../Components/Dialog/conformationDialog";
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });
export default function GiftCardBatchDetails({
  open,
  handleClose,
  giftCardSetting,
  setSelectedGiftCard,
  giftCardId,
}) {
  const { images, isLoading, error } = useProductImages(
    giftCardSetting?.physical_giftcard_template,
    "GiftCardImages"
  );
  const [details, setBatchDetails] = useState();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [updateAssignee, setUpdateAssignee] = useState(false);
  const [updateName, setUpdateName] = useState(false);
  const [updatePrice, setUpdatePrice] = useState(false);
  const [redeemThrough, setRedeemThrough] = useState(false);
  const [editDetails, setEditDetails] = useState({
    phone: "",
    name: "",
    price: "",
    redeem: "",
  });
  const [isValid, setIsValid] = useState();
  const dispatch = useDispatch();
  const [toggleValue, setToggleValue] = useState("Transactions");
  const [openSettlementDialog, setOpenSettlementDialog] = useState(false);
  const [newSettlementValues, setNewSettlementValues] = useState({});
  const [fieldValidity, setFieldValidity] = useState({});
  const [updateBatchStatus, setUpdateBatchStatus] = useState();
  const [renderData, setRenderData] = useState([]);
  const [downLoadData, setDownLoadData] = useState([]);
  const [status, setStatus] = useState();
  const [giftCardDetails, setGiftCardDetails] = useState({});
  const [giftCardDetailsOpen, setGiftCardDetailsOpen] = useState(false);
  const buttons = [
    { label: "Printing", value: GIFTCARD_STATUS.PRINTING },
    { label: "Active", value: GIFTCARD_STATUS.ACTIVE },
    { label: "Deactive", value: GIFTCARD_STATUS.DEACTIVE },
  ];
  const [confirmDialog, setConfirmDialog] = useState(false);
  const completeButtons = [
    { label: "Payment Close", value: GIFTCARD_STATUS.PAYMENTCLOSE },
    { label: "Complete", value: GIFTCARD_STATUS.COMPLETED },
  ];
  const handelOpenData = () => {
    console.log(giftCardSetting);
    let data = details?.details?.linkedGiftCards?.map((card) => ({
      amount: details?.details.amount_perGiftCard,
      id: card.giftCardId,
      branch: details?.details.batchName,
      password: card.password,
    }));
    setRenderData(data);
    setOpenDownloadModal(true);
  };
  const onSubmitSettlement = () => {
    const payload = {
      ...newSettlementValues,
      id: details.id,
      batchName: details?.details?.batchName,
    };
    dispatch(SettlePhysicalGiftCard(payload)).then((data) => {
      if (!data?.error) {
        setOpenSettlementDialog(false);
      }
      getGiftcards();
    });
  };
  const getGiftcards = () => {
    let payload = {
      id: giftCardId.id,
      batchName: giftCardId?.details?.batchName,
    };
    dispatch(GetPhysicalGiftCards(payload)).then((data) => {
      if (!data.error) {
        setBatchDetails(data?.data?.[0]);
      }
      // setGiftCards(data?.data);
    });
  };
  useEffect(() => {
    if (giftCardId?.id) {
      getGiftcards();
    }
  }, [giftCardId]);

  const onUpdateBatchStatus = (status) => {
    const payload = {
      id: details?.id,
      batchName: details?.details?.batchName,
      changeTo: status,
    };
    dispatch(PhysicalGiftCardChangeStatus(payload)).then((data) => {
      if (!data.error) {
        getGiftcards();
        setUpdateBatchStatus();
      }
    });
  };

  const onUpdateBatchAssigne = (type) => {
    if (type === "phone" && details?.details?.assignedTo?.length > 0) {
      return setConfirmDialog(true);
    }
    const payload = {
      id: details?.id,
    };
    if (type === "phone") {
      payload.assignedTo = editDetails?.phone;
    } else if (type === "name") {
      payload.batchName = editDetails?.name;
    } else if (type === "price") {
      payload.amount_perGiftCard = editDetails?.price;
    } else if (type === "redeem") {
      payload.redeemThrough = editDetails?.redeem;
    }
    dispatch(PhysicalGiftCardEditDetails(payload)).then((data) => {
      if (!data.error) {
        getGiftcards();
        setUpdateBatchStatus();
        setEditDetails({
          phone: "",
          name: "",
          price: "",
          redeem: "",
        });
        setUpdateAssignee(false);
        setUpdateName(false);
        setUpdatePrice(false);
        setRedeemThrough(false);
      }
    });
  };

  const GiftCardSubmitBtn = (props) => {
    return (
      <Box>
        <PrimaryButton
          btnName="Submit"
          handleButtonClick={() => {
            // submitCreateGc(props);
            onSubmitSettlement();
            // handelSubmitManufacture(props)
          }}
          color={appColors.commonBtnColor}
        />
      </Box>
    );
  };

  //function to change status
  const handelChangeStatus = (status) => {
    onUpdateBatchStatus(status);
  };

  const EditActionButtom = (props) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <PrimaryButton
          handleButtonClick={() => {
            handelViewButton(props);
          }}
          disabled={!details?.details?.isGiftCardCreated}
          btnName="View"
          color={appColors.blueColor}
        />{" "}
      </Box>
    );
  };

  //function to handel view button
  const handelViewButton = (props) => {
    const reqObj = {
      giftCardId: props?.row?.giftCardId || undefined,
    };
    dispatch(GetListOfGiftCardDetails(reqObj)).then((data) => {
      if (!data?.error) {
        setGiftCardDetails(data?.data[0]?.details);
        setGiftCardDetailsOpen(true);
      }
    });
  };
  //function to download csv
  const csvLink = {
    filename: "GiftCards",
    data: downLoadData,
  };

  //function to handel download dtails
  function toCSV() {
    setDownLoadData(details?.details?.linkedGiftCards);
  }

  //function to change button color based on selection
  const getButtonColor = (buttonStatus) => {
    if (details?.details?.status === buttonStatus) return "success";
    return "primary";
  };

  //function to show ui for coustom table
  const coustomUi = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box
          sx={[
            giftCardUi.resultTypo,
            { alignItems: "center", display: "flex", gap: 1 },
          ]}
          onClick={() => {
            handelOpenData();
          }}
        >
          <CloudDownloadIcon /> <div>DownLoad PDF</div>
        </Box>
        <Box>
          <CSVLink {...csvLink}>
            <SimCardDownloadRoundedIcon onClick={() => toCSV()} />
          </CSVLink>
        </Box>
      </Box>
    );
  };

  //common text field for edit data
  const renderEditField = (key, label, value, regex, type = "text") => (
    <Box sx={{ backgroundColor: "white", display: "flex", gap: 2 }}>
      <CoustomTextField
        label={label}
        name={label}
        type={type}
        value={value}
        validationRegex={regex}
        isRequired={true}
        handleChange={(fieldName, value, isValid) => {
          setEditDetails((prev) => ({ ...prev, [key]: value }));
          setIsValid(isValid);
        }}
        setIsValid={setIsValid}
        isHintRequired={true}
      />
      {isValid && (
        <PrimaryButton
          handleButtonClick={() => onUpdateBatchAssigne(key)}
          btnName="Save"
          color={appColors.blueColor}
        />
      )}
      <CloseIcon onClick={() => resetEditState(key)} />
    </Box>
  );

  //edit state to clear textfild data
  const resetEditState = (key) => {
    switch (key) {
      case "name":
        setUpdateName(false);
        break;
      case "price":
        setUpdatePrice(false);
        break;
      case "phone":
        setUpdateAssignee(false);
        break;
      case "redeem":
        setRedeemThrough(false);
        break;
      default:
        break;
    }
  };

  //function to change other assginee
  const handelChangeAssigne = () => {
    const payload = {
      id: details?.id,
      assignedTo: editDetails?.phone,
      isForceChangeAssignee: true,
    };
    dispatch(PhysicalGiftCardEditDetails(payload)).then((data) => {
      if (!data.error) {
        getGiftcards();
        setUpdateBatchStatus();
        setEditDetails({
          phone: "",
          name: "",
          price: "",
          redeem: "",
        });
        setUpdateAssignee(false);
        setConfirmDialog(false);
      }
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#1b185c" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
            GiftCard Batch Details
          </Typography>
          <Button
            variant="contained"
            autoFocus
            size="small"
            color="inherit"
            sx={{ color: "red" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContentText
        sx={{
          // backgroundImage: `url(${bgImage})`,
          height: "100vH",
          width: "100vW",
          alignItems: "center",
          padding: "15px",
          gap: "20px",
        }}
      >
        <Box sx={{ color: "#808080" }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {details?.details?.status !== GIFTCARD_STATUS.PAYMENTCLOSE &&
            details?.details?.status !== GIFTCARD_STATUS.COMPLETED ? (
              <div style={giftCardUi.resultTypo}>
                <ButtonGroup variant="contained" aria-label="Gift Card Status">
                  Status :{" "}
                  {buttons.map((button) => (
                    <Button
                      key={button.value}
                      onClick={() => handelChangeStatus(button.value)}
                      color={getButtonColor(button.value)}
                      size="small"
                      disabled={
                        status !== GIFTCARD_STATUS.PRINTING &&
                        button.value === GIFTCARD_STATUS.PRINTING
                      }
                    >
                      {button.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            ) : (
              ""
            )}
            {details?.details?.status !== GIFTCARD_STATUS.DEACTIVE && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Complete :</Box>
                <div style={giftCardUi.resultTypo}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="Gift Card Status"
                  >
                    {completeButtons.map((button) => (
                      <Button
                        key={button.value}
                        onClick={() => handelChangeStatus(button.value)}
                        color={getButtonColor(button.value)}
                        size="small"
                      >
                        {button.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ marginTop: ".5rem" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Batch Name :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {details?.details?.batchName}
                    {!updateName &&
                    details?.details?.status === GIFTCARD_STATUS.PRINTING ? (
                      <EditIcon onClick={() => setUpdateName(true)} />
                    ) : (
                      ""
                    )}

                    {updateName &&
                    details?.details?.status === GIFTCARD_STATUS.PRINTING
                      ? renderEditField(
                          "name",
                          "Batch Name",
                          editDetails?.name,
                          appRegex.nameRegexLength3
                        )
                      : ""}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Price Per Card :</Box>

                <Box sx={giftCardUi.resultTypo}>
                  ₹ {details?.details?.amount_perGiftCard}
                  {!updatePrice &&
                  details?.details?.status === GIFTCARD_STATUS.PRINTING ? (
                    <EditIcon onClick={() => setUpdatePrice(true)} />
                  ) : (
                    ""
                  )}
                  {updatePrice &&
                  details?.details?.status === GIFTCARD_STATUS.PRINTING
                    ? renderEditField(
                        "price",
                        "Price",
                        editDetails?.price,
                        appRegex.nameRegexLength1
                      )
                    : ""}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Total Cards :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {" "}
                    {details?.details?.numberOfGiftCards}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Currently Assigned To :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {details?.details?.currentAssignee?.name} ,
                    {details?.details?.currentAssignee?.phone}
                    {(!updateAssignee &&
                      details?.details?.status === GIFTCARD_STATUS.ACTIVE) ||
                    (details?.details?.status === GIFTCARD_STATUS.PRINTING &&
                      !updateAssignee) ? (
                      <EditIcon onClick={() => setUpdateAssignee(true)} />
                    ) : (
                      ""
                    )}
                    {(updateAssignee &&
                      details?.details?.status === GIFTCARD_STATUS.ACTIVE) ||
                    (details?.details?.status === GIFTCARD_STATUS.PRINTING &&
                      updateAssignee)
                      ? renderEditField(
                          "phone",
                          "Phone",
                          editDetails?.phone,
                          appRegex.phoneRegEx
                        )
                      : ""}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Redeem Through :</Box>
                <Box sx={giftCardUi.resultTypo}>
                  {details?.details?.redeemThrough}
                  <Box>
                    {!redeemThrough &&
                    details?.details?.status === GIFTCARD_STATUS.PRINTING ? (
                      <EditIcon onClick={() => setRedeemThrough(true)} />
                    ) : (
                      ""
                    )}
                    {redeemThrough &&
                    details?.details?.status === GIFTCARD_STATUS.PRINTING ? (
                      <>
                        <FormControl>
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            Redeem Through
                          </FormLabel>
                          <RadioGroup
                            row
                            value={editDetails.redeem}
                            onChange={(event) => {
                              setEditDetails((prev) => ({
                                ...prev,
                                redeem: event.target.value,
                              }));
                            }}
                          >
                            <FormControlLabel
                              value="WALLET"
                              control={<Radio />}
                              label="Wallet"
                            />
                            <FormControlLabel
                              value="WATER_DROPS"
                              control={<Radio />}
                              label="Waterdrops"
                            />
                          </RadioGroup>
                        </FormControl>
                        {editDetails?.redeem !== "" ? (
                          <PrimaryButton
                            handleButtonClick={() =>
                              onUpdateBatchAssigne("redeem")
                            }
                            btnName="Save"
                            color={appColors.blueColor}
                          />
                        ) : (
                          <></>
                        )}
                        <CloseIcon onClick={() => setRedeemThrough(false)} />
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: ".5rem" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Password Required :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {details?.details?.isPasswordRequired ? "Yes" : "No"}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Total Sold :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {details?.details?.sold || 0}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Total Remaining :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {details?.details?.remaining || 0}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Amount Collected :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    ₹ {details?.details?.collectedAmount || 0}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={giftCardUi.headingTypo}>Assigned On :</Box>
                <Box>
                  <Box sx={giftCardUi.resultTypo}>
                    {moment(
                      details?.details?.currentAssignee?.Assigned_date
                    ).format("YYYY-MMM-DD : hh:mm a")}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            width: "100%",
            display: "flex",
            marginTop: ".5rem",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={toggleValue}
              onChange={(event, newValue) => setToggleValue(newValue)}
              aria-label="Type"
              exclusive
              backgroundColor="white"
              size="small"
            >
              <ToggleButton value="Transactions">Transactions</ToggleButton>
              <ToggleButton value="Assignees">Assignees</ToggleButton>
              <ToggleButton value="Giftcards">Gift Cards</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {toggleValue == "Transactions" &&
          details?.details?.status === GIFTCARD_STATUS.ACTIVE ? (
            <Button
              onClick={() => setOpenSettlementDialog(true)}
              sx={{ float: "left" }}
              variant="contained"
              size="small"
            >
              Settle
            </Button>
          ) : (
            <></>
          )}
        </Box>
        {toggleValue == "Transactions" ? (
          <CustomTable
            pagination={false}
            tableHead={
              tabelConfig.PhysicalGiftCardBatchTransactionsTable.headerData
            }
            tableData={details?.details?.paymentTransaction}
            tableDataKey={[
              ...tabelConfig.PhysicalGiftCardBatchTransactionsTable
                .tabelDataKey,
            ]}
            pageNumber={0}
            pageSize={0}
            tableRowClickFunction={() => {}}
          />
        ) : (
          ""
        )}

        {toggleValue == "Assignees" ? (
          <CustomTable
            pagination={false}
            tableHead={
              tabelConfig.PhysicalGiftCardBatchAssigneedTable.headerData
            }
            tableData={details?.details?.assignedTo}
            tableDataKey={[
              ...tabelConfig.PhysicalGiftCardBatchAssigneedTable.tabelDataKey,
            ]}
            pageNumber={0}
            pageSize={0}
            tableRowClickFunction={() => {}}
          />
        ) : (
          ""
        )}

        {toggleValue == "Giftcards" ? (
          <CustomTable
            pagination={false}
            tableHead={tabelConfig.physicalGiftCards.headerData}
            tableData={details?.details?.linkedGiftCards}
            tableDataKey={[
              ...tabelConfig.physicalGiftCards.tabelDataKey,
              {
                component: (props) => {
                  return EditActionButtom(props);
                },
              },
            ]}
            pageNumber={0}
            pageSize={0}
            tableRowClickFunction={() => {}}
            coustomUi={coustomUi}
          />
        ) : (
          ""
        )}
      </DialogContentText>
      <CoustomAddItemsDialog
        openDialog={openSettlementDialog}
        handelClose={() => setOpenSettlementDialog(false)}
        dialogTittle="Create Settlement"
        fieldsConfig={textFieldConfig.createGiftCardSettlement}
        submitButton={GiftCardSubmitBtn}
        formValues={newSettlementValues}
        setFormValues={setNewSettlementValues}
        fieldValidity={fieldValidity}
        setFieldValidity={setFieldValidity}
      />
      <DownloadGiftCardsModal
        handleClose={() => setOpenDownloadModal(false)}
        open={openDownloadModal}
        giftCardSetting={giftCardSetting}
        renderData={renderData}
      />
      <SelectedGiftCardDialog
        openDialog={giftCardDetailsOpen}
        setOpenDialog={setGiftCardDetailsOpen}
        giftCardDetails={giftCardDetails}
      />
      <ConformationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        dialogMsg="Are you sure to change Assignee"
        handelProceed={handelChangeAssigne}
      />
    </Dialog>
  );
}

function DownloadGiftCardsModal({
  open,
  handleClose,
  giftCardSetting,
  renderData,
}) {
  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#1b185c" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            Download Giftcard
          </Typography>
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            sx={{ color: "red" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      {open ? (
        <Grid container sx={{ width: "100%", m: 2 }}>
          {giftCardSetting?.physical_giftcard_template?.map((template) => (
            <Grid xs={12} sm={6} md={4} item>
              <Box>
                <RenderGiftCards
                  templateFileName={template}
                  renderData={renderData}
                  page={true}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </Dialog>
  );
}

const giftCardUi = {
  headingTypo: {
    color: appColors.commonTextColor,
    fontWeight: "500",
  },
  resultTypo: {
    color: appColors.blueColor,
    fontWeight: "500",
  },
};
