/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 18-01-2024
 */
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function NewProductDetailsCard(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  //function for handling pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //function for handling rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  //function for handling edit product details
  const handleClickEdit = (data, crate) => {
    props.handleClickEdit(data, "", crate);
  };

  //function for handling Open product crate details
  const handleOpenProductCrate = (data) => {
    props.handleOpenProductCrate(data);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: 5 }}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={props.productAvailableDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={appColors.commonLayoutColor}>
                <TableCell />
                <TableCell size="small" align="start">
                  <strong>S.no</strong>
                </TableCell>
                <TableCell size="small" align="start">
                  <strong>Name</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Capacity</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Material</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Life Span</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Order Type</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Image</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Componenets</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Edit</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.productAvailableDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((data, index) => (
                  <>
                    <TableRow
                      sx={{ "& > *": { borderBottom: "unset" } }}
                      key={index}
                    >
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handelIcon(index)}
                        >
                          {openFlag ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell size="small" align="start">
                        {index + 1}
                      </TableCell>
                      <TableCell size="small" align="start">
                        {data.product_details.name}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.product_details.capacity}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.product_details.material}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.product_details.life_span}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {props.orderTypes.map((details, index) => {
                          if (
                            details.value == data.product_details.order_type
                          ) {
                            return <span key={index}>{details.label}</span>;
                          }
                          return null; // Return null for non-matching elements
                        })}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.product_details.image !== "" ? (
                          <DownloadIcon
                            onClick={() => {
                              dispatch(getFiles(data.product_details.image));
                            }}
                          />
                        ) : (
                          <FileDownloadOffIcon />
                        )}
                      </TableCell>
                      <TableCell size="small" align="center">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <Button
                            sx={{ height: "25px" }}
                            variant="contained"
                            disabled={
                              !data?.product_details?.components?.length
                            }
                            onClick={() =>
                              props.handelClickViewComponents(
                                data.product_details
                              )
                            }
                            size="small"
                          >
                            View
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell size="small" align="center">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <Button
                            sx={{ height: "25px" }}
                            variant="contained"
                            onClick={() =>
                              handleClickEdit(
                                data.product_details,
                                data.crate_details
                              )
                            }
                            size="small"
                          >
                            Edit
                          </Button>
                          <Button
                            sx={{ height: "25px" }}
                            disabled={data.crate_details[0].capacity === null}
                            variant="contained"
                            onClick={() => handleOpenProductCrate(data)}
                            size="small"
                          >
                            Crates
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                      >
                        {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                        {open === index && openFlag === true ? (
                          <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow sx={appColors.commonLayoutColor}>
                                  <TableCell size="small">
                                    <strong>SKU Qty</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>Max Book Qty</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>Min Booking Qty</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>HSN</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>CGST%</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>SGST%</strong>
                                  </TableCell>
                                  <TableCell size="small">
                                    <strong>IGST%</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={data.product_details.id}>
                                  <TableCell size="small">
                                    {data.product_details.sku_qty[0]}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.product_details.max_book_qty}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.product_details.min_book_qty}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.product_details.hsn_code}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.product_details.sgst_perc}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.product_details.cgst_perc}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data.product_details.igst_perc}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        ) : (
                          ""
                        )}
                        {/* </Collapse> */}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <Material.Paper
        sx={{
          minWidth: { xs: "290px" },
          marginTop: "30px",
          borderRadius: "10px",
        }}
        elevation={20}
      >
        <Material.Grid
          container
          spacing={2}
          sx={{ padding: "1rem" }}
          justifyContent="space-between"
        >
          <Material.Grid
            container
            direction="data"
            justifyContent="center"
            alignItems="center"
            md={2}
            xs={12}
          >
            {" "}
            <img
              src={props.data.product_details.image}
              alt="icon"
              style={{
                height: "90px",
                width: "80px",
                marginTop: "1px",
              }}
            />
          </Material.Grid>
          <Material.Grid md={5} xs={12}>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Product Id :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.id}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Product Name :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.name}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Amount :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.amount}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                SKU Qty :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.sku_qty}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Capacity :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.capacity}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Material :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.material}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Hsn Code :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.hsn_code}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Delivery Charges :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.delivery_charges}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Updated At :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.updated_at}
              </Material.Typography>
            </Material.Box>
          </Material.Grid>
          <Material.Grid md={5} xs={12} sx={{ paddingLeft: { xs: "20px" } }}>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Life Span :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.life_span}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Order Type :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.order_type}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Max Book Qty :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.max_book_qty}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Min Book Qty :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.min_book_qty}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Cgst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.cgst_percentage}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Igst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.igst_percentage}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Sgst Percentage :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.sgst_percentage}
              </Material.Typography>
            </Material.Box>
            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Created At :
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                {props.data.product_details.created_at}
              </Material.Typography>
            </Material.Box>

            <Material.Box sx={DetailsStyles.contentBox}>
              <Material.Typography
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Description
              </Material.Typography>
              <Material.Typography sx={{ fontSize: "14px" }}>
                : {props.data.product_details.description}
              </Material.Typography>
            </Material.Box>
          </Material.Grid>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Material.Button
              sx={{ margin: "2px" }}
              variant="contained"
              onClick={() => handleClickEdit(props.data.product_details)}
              size="small"
            >
              Edit
            </Material.Button>
            <Material.Button
              sx={{ margin: "2px" }}
              variant="contained"
              onClick={handleOpenProductCrate}
              size="small"
            >
              Crate Details
            </Material.Button>
          </Material.Box>
        </Material.Grid>
      </Material.Paper> */}
    </>
  );
}

export default NewProductDetailsCard;
