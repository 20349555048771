import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import userLogo from "../../Components/Assests/Images/userLogo.png";
import {
  AirlineSeatReclineExtra,
  BrowserUpdated,
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
  LocalShipping,
  SyncRounded,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import FileUpload from "@mui/icons-material/FileUpload";
import { InputLabel, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import {
  DeleteUser,
  DeliverySlotsEnableDisableSwitchAction,
  DisableUserAdmin,
  EnableUserAdmin,
  GetProfileDetails,
  PutCansRollBack,
  TansferDistributorCanCreateOrder,
  UpdateUserDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { useDispatch } from "react-redux";
import SupplierDetailsCard from "../AdminUsers/SupplierDetailsCard";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import DistributorViewDetails from "../AdminUsers/DistributorViewDetails";
import DistributorAssignedAreaCard from "../AdminUsers/DistributorAssignedAreaCard";
import SupplierDriverDetailsDialog from "./SupplierDriverDetailsDialog";
import DeleteUserDialog from "../../Components/Dialog/DeleteUserDialog";
import AddReduceReturnDialog from "../AdminUsers/AddReduceReturnDialog";
import TransfoerCansCreateOrderDialog from "../../Components/Dialog/TransfoerCansCreateOrderDialog";
import DistributorOrderDialog from "../../Components/Dialog/distributorWfsOrderDialog";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import WareHouseDetailsDialog from "./WareHouseDetailsDialog";
import VehiclesDetailsDialog from "./VehiclesDetailsDialog";

function Supplier(props) {
  CustomConsole(props.updateUserDetails);
  const dispatch = useDispatch();
  // const [isSwitchOn, setIsSwitchOn] = React.useState(true);
  const [changeInSwitch, setChangeInSwitch] = React.useState(false);
  const [openVehiclesDialog, setOpenVehiclesDialog] = React.useState(false);
  const [openWarehouseDialog, setOpenWarehouseDialog] = React.useState(false);
  const [isrevertbackenabled, setIsrevertbackenabled] = React.useState(false);
  const [addReduceReturn, setAddReduceReturn] = React.useState(false);
  const [stockUpdates, setStockUpdates] = React.useState("");
  const [openDialogCreateOrder, setOpenDialogCreateOrder] =
    React.useState(false);
  const [customPrice, setCustomPrice] = React.useState("");
  const [twntyLtrDiscount, setTwntyLtrDiscount] = React.useState(0);
  const [accountNumber, setAccountNumber] = React.useState("");
  const [singleSelectedAccountData, setSingleSelectedAccountData] =
    React.useState({});
  const [openDriversDetailsDialog, setOpenDriversDetailsDialog] =
    React.useState(false);
  // Add Address
  const [addressnamePhone, setAddressnamePhone] = React.useState("");
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [showSupplierDetails, setShowSupplierDetails] = React.useState(false);
  const [distributorType, setDistributorType] = React.useState("");
  const [updateUserBtn, setUpdateUserBtn] = React.useState(false);
  const [singleSelectedAddressData, setSingleSelectedAddressData] =
    React.useState({});
  const [alignmentStock, setAlignmentStock] = React.useState("Regular");
  const [stockCapacity, setStockCapacity] = React.useState("");
  const [deleteUserDialog, setDeleteUserDialog] = React.useState(false);
  const [singleSelectedStockData, setSingleSelectedStockData] = React.useState(
    {}
  );
  const [openPlaceOrder, setOpenPlaceOrder] = React.useState(false);

  //function for closing place order dialog
  const handelPlaceOrderClose = () => {
    setOpenPlaceOrder(false);
  };
  const Input = styled("input")({
    display: "none",
  });

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
    dispatch(GetAvailableProducts());
  }, []);

  React.useEffect(() => {
    CustomConsole(props.bookingDetails);
    if (
      props.bookingDetails.error === false &&
      props.bookingDetails.setupConfigData.length > 0
    ) {
      props.bookingDetails.setupConfigData.map((data) => {
        if (data.key === "comapny_types") {
          setCompanyTypes(data.value);
        }
      });
    }
  }, [props.bookingDetails]);
  React.useEffect(() => {
    if (changeInSwitch === true && props.isSwitchOn === true) {
      toast.success(props.updateUserDetails.enableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
    if (changeInSwitch === true && props.isSwitchOn === false) {
      toast.success(props.updateUserDetails.disableUserMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setChangeInSwitch(false);
    }
  }, [props.updateUserDetails]);

  React.useEffect(() => {
    //
    if (
      props.updateUserDetails?.userStockData &&
      props.updateUserDetails.userStockData.length > 0 &&
      stockCapacity.length <= 0
    ) {
      setStockCapacity(
        () =>
          props.updateUserDetails.userStockData[0].container_capacity +
          "-" +
          props.updateUserDetails.userStockData[0].container_material
      );
    }
  }, [props.updateUserDetails?.userStockData]);
  React.useEffect(() => {
    if (
      props.updateUserDetails?.accounts &&
      props.updateUserDetails?.accounts.length > 0
    ) {
      setAccountNumber(props.updateUserDetails?.accounts[0].account_id);
    }
  }, [props.updateUserDetails?.accounts]);
  React.useEffect(() => {
    if (
      props.updateUserDetails?.address &&
      props.updateUserDetails?.address.length > 0
    ) {
      setAddressnamePhone(props.updateUserDetails?.address[0].address_id);
    }
  }, [props]);

  React.useEffect(() => {
    if (props.updateUserDetails?.userStockData && stockCapacity) {
      const output = props.updateUserDetails?.userStockData?.find((obj) => {
        return (
          obj.container_capacity + "-" + obj.container_material ===
          stockCapacity
        );
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        setSingleSelectedStockData(output);
      } else {
        setSingleSelectedStockData({});
      }
    }
  }, [props.updateUserDetails?.userStockData, stockCapacity]);

  React.useEffect(() => {
    if (props.updateUserDetails?.address && addressnamePhone) {
      const output = props.updateUserDetails?.address?.find((obj) => {
        return obj.address_id === addressnamePhone;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        setSingleSelectedAddressData(output);
      } else {
        setSingleSelectedAddressData({});
      }
    }
  }, [props.updateUserDetails?.address, addressnamePhone]);

  React.useEffect(() => {
    if (props.updateUserDetails?.accounts && accountNumber) {
      const output = props.updateUserDetails?.accounts?.find((obj) => {
        return obj.account_id === accountNumber;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      // setSelectedStock(output);
      if (output) {
        setSingleSelectedAccountData(output);
      } else {
        setSingleSelectedAccountData({});
      }
    }
  }, [props.updateUserDetails?.userStockData, accountNumber]);

  React.useEffect(() => {
    CustomConsole("--------updateUserDetails---------");
    CustomConsole(props.updateUserDetails);
    // CustomConsole(updateUserDetails.userProfileData.length);
    // CustomConsole(updateUserDetails.userProfileData.consumer);
    if (props.updateUserDetails.error === false) {
      //   setIsrevertbackenabled(props.updateUserDetails.is_revert_back_enabled);
      // CustomConsole(updateUserDetails.userProfileData.length);
      // CustomConsole(Object.keys(updateUserDetails.userProfileData).length);
      if (
        props.updateUserDetails.userProfileData !== undefined &&
        props.updateUserDetails.userProfileData !== null &&
        Object.keys(props.updateUserDetails.userProfileData).length > 0
      ) {
        CustomConsole("--------updateUserDetails---------");
        CustomConsole(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        CustomConsole(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        setTwntyLtrDiscount(
          props.updateUserDetails.userProfileData.user.twenty_ltr_discount
        );
        setCustomPrice(
          props.updateUserDetails.userProfileData.user.custom_twentyltr_price
        );
        CustomConsole("--------custom_twentyltr_price---------");
      }
      setUpdateUserBtn(false);
      // setUpdateFlagCheck(false);
    }
    if (props.updateUserDetails.error === false && updateUserBtn === true) {
      CustomConsole("Profile");

      CustomConsole("Profile");
      dispatch(GetProfileDetails(props.userId));
      setUpdateUserBtn(false);
    }
    // setTwntyLtrDiscount("");
    // setCustomPrice("");
  }, [props.updateUserDetails]);
  React.useEffect(() => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var result = reg.test(props.email);
    var emailResult = false;
    if (props.roleId === 3000) {
      let regEmail = /^[^@]+@bookwater\.com$/;
      emailResult = regEmail.test(props.email);
    }
    if (updateUserBtn === true) {
      CustomConsole(parseInt(twntyLtrDiscount) < parseInt(customPrice));
      if (
        props.phone.length === 10 &&
        result === true &&
        props.fullname.trim() !== "" &&
        emailResult === false
      ) {
        dispatch(
          UpdateUserDetails(
            props.fullname,
            props.phone,
            props.email,
            props.roleId,
            twntyLtrDiscount || 0,
            props.userId,
            distributorType || 2,
            customPrice
          )
        );
      } else {
        toast.error("Please update valid details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUpdateUserBtn(false);
      }

      setUpdateUserBtn(false);
    }
  }, [updateUserBtn]);
  React.useEffect(() => {
    if (props.updateUserDetails.error === false) {
      setIsrevertbackenabled(props.updateUserDetails.is_revert_back_enabled);
    }
  }, [props.updateUserDetails]);
  const handleChangeSwitch = (event) => {
    setChangeInSwitch(true);
    if (event.target.checked) {
      props.setIsSwitchOn(true);
      dispatch(EnableUserAdmin(props.userId));
    } else if (event.target.checked === false) {
      props.setIsSwitchOn(false);
      dispatch(DisableUserAdmin(props.userId));
    }
  };
  const handleCloseDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const handleDeleteUserDialog = () => {
    dispatch(DeleteUser(props.userId));
    props.setIsUserDetailsOpen(false);
    setDeleteUserDialog(false);
  };
  const handleDeleteUser = () => {
    // dispatch(DeleteUser(userId));

    setDeleteUserDialog(true);
  };
  const handleUpdateUserDetails = () => {
    setUpdateUserBtn(true);
    setTimeout(() => {
      dispatch(GetProfileDetails(props.userId));
    }, 1000);
  };
  const handleChangeStockRegulatToReturn = (event, newAlignment) => {
    setAlignmentStock(newAlignment);
  };
  const handleClickRollaback = (event) => {
    CustomConsole("------handleClickRollaback------");
    const reqobj = {
      user_id: props.userId,
      is_revert_back_enabled: event.target.checked,
    };
    setIsrevertbackenabled(() => event.target.checked);
    CustomConsole(reqobj);
    dispatch(PutCansRollBack(reqobj));
  };
  const handleChangeACcount = (event) => {
    // const selectedAccountData = props.updateUserDetails?.accounts.find(
    //   (data) => data.account_id === event.target.value
    // );
    // CustomConsole(selectedAccountData);
    // if (selectedAccountData) {
    //   setSingleSelectedAccountData(selectedAccountData);
    // }
    setAccountNumber(event.target.value);
  };
  const handleChangeAddressNamePhone = (event) => {
    // const selectedAddresssData = props.updateUserDetails?.address.find(
    //   (data) => data.address_id === event.target.value
    // );
    // CustomConsole(selectedAddresssData);
    // if (selectedAddresssData) {
    //   setSingleSelectedAddressData(selectedAddresssData);
    // }
    setAddressnamePhone(event.target.value);
  };
  const handleSwitchDeliverySlots = (event) => {
    CustomConsole("------handleSwitchDeliverySlots------");
    const reqObj = {
      user_id: props.userId,
      delivery_slots_status: true,
    };
    if (event.target.checked) {
      props.setDeliverySlotStatus(true);
      reqObj.delivery_slots_status = true;
    } else if (event.target.checked === false) {
      props.setDeliverySlotStatus(false);
      reqObj.delivery_slots_status = false;
    }
    CustomConsole(reqObj);
    dispatch(DeliverySlotsEnableDisableSwitchAction(reqObj));
  };
  const handleChangeStock = (event) => {
    // const selectedStockData = props.updateUserDetails?.userStockData.find(
    //   (data) => data.container_capacity === event.target.value
    // );
    // CustomConsole(selectedStockData);
    // if (selectedStockData) {
    //   setSingleSelectedStockData(selectedStockData);
    // }
    setStockCapacity(event.target.value);
  };
  const handleOpenDriverDetails = () => {
    CustomConsole("-------------handleOpenDriverDetails-------------");
    setOpenDriversDetailsDialog(true);
  };
  const handleCloseDriverDetails = () => {
    setOpenDriversDetailsDialog(false);
  };
  const handleAddStock = () => {
    setAddReduceReturn(true);
    setStockUpdates("Add Stock");
  };
  const handleReturnStock = () => {
    setAddReduceReturn(true);
    setStockUpdates("Return Stock");
  };
  const handleCreateOrder = (props) => {
    CustomConsole("---------------handleCreateOrder----------------");
    if (
      props.container_capacity !== "" &&
      props.container_material !== "" &&
      props.total_container_qty !== ""
    ) {
      const reqObj = {
        container_capacity: props.container_capacity,
        container_material: props.container_material,
        total_container_qty: parseInt(props.total_container_qty),
        user_id: props.userId,
      };
      CustomConsole(reqObj);
      dispatch(TansferDistributorCanCreateOrder(reqObj));
      props.setTotalContainerQty("");
      setOpenDialogCreateOrder(false);
    } else {
      toast.error("Please fill all Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleCloseDialog = () => {
    CustomConsole("---------------handleCloseDialog----------------");
    setOpenDialogCreateOrder(false);
  };
  const handleTransferCansCreateOrderOpenDialog = () => {
    CustomConsole(
      "---------------handleTransferCansCreateOrderOpenDialog-------------------"
    );
    setOpenDialogCreateOrder(true);
  };

  //function for handling sku order for supplier
  const handelBookingSupplier = (data) => {
    if (Object.keys(singleSelectedAddressData).length > 0) {
      setOpenPlaceOrder(true);
    } else {
      toast.error("Please Select Warehouse address to Proceed Booking", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  //Ware house Details
  const handleWareHouseDetails = () => {
    setOpenWarehouseDialog(true);
  };
  //
  const handleCloseWareHouseDialog = () => {
    setOpenWarehouseDialog(false);
  };
  const handleVehiclesDetails = () => {
    setOpenVehiclesDialog(true);
  };

  const handleCloseVehicleDialog = () => {
    setOpenVehiclesDialog(false);
  };
  return (
    <>
      <Material.Box>
        <Material.Grid container spacing={1}>
          <Material.Grid item md={3.5} lg={3.5} xs={12}>
            <Material.Box>
              <Material.Box sx={{ m: 1 }}>
                <Material.Paper
                  sx={{ borderRadius: "10px", padding: "10px" }}
                  elevation={3}
                >
                  <Material.Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src={userLogo}
                      alt=""
                    />
                  </Material.Box>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Material.TextField
                      label="FullName"
                      size="small"
                      value={props.fullname}
                      onChange={(event) =>
                        props.setFullname(event.target.value)
                      }
                      sx={{ width: "100%" }}
                    />
                    <Material.TextField
                      label="Phone"
                      size="small"
                      value={props.phone}
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={(event) => props.setPhone(event.target.value)}
                      sx={{ width: "100%" }}
                    />
                    <Material.TextField
                      label="Email"
                      size="small"
                      value={props.email}
                      onChange={(event) => props.setEmail(event.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </Material.Box>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Material.Box
                      sx={{
                        border: "2px solid black",
                        // padding:"5px",
                        // pl:"20px",
                        // width: "250px",
                        // m: 1,
                        // ml: { xs: 3.4, md: 1 },
                      }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              // fontSize: "18px",
                              // fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            User's Status
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          {/* {parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_ADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_SUPERADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSHEAD ? ( */}
                          <Material.FormControlLabel
                            control={
                              <Material.Switch checked={props.isSwitchOn} />
                            }
                            label={
                              props.isSwitchOn ? (
                                <Material.Typography
                                  sx={{
                                    color: "black",
                                  }}
                                >
                                  Active
                                </Material.Typography>
                              ) : (
                                <Material.Typography
                                  sx={{
                                    color: "black",
                                  }}
                                >
                                  InActive
                                </Material.Typography>
                              )
                            }
                            onChange={handleChangeSwitch}
                          />
                          {/* // ) : (
                    //   ""
                    // )} */}
                        </Material.Box>
                      </Material.Box>
                    </Material.Box>
                    <Material.Box
                      sx={{
                        border: "2px solid black",
                        // padding:"5px",
                        // pl:"20px",
                        // width: "250px",
                        // m: 1,
                        // ml: { xs: 3.4, md: 1 },
                      }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              // fontSize: "18px",
                              // fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            RollBack Cans
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          {/* {parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_ADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_SUPERADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSHEAD ? ( */}
                          <Material.FormControlLabel
                            control={
                              <Material.Switch checked={isrevertbackenabled} />
                            }
                            label={
                              isrevertbackenabled ? (
                                <Material.Typography
                                  sx={{
                                    color: "black",
                                  }}
                                >
                                  Active
                                </Material.Typography>
                              ) : (
                                <Material.Typography
                                  sx={{
                                    color: "black",
                                  }}
                                >
                                  In-Active
                                </Material.Typography>
                              )
                            }
                            onChange={handleClickRollaback}
                          />
                          {/* // ) : (
                    //   ""
                    // )} */}
                        </Material.Box>
                      </Material.Box>
                    </Material.Box>
                    <Material.Box
                      sx={{
                        border: "2px solid black",
                        // padding:"5px",
                        // pl:"20px",
                        // width: "250px",
                        // m: 1,
                        // ml: { xs: 3.4, md: 1 },
                      }}
                    >
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <Material.Box>
                          <Material.Typography
                            sx={{
                              // fontSize: "18px",
                              // fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Delivery Slot's
                          </Material.Typography>
                        </Material.Box>
                        <Material.Box>
                          {/* {parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_ADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_SUPERADMIN ||
                    parseInt(localStorage.getItem("roleId")) ===
                      ADMIN_ROLES.BW_CSHEAD ? ( */}
                          <Material.FormControlLabel
                            // sx={{ marginLeft: "40px" }}
                            control={
                              <Material.Switch
                                checked={props.deliverySlotStatus}
                              />
                            }
                            label={
                              props.deliverySlotStatus ? (
                                <Material.Typography
                                  sx={{
                                    color: "black",
                                  }}
                                >
                                  Active
                                </Material.Typography>
                              ) : (
                                <Material.Typography
                                  sx={{
                                    color: "black",
                                  }}
                                >
                                  In-Active
                                </Material.Typography>
                              )
                            }
                            onChange={handleSwitchDeliverySlots}
                          />
                          {/* // ) : (
                    //   ""
                    // )} */}
                        </Material.Box>
                      </Material.Box>
                    </Material.Box>
                  </Material.Box>
                  <Material.Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Material.Button
                      variant="contained"
                      size="small"
                      color="success"
                      // sx={{
                      //   background: appColors.commonBtnColor.background,
                      // }}
                      onClick={handleUpdateUserDetails}
                    >
                      <BrowserUpdated />
                      Update
                    </Material.Button>
                    <Material.Button
                      variant="contained"
                      size="small"
                      color="error"
                      // sx={{
                      //   background: appColors.commonBtnColor.background,
                      // }}
                      onClick={handleDeleteUser}
                    >
                      <DeleteOutlined />
                      Delete
                    </Material.Button>
                  </Material.Box>
                  <Material.Box sx={{ m: 1 }}>
                    <Material.Button
                      variant="contained"
                      onClick={handleOpenDriverDetails}
                    >
                      <AirlineSeatReclineExtra /> Driver Details
                    </Material.Button>
                  </Material.Box>

                  <Material.Box sx={{ m: 1 }}>
                    <Material.Button
                      variant="contained"
                      onClick={handleWareHouseDetails}
                    >
                      WareHouse Details
                    </Material.Button>
                  </Material.Box>
                  <Material.Box sx={{ m: 1 }}>
                    <Material.Button
                      variant="contained"
                      onClick={handleVehiclesDetails}
                    >
                      <LocalShipping /> Vehicles
                    </Material.Button>
                  </Material.Box>
                </Material.Paper>
              </Material.Box>
            </Material.Box>
          </Material.Grid>
          <Material.Grid item md={8.5} lg={8.5} xs={12}>
            <Material.Box>
              <Material.Box sx={{ m: 1 }}>
                <Material.Paper sx={{ borderRadius: "10px" }} elevation={3}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { md: "space-between", xs: "center" },
                      gap: "10px",
                    }}
                  >
                    <Material.Box sx={{ mt: 1.5, ml: 1 }}>
                      {/* <Material.Button
                        variant="contained"
                        size="small"
                        // sx={{
                        //   background: appColors.commonBtnColor.background,
                        // }}
                        // onClick={handleReturnStock}
                      >
                        Add Stock
                      </Material.Button> */}
                      <Material.Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        <Material.Stack
                          direction="row"
                          // spacing={1}

                          sx={{ maxHeight: "30px" }}
                        >
                          <Material.ToggleButtonGroup
                            value={alignmentStock}
                            exclusive
                            size="sm"
                            color="primary"
                            onChange={handleChangeStockRegulatToReturn}
                            // sx={{ bgcolor: "#73AFEB" }}
                            // className={classes.reducedWidth}
                          >
                            <Material.ToggleButton value="Regular">
                              REGULAR
                            </Material.ToggleButton>
                            <Material.ToggleButton value="Return">
                              Return
                            </Material.ToggleButton>
                          </Material.ToggleButtonGroup>
                        </Material.Stack>
                      </Material.Box>
                    </Material.Box>
                    <Material.Box sx={{ mt: 2 }}>
                      <Material.Typography sx={{ fontWeight: "bold" }}>
                        STOCK DETAILS
                      </Material.Typography>
                    </Material.Box>

                    <Material.Box>
                      <Material.FormControl
                        sx={{ m: 1, minWidth: 220 }}
                        size="small"
                      >
                        <Material.InputLabel>
                          Select Capacity/Material
                        </Material.InputLabel>
                        <Material.Select
                          value={stockCapacity}
                          label="Select Capacity/Material"
                          onChange={handleChangeStock}
                        >
                          {/* {alignmentStock === "Regular" ? (
                            <> */}
                          {props.updateUserDetails?.userStockData.map(
                            (data) => {
                              return (
                                <Material.MenuItem
                                  value={
                                    data.container_capacity +
                                    "-" +
                                    data.container_material
                                  }
                                >
                                  {data.container_material}/
                                  {data.container_capacity}
                                </Material.MenuItem>
                              );
                            }
                          )}
                          {/* </>
                          ) : (
                            ""
                          )} */}
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                  </Material.Box>
                  {alignmentStock === "Regular" ? (
                    <Material.Grid
                      container
                      spacing={0.5}
                      sx={{
                        paddingLeft: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Container Material
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.container_material}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Container Capacity
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.container_capacity}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Deposit Amount
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.current_deposit_amount}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Deposit Containers
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.total_deposited_containers_qty}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Undeposited Containers
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {
                          singleSelectedStockData.total_undeposited_containers_qty
                        }
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Filled containers
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.filled_container_qty}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Containers Left
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.new_containers_to_provide}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Empty Containers
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.empty_container_qty}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Physical damaged Qty
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {singleSelectedStockData.physical_damaged_container_qty}
                      </Material.Grid>
                      <Material.Grid item md={3} sm={12} xs={6}>
                        <Material.Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "blue",
                          }}
                        >
                          Qr/Barcode Damaged Qty
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid
                        item
                        md={3}
                        sm={12}
                        xs={6}
                        sx={{ textAlign: "left", fontSize: "14px" }}
                      >
                        {
                          singleSelectedStockData.qr_barcode_damaged_container_qty
                        }
                      </Material.Grid>
                      <Material.Grid item md={12} sm={12} xs={12}>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            // padding: "40px",
                          }}
                        >
                          <Material.Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <Material.Button
                              size="small"
                              variant="contained"
                              onClick={handleAddStock}
                              sx={{
                                bgcolor: "green",
                              }}
                            >
                              Add stock
                            </Material.Button>

                            <Material.Button
                              size="small"
                              variant="contained"
                              onClick={handleReturnStock}
                              // sx={{
                              //   background: appColors.commonBtnColor.background,
                              // }}
                            >
                              Return stock
                            </Material.Button>
                          </Material.Box>
                          <Material.Box>
                            {ADMIN_ROLES.BW_SUPERADMIN === props.adminRole ||
                            ADMIN_ROLES.BW_ADMIN === props.adminRole ||
                            ADMIN_ROLES.BW_CSHEAD === props.adminRole ||
                            ADMIN_ROLES.BW_CSLEAD === props.adminRole ? (
                              <Material.Button
                                size="small"
                                variant="contained"
                                onClick={
                                  handleTransferCansCreateOrderOpenDialog
                                }
                                // sx={{
                                //   background:
                                //     appColors.commonBtnColor.background,
                                // }}
                              >
                                Transfer Cans Create Order
                              </Material.Button>
                            ) : (
                              ""
                            )}
                          </Material.Box>
                        </Material.Box>
                      </Material.Grid>
                    </Material.Grid>
                  ) : (
                    <Material.Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "20px",
                      }}
                    >
                      <Material.Grid
                        container
                        sx={{ paddingLeft: "10px", paddingBottom: "10px" }}
                        spacing={0.5}
                      >
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Container Material
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.container_material}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Container Capacity
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.container_capacity}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Physically Damaged
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.physically_damage}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            QR/Bar Code Damage
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.qr_bar_code_damage}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Valid Containers
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.valid_containers}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Filled Can Qty.
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.filled_cans_qty}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Water Expired Can
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {
                            props.updateUserDetails.distStock
                              .water_expired_can_qty
                          }
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Replace Can Qty.
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {props.updateUserDetails.distStock.replace_can_qty}
                        </Material.Grid>
                        <Material.Grid item md={3} sm={12} xs={6}>
                          <Material.Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "blue",
                            }}
                          >
                            Return Can Submitting
                          </Material.Typography>
                        </Material.Grid>
                        <Material.Grid
                          item
                          md={3}
                          sm={12}
                          xs={6}
                          sx={{ textAlign: "left", fontSize: "14px" }}
                        >
                          {
                            props.updateUserDetails.distStock
                              .return_containers_submitting
                          }
                        </Material.Grid>
                      </Material.Grid>
                    </Material.Box>
                  )}
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ m: 1 }}>
                <Material.Paper sx={{ borderRadius: "10px" }} elevation={3}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { md: "flex-end", xs: "center" },
                      gap: { md: "60px", xs: "10px" },
                    }}
                  >
                    <Material.Box sx={{ mt: 2 }}>
                      <Material.Typography sx={{ fontWeight: "bold" }}>
                        ACCOUNT DETAILS
                      </Material.Typography>
                    </Material.Box>

                    <Material.Box>
                      <Material.FormControl
                        sx={{ m: 1, minWidth: 220 }}
                        size="small"
                      >
                        <Material.InputLabel>
                          Select A/c Number
                        </Material.InputLabel>
                        <Material.Select
                          value={accountNumber}
                          label="Select A/c Number"
                          onChange={handleChangeACcount}
                        >
                          {props.updateUserDetails?.accounts.map((data) => {
                            return (
                              <Material.MenuItem value={data.account_id}>
                                {data.account_number}/{data.bank_name}
                              </Material.MenuItem>
                            );
                          })}
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                  </Material.Box>
                  <Material.Grid
                    container
                    spacing={0.5}
                    sx={{ paddingLeft: "10px", paddingBottom: "10px" }}
                  >
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Bank Name
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.bank_name}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Account Number
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.account_number}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        IFSC Code
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.ifsc_code}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Branch Name
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAccountData.branch_name}
                    </Material.Grid>
                  </Material.Grid>
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ m: 1 }}>
                {" "}
                <Material.Paper sx={{ borderRadius: "10px" }} elevation={3}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { md: "flex-end", xs: "center" },
                      gap: { md: "50px", xs: "10px" },
                    }}
                  >
                    <Material.Box sx={{ mt: 2 }}>
                      <Material.Typography sx={{ fontWeight: "bold" }}>
                        WARE HOUSE ADDRESS
                      </Material.Typography>
                    </Material.Box>

                    <Material.Box>
                      <Material.FormControl
                        sx={{ m: 1, minWidth: 220 }}
                        size="small"
                      >
                        <Material.InputLabel>
                          Select Name/Phone
                        </Material.InputLabel>
                        <Material.Select
                          value={addressnamePhone}
                          label="Select Name/Phone"
                          onChange={handleChangeAddressNamePhone}
                        >
                          {props.updateUserDetails.address.map((data) => {
                            return (
                              <Material.MenuItem value={data.address_id}>
                                {data.contact_name}/{data.contact_phone}
                              </Material.MenuItem>
                            );
                          })}
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                  </Material.Box>
                  <Material.Grid
                    container
                    spacing={0.5}
                    sx={{ paddingLeft: "10px", paddingBottom: "10px" }}
                  >
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Contact Name
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.contact_name
                        ? singleSelectedAddressData.contact_name
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Contact Phone
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.contact_phone
                        ? singleSelectedAddressData.contact_phone
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Custom Price
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.custom_price
                        ? singleSelectedAddressData.custom_price
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Discount
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.discount
                        ? singleSelectedAddressData.discount
                        : 0}
                    </Material.Grid>
                    {/* <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Cans Available
                      </Material.Typography>
                    </Material.Grid> */}
                    {/* <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.existing_cans
                        ? singleSelectedAddressData.existing_cans
                        : 0}
                    </Material.Grid> */}
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Address Line 1
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.address_line1
                        ? singleSelectedAddressData.address_line1
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Address Line 2
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.address_line2
                        ? singleSelectedAddressData.address_line2
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Address Line 3
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.address_line3
                        ? singleSelectedAddressData.address_line3
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Service Area
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.service_area
                        ? singleSelectedAddressData.service_area
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Pincode
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.pincode
                        ? singleSelectedAddressData.pincode
                        : 0}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        City
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.city
                        ? singleSelectedAddressData.city
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={3} sm={12} xs={6}>
                      <Material.Typography
                        sx={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "blue",
                        }}
                      >
                        Country
                      </Material.Typography>
                    </Material.Grid>
                    <Material.Grid
                      item
                      md={3}
                      sm={12}
                      xs={6}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {singleSelectedAddressData.country
                        ? singleSelectedAddressData.country
                        : "--"}
                    </Material.Grid>
                    <Material.Grid item md={6} sm={12} xs={12}>
                      <Material.Box
                        sx={{
                          display: "flex",
                          justifyContent: { md: "flex-end", xs: "center" },
                        }}
                      >
                        <Material.Button
                          variant="contained"
                          onClick={handelBookingSupplier}
                        >
                          Place Order
                        </Material.Button>
                      </Material.Box>
                    </Material.Grid>
                  </Material.Grid>
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ m: 1 }}>
                {" "}
                <Material.Paper
                  sx={{ borderRadius: "10px", padding: "10px" }}
                  elevation={3}
                >
                  <Material.Box sx={{ m: 2 }}>
                    <Material.Typography sx={{ fontWeight: "bold" }}>
                      PROFILE DETAILS
                    </Material.Typography>
                  </Material.Box>
                  <SupplierDetailsCard
                    supplierData={props.supplierData}
                    userRoleId={props.userRoleId}
                    setShowSupplierDetails={setShowSupplierDetails}
                    showSupplierDetails={showSupplierDetails}
                    userId={props.userId}
                    companyTypes={companyTypes}
                  />
                </Material.Paper>
              </Material.Box>
              <Material.Box sx={{ m: 1 }}>
                <Material.Paper sx={{ borderRadius: "10px" }} elevation={3}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      // justifyContent: "space-around",
                      justifyContent: "center",
                    }}
                  >
                    <Material.Box>
                      {ADMIN_ROLES.BW_SUPERADMIN === props.adminRole ||
                      ADMIN_ROLES.BW_ADMIN === props.adminRole ||
                      ADMIN_ROLES.BW_CSHEAD === props.adminRole ||
                      ADMIN_ROLES.BW_SALESHEAD === props.adminRole ? (
                        <DistributorViewDetails
                          // scrollDown={scrollDownToDetailsCard}
                          distriNum={props.phone}
                          adminRole={props.adminRole}
                          // setAdminRoleId={setAdminRoleId}
                        />
                      ) : null}
                    </Material.Box>
                    <Material.Box sx={{width:"100%"}}>
                      {ADMIN_ROLES.BW_SUPERADMIN === props.adminRole ||
                      ADMIN_ROLES.BW_ADMIN === props.adminRole ||
                      ADMIN_ROLES.BW_CSHEAD === props.adminRole ||
                      ADMIN_ROLES.BW_CS === props.adminRole ||
                      ADMIN_ROLES.BW_SALESHEAD === props.adminRole ||
                      ADMIN_ROLES.BW_OPSHEAD === props.adminRole ? (
                        <DistributorAssignedAreaCard
                          distriNum={props.phone}
                          pwdPincodeAreas={props.pwdPincodeAreas}
                        />
                      ) : (
                        <Material.Box>
                          <Material.Alert severity="error">
                            Access denied
                          </Material.Alert>
                        </Material.Box>
                      )}
                    </Material.Box>
                  </Material.Box>
                </Material.Paper>
              </Material.Box>
            </Material.Box>
          </Material.Grid>
        </Material.Grid>
      </Material.Box>
      <DistributorOrderDialog
        open={openPlaceOrder}
        onClose={handelPlaceOrderClose}
        userId={props.userId}
        address={singleSelectedAddressData.address_id}
      />
      {openDriversDetailsDialog && (
        <SupplierDriverDetailsDialog
          supplierIntegerId={props.supplierId}
          openDriversDetailsDialog={openDriversDetailsDialog}
          supplierId={props.supplierId}
          handleCloseDriverDetails={handleCloseDriverDetails}
        />
      )}
      {deleteUserDialog && (
        <DeleteUserDialog
          deleteUserDialog={deleteUserDialog}
          handleDelete={handleDeleteUserDialog}
          handleClose={handleCloseDeleteUserDialog}
        />
      )}
      {addReduceReturn ? (
        <>
          <AddReduceReturnDialog
            addReduceReturn={addReduceReturn}
            setAddReduceReturn={setAddReduceReturn}
            userId={props.userId}
            stockUpdates={stockUpdates}
            address={props.updateUserDetails.address}
            roleId={props.roleId}
          />
        </>
      ) : (
        ""
      )}
      <TransfoerCansCreateOrderDialog
        openDialogCreateOrder={openDialogCreateOrder}
        handleCreateOrder={handleCreateOrder}
        handleCloseDialog={handleCloseDialog}
        userId={props.userId}
      />
      <WareHouseDetailsDialog
        openWarehouseDialog={openWarehouseDialog}
        handleCloseWareHouseDialog={handleCloseWareHouseDialog}
        suplierId={props.userId}
        phone={props.phone}
        fullName={props.fullname}
        supplierIntegerId={props.supplierId}
      />
      <VehiclesDetailsDialog
        openVehiclesDialog={openVehiclesDialog}
        handleCloseDialog={handleCloseVehicleDialog}
        supplier_number={props.phone}
      />
    </>
  );
}

export default Supplier;
