/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * @date
 * @version
 */
import actionType from "./actionType";
import useAxios from "../../Axios/useAxios";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

//function to get raw material stock details
export const RawMaterialStockDetails = (params = {}) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const filteredReqObj = Object.fromEntries(
    Object.entries(params || {}).filter(([key, value]) => value !== "")
  );
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/products/raw_materials?${new URLSearchParams(filteredReqObj)}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to get raw material stock details
export const AddRawMaterials = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/products/raw_materials`, object);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error.response.data.msg || error.response.data.message, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to Edit raw material stock details
export const UpdateRawMaterials = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/products/raw_materials`, object);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error.response.data.msg || error.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to add  manufacturer details
export const AddManufacturerDetails = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/products/manufacturer`, object);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error.response.data.msg || error.response.data.message, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to get raw material stock details
export const GetManufacturerDetails = (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      //  params = new URLSearchParams({ key1: 'value1', key2: 'value2' }).toString();
      let response = await api.get(`/SSM/products/manufacturer`, { params });
      if (response.status === httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to validate material stock details for order
export const ValidateManufactureOrder = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/validate_order`, object);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg || response.data.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response.data.msg || response.data.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error.response.data.msg || error.response.data.message, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to place manufacture order
export const PlaceManufactureOrder = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/order`, object);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg || response.data.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response.data.msg || response.data.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error.response.data.msg || error.response.data.message, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to place manufacture order
export const UpdateManufactureOrder = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/mft/order`, object);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg || response.data.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response.data.msg || response.data.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error.response.data.msg || error.response.data.message, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to place manufacture order
export const DownLoadDraftManufacturerPo = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/generate_po_draft`, object, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          // Calculate and use the progress
          const totalLength = progressEvent.total;
          const loaded = progressEvent.loaded;
          const progress = Math.round((loaded / totalLength) * 100);
          dispatch({
            payload: { error: false },
            type: actionType.INVOICE_GENERATION,
            progress: progress,
          });
        },
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INVOICE_GENERATION,
          payload: response.data,
        });
        CustomConsole("--------Response From the Api------");
        CustomConsole(response);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        if (response.data.length <= 0) {
          CustomConsole("no available toast");
        }
        toast.dark("Invoice Generated!", {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      return error.response;
    }
  };
};

export const GetMftOrderPoData = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/generate_po`, object);
      if (response?.status === httpStatus.SUCCESS) {
        toast.dark(response?.data?.msg || response?.data?.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response?.data?.msg || response?.data?.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return null;
      }
    } catch (error) {
      return null;
    }
  };
};
export const GetMftOrderPoDraftData = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/generate_po_data`, object);
      if (response?.status === httpStatus.SUCCESS) {
        toast.dark(response?.data?.msg || response?.data?.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response?.data?.msg || response?.data?.message, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return null;
      }
    } catch (error) {
      return null;
    }
  };
};

//function to place manufacture order
export const DownLoadtManufacturerPo = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/generate_po`, object, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          // Calculate and use the progress
          const totalLength = progressEvent.total;
          const loaded = progressEvent.loaded;
          const progress = Math.round((loaded / totalLength) * 100);
          dispatch({
            payload: { error: false },
            type: actionType.INVOICE_GENERATION,
            progress: progress,
          });
        },
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INVOICE_GENERATION,
          payload: response.data,
        });
        CustomConsole("--------Response From the Api------");
        CustomConsole(response);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        if (response.data.length <= 0) {
          CustomConsole("no available toast");
        }
        toast.dark("Invoice Generated!", {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to place manufacture order
export const ManufactureOrdersList = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/mft/orders`, {
        params: object,
      });
      if (response.status === httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to upload po for order
export const UploadManufactureOrderPo = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/mft/order/upload_po`, object);
      if (response.status === httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to Approve po for order
export const ApprovePoOrder = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/mft/order/approve_reject_po`, object);
      if (response.status === httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to Approve po for order
export const CompleteManufactureOrder = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/mft/complete_order`, object);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};

//function to upload po for order
export const UploadManufactureOrderPR = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/mft/order/upload_pr`, object);
      if (response.status === httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };
};

//function to get raw material stock details
export const EditManufacture = (object) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/products/manufacturer`, object);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response;
      } else {
        console.log(response);
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response;
      }
    } catch (error) {
      toast.dark(error?.response?.data?.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error.response;
    }
  };
};
