import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Box, Grow, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSupplierDetailsList,
  WareHouseDetailsList,
} from "../Redux/Actions/supplierVehiclesActions";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";
import { Warehouse } from "@mui/icons-material";
import {
  GetSupplierDeliveryAgentDetailsAdmin,
  MarkDamageCanCallback,
} from "../Redux/Actions/supliersAction";
import { GetWfsDetailsSku } from "../Redux/Actions/skuWfsDetailsAction";
import MarkDamage from "./Components/MarkDamage";
import { GetCompleteQrDetails } from "../Redux/Actions/adminQrBarcodeAction";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

const OPS_ALLOWED_ROLES = [
  ADMIN_ROLES.BW_ADMIN,
  ADMIN_ROLES.BW_SUPERADMIN,
  ADMIN_ROLES.BW_OPSHEAD,
  ADMIN_ROLES.BW_OPS,
  ADMIN_ROLES.BW_CS,
  ADMIN_ROLES.BW_CSHEAD,
  ADMIN_ROLES.BW_CSLEAD,
];

const MarkProductStatus = () => {
  const is_admin = OPS_ALLOWED_ROLES.includes(
    parseInt(localStorage.getItem("roleId"))
  );
  const user_role = parseInt(localStorage.getItem("roleId"));
  const dispatch = useDispatch();
  const suppliers = useSelector(
    (state) => state.supplierDeliveries?.supplierList
  );
  const wareHouses = useSelector((state) => state.userDetailsUpdate?.addresses);

  const employees = useSelector(
    (state) => state.supplierDeliveries.getSupplierDriverDataAdmin
  );
  const adminQrBarcodeData = useSelector(
    (state) => state.adminQrBarcodeData.qrDetails
  );

  const wfsList = useSelector((state) => state.skuWfsDetails?.wfsDetails);
  const products = useSelector(
    (state) => state.newProductContainerDetails.getAvailableProducts
  );
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [filteredWarehouses, setFilteredWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedWfs, setSelectedWfs] = useState();
  function GetSupplierDependancy() {
    if (selectedSupplier) {
      dispatch(
        WareHouseDetailsList({
          user_id: selectedSupplier?.user_id,
        })
      );
      console.log(selectedSupplier);
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: selectedSupplier?.supplier_id,
          //   employee_type: employeeType.DRIVER,
        })
      );
    }
  }
  const getSuppliers = () => {
    dispatch(GetSupplierDetailsList());
  };
  useEffect(() => {
    const suppliersList = suppliers?.filter(
      (supplier) => supplier.supplier_name !== null
    );
    setFilteredSuppliers(suppliersList || []);
  }, [suppliers]);

  useEffect(() => {
    setFilteredWarehouses(wareHouses || []);
    setFilteredEmployees(employees || []);
  }, [wareHouses, employees]);
  useEffect(() => {
    GetSupplierDependancy();
  }, [selectedSupplier]);
  const getWfsList = () => {
    const wfsFilters = {
      page_number: 1,
      page_size: 200,
    };

    dispatch(GetWfsDetailsSku(wfsFilters));
  };

  const handleSubmit = ({ products }) => {
    dispatch(
      MarkDamageCanCallback({
        products: products,
        supplier_id: selectedSupplier?.supplier_id,
        driver_id: selectedEmployee?.driver_id,
        address_id: selectedWarehouse?.address_id,
        wfs_id: selectedWfs?.wfs_master_id,
      })
    );
  };
  useEffect(() => {
    let availableProducts = [];
    products.map((item) => {
      availableProducts?.push({
        capacity: item.product_details?.capacity,
        material: item.product_details?.material,
        is_crate: false,
        is_qr: item.product_details?.is_qr,
        name: item.product_details?.name,
      });
    });
    setFilteredProducts(availableProducts);
  }, [products]);
  useEffect(() => {
    if (is_admin) {
      getWfsList();
      getSuppliers();
    }
    console.log(user_role);
    if (user_role == ADMIN_ROLES.BW_DISTRIBUTER) {
      dispatch(
        WareHouseDetailsList({
          user_id: selectedSupplier?.user_id,
        })
      );
      console.log(selectedSupplier);
      dispatch(
        GetSupplierDeliveryAgentDetailsAdmin({
          supplier_id: selectedSupplier?.supplier_id,
          //   employee_type: employeeType.DRIVER,
        })
      );
    }
    dispatch(GetAvailableProducts());
  }, [is_admin]);

  //api call if manager login
  React.useEffect(() => {
    if (user_role == ADMIN_ROLES.BW_SUPPLIERMANAGER) {
      dispatch(GetFilteredUserAddressAdmin());
      console.log(selectedSupplier);
      dispatch(GetSupplierDeliveryAgentDetailsAdmin());
    }
  }, [user_role]);
  console.log(wareHouses);
  return (
    <Box sx={{ pt: 2, gap: 2, display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: { md: "flex-start", xs: "center" },
          }}
        >
          {is_admin && !selectedWfs?.wfs_master_id ? (
            <Box>
              <Autocomplete
                disabled={!suppliers?.length || selectedWfs?.wfs_master_id}
                id="supplier-select-demo"
                sx={{ width: 210 }}
                options={
                  filteredSuppliers?.map((item) => ({
                    contact_name: item.supplier_name,
                    user_id: item.user_id,
                    supplier_id: item.id,
                  })) || []
                }
                value={selectedSupplier} // Set the value of Autocomplete to the selected supplier
                isOptionEqualToValue={(option, value) =>
                  option.user_id === value?.user_id
                }
                autoHighlight
                getOptionLabel={(option) => option.contact_name || ""} // Handle case where contact_name is null
                onChange={(_, newValue) => {
                  setSelectedSupplier(newValue || null); // Update the selected supplier when user changes
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.contact_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      filteredSuppliers?.length
                        ? "Search Supplier"
                        : "No Supplier Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // Disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Box>
          ) : (
            <></>
          )}
          {selectedSupplier ||
          user_role == ADMIN_ROLES.BW_DISTRIBUTER ||
          user_role == ADMIN_ROLES.BW_SUPPLIERMANAGER ? (
            <>
              {/* Warehouses Select */}
              <Grow
                in={
                  selectedSupplier ||
                  user_role == ADMIN_ROLES.BW_DISTRIBUTER ||
                  user_role == ADMIN_ROLES.BW_SUPPLIERMANAGER
                }
                style={{ transformOrigin: "0 0 0" }}
                {...(selectedSupplier ||
                user_role == ADMIN_ROLES.BW_DISTRIBUTER ||
                user_role == ADMIN_ROLES.BW_SUPPLIERMANAGER
                  ? { timeout: 1000 }
                  : {})}
              >
                <Box>
                  <Autocomplete
                    disabled={!wareHouses?.length}
                    id="warehouse-select-demo"
                    sx={{ width: 210 }}
                    options={filteredWarehouses?.map((item) => ({
                      contact_name: item.contact_name,
                      address_id: item.address_id,
                    }))}
                    value={selectedWarehouse} // Set the value of Autocomplete to the selected warehouse
                    isOptionEqualToValue={(option, value) =>
                      option.address_id === value?.address_id
                    }
                    autoHighlight
                    getOptionLabel={(option) => option.contact_name || ""} // Handle case where contact_name is null
                    onChange={(_, newValue) => {
                      setSelectedWarehouse(newValue || null); // Update the selected when user changes
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.contact_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={
                          filteredWarehouses?.length
                            ? "Search warehouse"
                            : "No warehouse Found"
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // Disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Box>
              </Grow>

              {/* Employees ist */}
              <Grow
                in={
                  selectedWarehouse &&
                  (selectedSupplier ||
                    user_role == ADMIN_ROLES.BW_DISTRIBUTER ||
                    user_role == ADMIN_ROLES.BW_SUPPLIERMANAGER)
                }
                style={{ transformOrigin: "0 0 0" }}
                {...(selectedWarehouse ? { timeout: 1000 } : {})}
              >
                <Box>
                  <Autocomplete
                    disabled={!employees?.length}
                    id="distEmployees-select-demo"
                    sx={{ width: 210 }}
                    options={filteredEmployees?.map((item) => ({
                      driver_id: item.driver_id,
                      user_id: item.user_id,
                      address_id: item.address_id,
                      fullname: item?.driver_profile?.fullname,
                    }))}
                    value={selectedEmployee} // Set the value of Autocomplete to the selected warehouse
                    isOptionEqualToValue={(option, value) =>
                      option.user_id === value?.user_id
                    }
                    autoHighlight
                    getOptionLabel={(option) => option.fullname || ""} // Handle case where contact_name is null
                    onChange={(_, newValue) => {
                      setSelectedEmployee(newValue || null); // Update the selected when user changes
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.fullname}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={
                          filteredEmployees?.length
                            ? "Search Employee"
                            : "No employees Found"
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // Disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Box>
              </Grow>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: { md: "flex-start", xs: "center" },
          }}
        >
          <Box>
            {is_admin && !selectedSupplier?.supplier_id ? (
              <Autocomplete
                disabled={!wfsList?.length || selectedSupplier?.supplier_id}
                id="WFS-select-demo"
                sx={{ width: 210 }}
                options={wfsList?.map((item) => ({
                  wfs_master_id: item.wfs_master_id,
                  company_name: item.company_name,
                  owner_name: item.owner_name,
                  owner_phone: item.owner_phone,
                  pincode: item?.pincode,
                }))}
                value={selectedWfs} // Set the value of Autocomplete to the selected warehouse
                isOptionEqualToValue={(option, value) =>
                  option.wfs_master_id === value?.wfs_master_id
                }
                autoHighlight
                getOptionLabel={(option) => option.company_name || ""} // Handle case where contact_name is null
                onChange={(_, newValue) => {
                  setSelectedWfs(newValue || null); // Update the selected when user changes
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.company_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                      wfsList?.length ? "Search WFS Details" : "No WFS Found"
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // Disable autocomplete and autofill
                    }}
                  />
                )}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <MarkDamage products={filteredProducts} handleSubmit={handleSubmit} />
      </Box>
      <Box></Box>
    </Box>
  );
};

MarkProductStatus.propTypes = {};

export default MarkProductStatus;
