import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
import { useSelector } from "react-redux";
import {
  AddIotDevice,
  GetIotDevice,
  GetIotDevices,
  UpdateIotDevice,
} from "../Redux/Actions/adminDevicesIotAction";
import { ToastContainer, toast } from "react-toastify";
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IotLiveDataTable from "./liveDataTable";
import { GetIotLiveData } from "../Redux/Actions/adminContainerDetailsAction";
export default function IotDeviceInfoCard1({
  selectedDeviceData,
  addNewDevice,
  IotDevices,
  alignment,
  setAlignment,
}) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const todayDate = moment();
  const dispatch = useDispatch();
  const IotDevicesGet = useSelector((state) => state.IotDeviesData);
  const [selectedDeviceId, setSelectedDeviceId] = React.useState("");
  const [deviceId, setDeviceId] = React.useState("");
  const [deviceStatus, setDeviceStatus] = React.useState("");
  const [batchNumber, setBatchNumber] = React.useState("");
  const [id, setId] = React.useState("");
  const [simcardNumber, setSimcardNumber] = React.useState("");
  const [manufacturingData, setManufacturingData] = React.useState("");
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const [updateBtn, setUpdateBtn] = React.useState(false);
  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [newIotBtn, setNewIotBtn] = React.useState(false);

  const [batchNumberError, setBatchNumberError] = React.useState(false);
  const [deviceIdError, setDeviceIdError] = React.useState(false);
  const [lineIdError, setLineIdError] = React.useState(false);
  const [simNumberError, setSimNumberError] = React.useState(false);
  const [showIotData, setShowIotData] = React.useState(false);
  const [iotLiveData, setIotLiveData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [sortOrder, setSortOrder] = React.useState("DESC");
  const [lastTimeStrap, setLastTimeStrap] = React.useState(null);
  const [previousKeys, setPreviousKeys] = React.useState([]);
  React.useEffect(() => {
    if (addNewDevice === true) {
      handleNewIotDevice();
    }
  }, [addNewDevice]);
  React.useEffect(() => {
    CustomConsole(selectedDeviceData);
    setNewIotBtn(false);
    setBatchNumberError(false);
    setDeviceIdError(false);
    setLineIdError(false);
    setSimNumberError(false);
    if (selectedDeviceData.length) {
      selectedDeviceData.map((data, key) => {
        setDeviceId(data.deviceid);
        setBatchNumber(data.batchnumber);
        setDeviceStatus(data.devicestatus);
        setId(data.tankernumber);
        setSimcardNumber(data.simnumber);
        setManufacturingData(data.mftdate);
        setIsSwitchOn(data.isactive === 1 ? true : false);
      });
    }
  }, [selectedDeviceData]);

  React.useEffect(() => {
    CustomConsole(IotDevicesGet);
    if (updateBtn === true) {
      toast.success(IotDevicesGet.updateIotMsg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateBtn(false);
      dispatch(GetIotDevices());
    }
  }, [IotDevicesGet]);
  const handleUpdateDevice = () => {
    setUpdateBtn(true);
    setUpdateFlag(true);
  };

  React.useEffect(() => {
    if (updateFlag === true) {
      const reqObj = {
        batchnumber: batchNumber,
        deviceid: deviceId,
        devicestatus: deviceStatus,
        isactive: isSwitchOn ? 1 : 0,
        mftdate: manufacturingData,
        simnumber: simcardNumber,
        tankernumber: id,
      };
      dispatch(UpdateIotDevice(reqObj));
      setUpdateFlag(false);
    }
  }, [updateFlag]);

  const handleChangeDeviceStatus = (event) => {
    CustomConsole(event.target.value);
    setDeviceStatus(event.target.value);
    if (parseInt(event.target.value) !== 100) {
      CustomConsole("not 100");
      setIsSwitchOn(false);
    }
    if (parseInt(event.target.value) === 100) {
      CustomConsole("inside 100");
      setIsSwitchOn(true);
    }
  };

  const handleChangeDate = (newValue) => {
    if (newValue !== null) {
      // CustomConsole(newValue.$d.toISOString());
      setManufacturingData(newValue.$d.toISOString());
    }
  };

  const handleNewIotDevice = () => {
    setDeviceId("");
    setDeviceStatus("");
    setBatchNumber("");
    setManufacturingData(moment());
    setSimcardNumber("");
    setId("");
    setIsSwitchOn(false);
    setNewIotBtn(true);
  };

  const handleAddDevice = () => {
    const reqObj = {
      batchnumber: batchNumber,
      deviceid: deviceId,
      devicestatus: 100,
      isactive: isSwitchOn ? 1 : 0,
      mftdate: manufacturingData,
      simnumber: simcardNumber,
      tankernumber: id,
    };
    if (
      batchNumber !== "" &&
      deviceId !== "" &&
      simcardNumber !== "" &&
      id !== ""
    ) {
      if (
        deviceIdError === false &&
        batchNumberError === false &&
        lineIdError === false &&
        simNumberError === false
      ) {
        dispatch(AddIotDevice(reqObj));
        setTimeout(() => {
          dispatch(GetIotDevices());
        }, 1000);
      } else {
        toast.error("Please correct the error in fields", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      toast.error("Some fields are empty, select all the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // TN01BW0011
  // BW0622S647488
  // PDW20WFS1
  // 73585948235
  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    if (charCode >= 97 && charCode <= 122) {
      event.preventDefault();
    }
  };
  const handleKeyPress2 = (event) => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const handleChangeDeviceId = (event) => {
    CustomConsole(event.target.value);
    setDeviceId(event.target.value);
    // const regex_1 = /^TN\d{2}BW\d{4}$/;
    // const regex_2 = /^BWTS\d{4}/;
    // const regex_3 = /^BWTN\d{4}/;
    // const regex_4 = /^BWCN\d{4}/;

    // var result_1 = regex_1.test(event.target.value);
    // var result_2 = regex_2.test(event.target.value);
    // var result_3 = regex_3.test(event.target.value);
    // var result_4 = regex_4.test(event.target.value);
    // // var result_3 =
    // if (
    //   result_1 === true ||
    //   result_2 === true ||
    //   result_3 === true ||
    //   result_4 === true
    // ) {
    //   CustomConsole("Proper device ID");
    //   setDeviceIdError(false);
    // } else {
    //   CustomConsole("Improper device ID");
    //   setDeviceIdError(true);
    // }
  };
  const handleChangeBatchNumber = (event) => {
    // BW0622P098765
    CustomConsole(event.target.value);
    setBatchNumber(event.target.value);
    const regex = /^BW\d{4}[P,S]\d{6}$/;
    var result = regex.test(event.target.value);
    if (result === true) {
      CustomConsole("Proper batch number");
      setBatchNumberError(false);
    } else {
      CustomConsole("Improper batch number");
      setBatchNumberError(true);
    }
    CustomConsole(result);
  };
  const handleChangeLineId = (event) => {
    CustomConsole(event.target.value);
    setId(event.target.value);
    const regex = /^PDW\d{1}WFS\d{1,3}$/;
    var result = regex.test(event.target.value);
    if (result === true) {
      CustomConsole("Proper line number");
      setLineIdError(false);
    } else {
      CustomConsole("Improper line number");
      setLineIdError(true);
    }
    CustomConsole(result);
  };

  const handleChangePhone = (event) => {
    CustomConsole(event.target.value);
    setSimcardNumber(event.target.value);
    const regex = /^\d{10}$/;
    var result = regex.test(event.target.value);
    if (result === true) {
      CustomConsole("Proper sim number");
      setSimNumberError(false);
    } else {
      CustomConsole("Improper sim number");
      setSimNumberError(true);
    }
    CustomConsole(result);
  };

  const handleSelectDeviceId = (event) => {
    CustomConsole(event.target.value);
    setSelectedDeviceId(event.target.value);
    setIotLiveData([]);
    setLastTimeStrap(null);
    setPreviousKeys([]);
  };

  React.useEffect(() => {
    if (selectedDeviceId !== "") {
      dispatch(GetIotDevice(selectedDeviceId));
    }
  }, [selectedDeviceId]);
  const handleChangeToggle = (event, newAlignment) => {
    CustomConsole(newAlignment);
    setShowIotData(false);
    setAlignment(event.target.value);
  };

  React.useEffect(() => {
    if (alignment === "addIot") {
      handleNewIotDevice();
      setSelectedDeviceId("");
    } else if (alignment === "editIot") {
      if (IotDevices.length > 0) {
        CustomConsole("Iot");
        CustomConsole(IotDevices[0].deviceid);
        setSelectedDeviceId(IotDevices[0].deviceid);
        setPageNumber(1);
        setPageSize(10);
        setLastTimeStrap(null);
        // dispatch(GetIotDevice(IotDevices[0].deviceid));
        // dispatch(GetIotDevices());
      }
    }
  }, [alignment, IotDevices]);

  //function to show iot data
  const handelShowLiveData = () => {
    setShowIotData(!showIotData);
  };

  //function to call api
  const liveDataApiCall = () => {
    let reqObj = {
      device_id: selectedDeviceId,
      page_size: pageSize,
      page_number: pageNumber,
      sort_order: sortOrder,
      lastEvaluatedTimestamp: previousKeys[pageNumber - 2],
    };
    dispatch(GetIotLiveData(reqObj)).then((response) => {
      console.log("response");
      console.log(response);
      if (response?.data) {
        const { data, lastEvaluatedKey } = response;
        setIotLiveData(data);
        setLastTimeStrap(lastEvaluatedKey);
        if (lastEvaluatedKey && !previousKeys.includes(lastEvaluatedKey)) {
          setPreviousKeys((prevKeys) => [...prevKeys, lastEvaluatedKey]);
        }
      } else {
        setIotLiveData([]);
        setLastTimeStrap(null);
      }
    });
  };
  //useEffect hit api for live data
  React.useEffect(() => {
    if (showIotData) {
      liveDataApiCall();
    }
  }, [showIotData, selectedDeviceId, pageNumber]);

  //function to handel api call on pagesize search
  const handelPageSizeSearch = () => {
    liveDataApiCall();
  };

  console.log("previousKeys.....");
  console.log(previousKeys);

  return (
    <>
      <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChangeToggle}
          aria-label="Platform"
        >
            <ToggleButton value="editIot">Edit IoT Devices</ToggleButton>
          <ToggleButton value="addIot">Add new device</ToggleButton>
        </ToggleButtonGroup>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Box>
        {alignment === "editIot" && (
          <>
            <Box
              sx={{
                      padding: "5px",
                borderRadius: "10px",
              }}
            >
              <FormControl
                      sx={{ minWidth: 170, padding: "5px" }}
                size="small"
              >
                <InputLabel id="demo-select-small-label">
                  Select device ID
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedDeviceId}
                  label="Select device ID"
                  onChange={handleSelectDeviceId}
                >
                  {IotDevices.map((data) => {
                    return (
                      <MenuItem value={data.deviceid}>
                        <span>{data.deviceid}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
            </Box>
            {alignment === "editIot" && (
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handelShowLiveData}
                >
                  {!showIotData ? "Live IoT Data" : "Back"}
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        {!showIotData && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
              flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "center",
                gap: "10px",
          }}
        >
          <TextField
            disabled={!newIotBtn}
                size="small"
            id="outlined-basic"
            label="Device ID"
            error={deviceIdError}
            helperText={deviceIdError && "Incorrect entry."}
            variant="outlined"
            value={deviceId}
            // onKeyPress={handleKeyPress}
            onChange={handleChangeDeviceId}
          />
          <TextField
            disabled={!newIotBtn}
                size="small"
            id="outlined-basic"
            error={batchNumberError}
            helperText={batchNumberError && "Incorrect entry."}
            label="Batch number"
            variant="outlined"
            value={batchNumber}
            onKeyPress={handleKeyPress}
            onChange={handleChangeBatchNumber}
          />
            </Box>
            <Box
              sx={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "center",
                gap: "10px",
              }}
            >
          <TextField
            disabled={!newIotBtn}
                size="small"
            id="outlined-basic"
            label="Line ID"
            error={lineIdError}
            helperText={lineIdError && "Incorrect entry."}
            variant="outlined"
            value={id}
            onKeyPress={handleKeyPress}
            onChange={handleChangeLineId}
          />
          <TextField
            disabled={!newIotBtn}
                size="small"
            id="outlined-basic"
            type="number"
            error={simNumberError}
            helperText={simNumberError && "Incorrect entry."}
            onKeyPress={handleKeyPress2}
            label="Simcard number"
            variant="outlined"
            value={simcardNumber}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 26);
            }}
            // onChange={(event) => setSimcardNumber(event.target.value)}
            onChange={handleChangePhone}
          />
            </Box>
            <Box
              sx={{
                display: "flex",

                flexWrap: "wrap",
                justifyContent: "center",
                gap: "10px",
              }}
            >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              maxDate={todayDate}
                  size="small"
              disabled={!newIotBtn}
              label="From"
                  inputFormat="DD/MMM/YYYY"
              value={manufacturingData}
              onChange={handleChangeDate}
              renderInput={(params) => (
                <TextField
                      size="small"
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        ></Box>
        <Box
          sx={
            {
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
              // gap: "10px",
            }
          }
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            {!newIotBtn && (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={deviceStatus}
                  onChange={handleChangeDeviceStatus}
                  // defaultValue={parseInt(deviceStatus)}
                >
                  <FormControlLabel
                    value={100}
                    control={<Radio />}
                    label="Working"
                  />
                  <FormControlLabel
                    value={200}
                    control={<Radio />}
                    label="Defect"
                  />
                  <FormControlLabel
                    value={300}
                    control={<Radio />}
                    label="Remove"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <Box>
                <FormControlLabel
                  disabled={parseInt(deviceStatus) !== 100 ? true : false}
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  label={isSwitchOn ? "Active" : "Deactive"}
                  checked={isSwitchOn}
                  onChange={(event) => setIsSwitchOn(event.target.checked)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {alignment === "editIot" && (
            <>
              <Box>
                <Button variant="contained" onClick={handleUpdateDevice}>
                  Update
                </Button>
              </Box>
            </>
          )}

          {alignment === "addIot" && (
            <>
              <Box sx={{ padding: "10px" }}>
                <Button variant="contained" onClick={handleAddDevice}>
                  Add device
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
        )}
        {showIotData && (
          <Box sx={{ width: { xs: "280px", sm: "400px", md: "100%" } }}>
            <IotLiveDataTable
              iotLiveData={iotLiveData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              setPageSize={setPageSize}
              handelPageSizeSearch={handelPageSizeSearch}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
