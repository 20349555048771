/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath R
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, Chip, DialogContent, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { appColors } from "../Assests/AppTheme/appThemeConst";
import { forwardRef } from "react";
import { GIFTCARD_STATUS } from "../AppMeta/appMetaConfig";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectedGiftCardDialog({
  openDialog,
  setOpenDialog,
  giftCardDetails,
}) {
  console.log(giftCardDetails);
  //function to handel close dialog
  const handleClose = () => {
    setOpenDialog(false);
  };

  //function to get giftcad status
  const getStatus = (props) => {
    let status_text;
    let color ="success";
    switch (props) {
      case 100:
        status_text = "Pending";        
        break;
      case 200:
        status_text = "Claim";
        break;
      case 300:
        status_text = "Claimed";
        break;
      case 400:
        status_text = "Expired";
        break;
      default:
        break;
    }
    return (
      <Box>
        <Chip label={status_text} color={color} variant="outlined" />
      </Box>
    );
  };
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: "#038ec2cc" }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent
          sx={{
            position: "relative",
            overflow: "hidden",
            background: appColors.white,
            padding: 0,
            overflowY: "auto",
            width: {
              xs: window.innerWidth - 75,
              sm: "20rem",
              md: "25rem",
              lg: "30rem",
              xl: "35rem",
            },
          }}
        >
          <Box sx={dialogStyles.cardStyle}>
            <Box sx={dialogStyles.cardHeader}>
              <Box>
                <Typography sx={dialogStyles.header}>
                  {giftCardDetails?.giftCardId}
                </Typography>
              </Box>
            </Box>

            <Box sx={dialogStyles.imageContent}>
              <Box sx={dialogStyles?.priceDetailsBox}>
                <Typography sx={dialogStyles.largeText}>
                  Price: ₹ {giftCardDetails?.payingAmount}
                </Typography>
              </Box>
            </Box>
            {giftCardDetails?.greeting?.ownWishes && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Wishes:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.greeting?.ownWishes}
                  </span>
                </Typography>
              </Box>
            )}
            {/* Created At and Expiry date info */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: ".3rem",
              }}
            >
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Created At
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.createdDate}
                </Typography>
              </Box>
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>Status</Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {getStatus(giftCardDetails?.status)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: ".3rem",
              }}
            >
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Batch Name
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.batchName}
                </Typography>
              </Box>
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Batch Id
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.batchId}
                </Typography>
              </Box>
            </Box>
            {/* Password and redeem info */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: ".3rem",
              }}
            >
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Password
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.isPasswordNeeded
                    ? giftCardDetails?.password
                    : "Not required"}
                </Typography>
              </Box>
              <Box>
                {giftCardDetails?.transactionDetails === undefined && (
                  <>
                    <Typography sx={dialogStyles.subHeaderText}>
                      Redeem Through
                    </Typography>
                    <Typography sx={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.redeemThrough}
                    </Typography>
                  </>
                )}
                {giftCardDetails?.transactionDetails && (
                  <>
                    <Typography sx={dialogStyles.subHeaderText}>
                      Redeemed value
                    </Typography>
                    <Typography sx={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.transactionDetails?.redeemedThrough ===
                      "WALLET"
                        ? `${giftCardDetails?.transactionDetails?.redeemedValue} in Water wallet`
                        : `${giftCardDetails?.transactionDetails?.redeemedValue} in water drops`}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <div
              style={{
                width: "100%",
                borderBottom: "2px dashed gray",
                margin: ".5rem 0",
              }}
            ></div>
            <Box sx={{ padding: "0rem .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Creator Details :
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: "0 .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Name:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.creatorName}
                  </span>
                </Typography>
                {/* <Typography sx={dialogStyles.subHeaderText}>
                  Created Date {giftCardDetails?.createdDate}
                </Typography> */}
              </Box>
            </Box>
            <Box sx={{ padding: "0 .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Phone:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.creatorPhone}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: "0 .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Email:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.creatorEmail}
                  </span>
                </Typography>
                {/* <Typography sx={dialogStyles.subHeaderText}>
                  Created Date {giftCardDetails?.createdDate}
                </Typography> */}
              </Box>
            </Box>
            {giftCardDetails?.receiver && (
              <Box sx={{ padding: ".5rem 0 .2rem 0.5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Receiver Details:
                  </Typography>
                </Box>
              </Box>
            )}
            {giftCardDetails?.receiver?.name && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Name:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.receiver?.name}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
            {giftCardDetails?.receiver?.phone && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Phone:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.receiver?.phone}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
            {giftCardDetails?.receiver?.email && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Email:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.receiver?.email}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}

            {giftCardDetails?.promoLabel && (
              <Box sx={{ padding: ".5rem .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Applied promo code:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.promoLabel}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <IconButton
              aria-label="fingerprint"
              color="success"
              onClick={handleClose}
              sx={{ padding: "0 0 .3rem 0" }}
            >
              <CancelIcon sx={{ color: appColors.giftCardColor }} />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const dialogStyles = {
  headingBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardStyle: {
    boxShadow: 5,
    transition: "0.3s",

    height: "auto",
    borderRadius: "1rem",
    position: "relative",
    background: appColors.themeBlue,
    padding: "0 0 0.5rem 0",
    marginBottom: ".5rem",
  },
  cardHeader: {
    background: "linear-gradient(180deg, #038ec2 25%, #5ad0fe 100%);",
    width: "100%",
    height: "30%",
    borderRadius: "1rem 1rem 0 0",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "space-between",
    padding: "0 .8rem",
  },
  header: {
    margin: 0,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  subHeaderText: {
    margin: 0,
    fontSize: ".8rem",
    color: "#7e7e7e",
    fontWeight: "bold",
  },
  subHeaderText1: {
    margin: 0,
    color: appColors.giftCardColor,
    fontSize: ".8rem",
    fontWeight: "bold",
  },
  subHeader: {
    height: "60%",
    width: "auto",
    background: "#038ec2",
    marginBottom: ".8rem",
    padding: "0 .3rem",
    borderRadius: "0 0 .5rem .5rem",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1)",
  },

  imageContent: {
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
    margin: "0 .5rem",
    width: "90%",
    alignItems: "center",
  },
  giftCardImage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  imageContainer: {
    height: "5rem",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: ".3rem",
  },
  priceDetailsBox: {
    width: "30%",
  },
  largeText: {
    margin: "0",
    fontSize: ".9rem",
    color: appColors.giftCardColor,
    fontWeight: "bold",
  },
  buttonStyles: {
    color: appColors.white,
    background: appColors.giftCardColor,
    borderRadius: "1rem",
    fontSize: ".8rem",
    width: "10rem",
    "&:hover": {
      background: appColors.giftCardColor,
    },
  },
};
