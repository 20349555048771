/**
 * @author vinay kumar
 * @description Return Can Particular Order Dialog
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.52
 * @Date 27-10-2023
 */
// Importing the Libraries and Other Files

import React, { useEffect, useState } from "react";

import { returnCanOrderStatus } from "../AppMeta/appMetaConfig";
import AccceptRejectCard from "../../Pages/AdminWithdrawalHistory/AccceptRejectCard";
// import { Box, Button } from "@mui/system";
import CustomConsole from "../../CustomConsole";
import OrderButtonSku from "../Buttons/orderButton";
import {
  Checkbox,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Paper,
  Stack,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { BorderColor } from "@mui/icons-material";
import EditReturnCanOrderDialog from "../../Pages/AdminWithdrawalHistory/EditReturnCanOrderDialog";
import {
  GetWithdrawallist,
  GetWithdrawallistOrderid,
  PutEditReturnCanOrder,
  PutEditReturnCanOrderDistriToWfs,
} from "../../Pages/Redux/Actions/AdminWithdrawalListAction";
import { useDispatch, useSelector } from "react-redux";
import {
  PutSupplierReturnInScanQr,
  SubmitReturnInScanAction,
} from "../../Pages/Redux/Actions/supliersAction";
import { toast } from "react-toastify";
import moment from "moment";
import ScanningDialog from "../Custom/ScanningDialog";
import { skuBookingButtonStyle } from "../Buttons/buttonStyles";
import AddIcon from "@mui/icons-material/Add";

function ReturnCanParticularOrderDialog(props) {
  const dispatch = useDispatch();
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );
  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] = useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = useState("");

  const [openEditReturnCanDialog, setOpenEditReturnCanDialog] =
    React.useState(false);
  const [qrBarcodeDamageCheckBox, setQrBarcodeDamageCheckBox] =
    React.useState(true);
  const [physicalDamageCheckBox, setPhysicalDamageCheckBox] =
    React.useState(true);
  const [physicalDamageQty, setPhysicalDamageQty] = React.useState(0);
  const [qrBarcodeDamageQty, setQrBarcodeDamageQty] = React.useState(0);
  const [withdrawalOrderDetails, setWithdrawalOrderDetails] = useState([]);

  const [refundAmount, setRefundAmount] = useState(
    withdrawalOrderDetails.details?.refund_price || ""
  );

  const [OpenScanningDialog, setOpenScanningDialog] = useState(false);

  CustomConsole(props);
  CustomConsole(withdrawalOrderDetails);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [orderId, setOrderId] = React.useState("");
  const [retunReceivingReq, setRetunReceivingReq] = React.useState({
    empty: 0,
    filled: 0,
    filled_expired: 0,
  });
  const containersLeft =
    parseInt(withdrawalOrderDetails?.container_qty) -
    (parseInt(retunReceivingReq?.empty) +
      parseInt(retunReceivingReq.filled_expired) +
      parseInt(qrBarcodeDamageQty) +
      parseInt(withdrawalOrderDetails?.details?.qr_codes?.length || 0) +
      parseInt(physicalDamageQty));
  const orderStatusFun = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case returnCanOrderStatus.CONSUMER_ACCEPTED:
        return "CONSUMER_ACCEPTED";
      case returnCanOrderStatus.APPROVED:
        return "ONGOING";
      case returnCanOrderStatus.REFUND:
        return "REFUND";
      case returnCanOrderStatus.CONSUMER_REJECTED:
        return "CONSUMER REJECTED";
      case returnCanOrderStatus.DELIVERED:
        return "DELIVERED";
      case returnCanOrderStatus.REJECTED:
        return "CANCELLED";
      case returnCanOrderStatus.PENDING:
        return "PENDING";
      default:
        return "";
    }
  };

  React.useEffect(() => {
    CustomConsole("--------props.singleData----------");
    CustomConsole(props.singleData);
    setOrderId(props.singleData.return_can_order_id);
  }, [props.singleData]);

  const RetunCanData = [
    { type: "Order Id", payload: withdrawalOrderDetails.return_can_order_id },
    {
      type:
        withdrawalOrderDetails.is_consumer === true
          ? "Consumer Name"
          : "Distributor Name",
      payload: withdrawalOrderDetails.name,
    },
    {
      type:
        withdrawalOrderDetails.is_consumer === true
          ? "Consumer Phone"
          : "Distributor Phone",
      payload: withdrawalOrderDetails.phone
        ? withdrawalOrderDetails.phone
        : "--",
    },
    {
      type: "Status",
      payload: orderStatusFun(withdrawalOrderDetails.order_status),
    },
    {
      type: "Container Quantity",
      payload: withdrawalOrderDetails.container_qty,
    },
    {
      type: props.isConsumer === true ? "Supplier Name" : "WFS Name",
      payload: withdrawalOrderDetails.supplier_name,
    },
    { type: "OTP", payload: withdrawalOrderDetails.return_can_otp },
    {
      type: "Service Area",
      payload:
        withdrawalOrderDetails.consumer_address !== undefined &&
        withdrawalOrderDetails.consumer_address !== null &&
        withdrawalOrderDetails.consumer_address.service_area != undefined
          ? withdrawalOrderDetails.consumer_address.service_area
          : "--",
    },
    {
      type: "Pincode",
      payload:
        withdrawalOrderDetails.consumer_address !== undefined &&
        withdrawalOrderDetails.consumer_address !== null &&
        withdrawalOrderDetails.consumer_address.pincode != undefined
          ? withdrawalOrderDetails.consumer_address.pincode
          : "--",
    },
    {
      type: "Address",
      payload:
        withdrawalOrderDetails.consumer_address !== undefined &&
        withdrawalOrderDetails.consumer_address !== null &&
        withdrawalOrderDetails.consumer_address.address_line1 != undefined &&
        withdrawalOrderDetails.consumer_address.address_line2
          ? withdrawalOrderDetails.consumer_address.address_line1 +
            "," +
            withdrawalOrderDetails.consumer_address.address_line2
          : "--",
    },
    {
      type: "Delivery Agent Name",
      payload:
        withdrawalOrderDetails.delivery_agent_name !== ""
          ? withdrawalOrderDetails.delivery_agent_name
          : "--",
    },
    { type: "Capacity", payload: withdrawalOrderDetails.container_capacity },
    { type: "Material", payload: withdrawalOrderDetails.container_material },
    {
      type: "Created At",
      payload: withdrawalOrderDetails.created_at,
      // moment
      //   .utc(withdrawalOrderDetails.created_at)
      //   .tz("Asia/Kolkata")
      //   .add(5, "hours")
      //   .add(30, "minutes")
      //   .format("DD-MM-YYYY hh:mm"),
    },
    {
      type: "Updated At",
      payload: withdrawalOrderDetails.updated_at,
      // moment
      //   .utc(withdrawalOrderDetails.updated_at)
      //   .tz("Asia/Kolkata")
      //   .add(5, "hours")
      //   .add(30, "minutes")
      //   .format("DD-MM-YYYY hh:mm"),
    },
    { type: "Refund Amount", payload: withdrawalOrderDetails.refund_amount },
    {
      type: "Reject Reason",
      payload:
        withdrawalOrderDetails.reject_reason !== ""
          ? withdrawalOrderDetails.reject_reason
          : "--",
    },
    {
      type: "Deposited Valid Cans",
      payload: withdrawalOrderDetails.deposited_valid_cans,
    },
    {
      type: "Filled Cans  Quantity",
      payload: withdrawalOrderDetails.filled_cans_qty,
    },
    {
      type: "UnDeposited Damaged Cans",
      payload: withdrawalOrderDetails.undeposited_damaged_cans,
    },
    {
      type: "UnDeposited Valid Cans",
      payload: withdrawalOrderDetails.undeposited_valid_cans,
    },
    {
      type: "Received Container  Quantity",
      payload: withdrawalOrderDetails.received_container_qty,
    },
    {
      type: "Physically Damage Quantity",
      payload: withdrawalOrderDetails.physically_damage_can_qty,
    },
    {
      type: "Qr Bar Code Damage Can Quantity",
      payload: withdrawalOrderDetails.qr_bar_code_damage_can_qty,
    },
    {
      type: "Recently completed Order amount",
      payload: `${
        withdrawalOrderDetails.details?.recent_order?.order_id || " "
      }${withdrawalOrderDetails.details?.recent_order?.amount || " "}`,
    },
  ];

  const handleClose = () => {
    CustomConsole("-------------handleClose----------------");
    props.handleClose();
  };

  const clickRejected = () => {
    CustomConsole("--------------clickRejected----------------");
    props.clickRejected(withdrawalOrderDetails.return_can_order_id);
  };
  const ClickAcceptFirst = () => {
    CustomConsole("--------------clickAcceptFirst----------------");
    props.ClickAcceptFirst(withdrawalOrderDetails.return_can_order_id);
  };
  const ClickReAssign = (orderid) => {
    CustomConsole("--------------clickAcceptFirst----------------");
    props.ClickReAssign(orderid);
  };
  const resetFilledScan = () => {
    dispatch(
      PutSupplierReturnInScanQr({
        codes: [],
        is_reset: true,
        order_id: withdrawalOrderDetails.return_can_order_id,
      })
    ).then(() => {
      getOrderDetails();
    });
  };
  const handelIssueRefund = () => {
    CustomConsole("--------------handelIssueRefund----------------");
    props.handelIssueRefund({
      orderId: withdrawalOrderDetails.return_can_order_id,
      refund_amount: refundAmount,
    });
  };

  const handleOpenReturnEditDialog = () => {
    setOpenEditReturnCanDialog(true);
  };
  const handleSubmitReturnDialog = (props) => {
    const reqObj = {
      product_qty: props.qty,
    };
    CustomConsole(reqObj);
    if (reqObj.product_qty > 0) {
      if (props.is_consumer === true) {
        dispatch(PutEditReturnCanOrder(orderId, reqObj));
      } else {
        dispatch(PutEditReturnCanOrderDistriToWfs(orderId, reqObj));
      }
      setOpenEditReturnCanDialog(false);
      props.close(false);
      setTimeout(() => {
        dispatch(
          GetWithdrawallist({
            page_number: 0,
            page_size: 25,
          })
        );
      }, 1500);
    } else {
      toast.dark("Edit minimum quantity 1", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
  const handleCloseReturnDialog = () => {
    setOpenEditReturnCanDialog(false);
  };

  const handleQrBarcodeDamageChecked = (event) => {
    CustomConsole("----checked-----QrBarCode------");
    CustomConsole(event.target.checked);
    setQrBarcodeDamageCheckBox(event.target.checked);
  };
  const handlePhysicalDamageChecked = (event) => {
    CustomConsole("----checked-----PD------");
    CustomConsole(event.target.checked);
    setPhysicalDamageCheckBox(event.target.checked);
  };

  const handleCompleteReturn = () => {
    const reqObj = {
      // order_id: withdrawalOrderDetails.return_can_order_id,
      empty_qty: parseInt(retunReceivingReq.empty),
      filled_qty: withdrawalOrderDetails?.details?.qr_codes?.length || 0,
      // filled_qty: parseInt(retunReceivingReq.filled),
      expired_qty: parseInt(retunReceivingReq.filled_expired),
      physically_damaged: {
        recieved: physicalDamageCheckBox,
        qty: parseInt(physicalDamageQty),
      },
      qr_barcode_damaged: {
        recieved: qrBarcodeDamageCheckBox,
        qty: parseInt(qrBarcodeDamageQty),
      },
    };
    CustomConsole("---reqObj---------");
    CustomConsole(reqObj);
    const totalSubmitting =
      reqObj.empty_qty +
      reqObj.filled_qty +
      reqObj.expired_qty +
      physicalDamageQty +
      qrBarcodeDamageQty;
    CustomConsole(totalSubmitting);
    if (totalSubmitting === withdrawalOrderDetails.container_qty) {
      dispatch(SubmitReturnInScanAction(orderId, reqObj));
      props.setOpenDialogReturnCanSingleOrder(false);
      setTimeout(() => {
        dispatch(
          GetWithdrawallist({
            page_number: 0,
            page_size: 25,
          })
        );
      }, 1500);
    } else {
      toast.error(
        `Ordered Quantity And Returning Quantity is Mismatching! Or Can't Submit Less Than Zero Every Field `,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
  const getOrderDetails = () => {
    dispatch(GetWithdrawallistOrderid(orderId));
  };
  useEffect(() => {
    // if(orderId!==""){
    getOrderDetails();
    // }
  }, [orderId]);
  useEffect(() => {
    // CustomConsole(adminWithdrawallistData)
    if (Object.keys(adminWithdrawallistData.withdrawalOrderid).length > 0) {
      adminWithdrawallistData.withdrawalOrderid.map((data) => {
        CustomConsole(data);
        setWithdrawalOrderDetails(data);
      });
    } else {
      setWithdrawalOrderDetails([]);
    }
  }, [adminWithdrawallistData]);
  useEffect(() => {
    console.log(scannedQrBarcode, "scannedQrBarcode");
    if (scannedQrBarcode) {
      dispatch(
        PutSupplierReturnInScanQr({
          codes: [scannedQrBarcode],
          is_reset: false,
          order_id: withdrawalOrderDetails.return_can_order_id,
        })
      ).then(() => {
        getOrderDetails();
      });
    }
  }, [scannedQrBarcode]);
  return (
    <Box>
      <Dialog open={props.openDialogReturnCanSingleOrder}>
        <DialogContent sx={{ width: "500px" }}>
          {/* <Box> */}
          <Grid container>
            <Grid item md={12} xs={6}>
              {RetunCanData.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",

                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {data.payload}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
          {/* </Box> */}

          {/* {props.adminWithdrawallistData.damageCansData &&
          props.adminWithdrawallistData.damageCansData.length > 0 ? ( */}
          {/* {props.ADMIN_ROLES.BW_ADMIN === props.adminRole ||
          props.ADMIN_ROLES.BW_SUPERADMIN === props.adminRole ||
          props.ADMIN_ROLES.BW_CSLEAD === props.adminRole ||
          props.ADMIN_ROLES.BW_CS === props.adminRole ||
          props.ADMIN_ROLES.BW_CSHEAD === props.adminRole ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {withdrawalOrderDetails.order_status === 500 ? (
                <AccceptRejectCard
                  withdrawDet={props.withdrawDet}
                  setOpenDialogReturnCanSingleOrders={
                    props.setOpenDialogReturnCanSingleOrder
                  }
                  pageNumber={props.pageNumber}
                />
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )} */}
          {/* ) : null} */}
          {withdrawalOrderDetails.order_status ===
            returnCanOrderStatus.PENDING ||
          withdrawalOrderDetails.order_status ===
            returnCanOrderStatus.APPROVED ? (
            <>
              {props.isConsumer === true &&
              withdrawalOrderDetails.is_submit_started === true &&
              withdrawalOrderDetails.is_submit_finished === false ? (
                <>
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={{ m: 1, color: "red" }}>Note:</Typography>
                    <Checkbox defaultChecked disabled />
                    <Typography sx={{ m: 1 }}>Receiving</Typography>
                  </Box>
                  <Grid container>
                    <Grid item md={12} xs={6} sm={6} lg={12}>
                      <Box
                        sx={{
                          bgcolor: "#F4F6F7",
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: { md: "column", xs: "column" },
                          // justifyContent: "space-around",
                          pt: "10px",
                          gap: { xs: "10px", md: "20px" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Empty : -&nbsp;&nbsp;
                          </Typography>
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                empty: parseInt(retunReceivingReq.empty + 1),
                              }))
                            }
                            handelMinusQuantity={() =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                empty:
                                  retunReceivingReq.empty > 0
                                    ? retunReceivingReq.empty - 1
                                    : 0,
                              }))
                            }
                            handelQuantityChange={(event) =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                empty:
                                  event.target.value >= 0 &&
                                  event.target.value <= 999
                                    ? parseInt(event.target.value)
                                    : parseInt(prev.empty),
                              }))
                            }
                            inputValue={retunReceivingReq.empty}
                            // disabled={false}
                          />
                        </Box>
                        {/* Filled RECEIVING */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Filled : -&nbsp;&nbsp;
                          </Typography>
                          {/* <Button
                          onClick={() => setOpenScanningDialog(true)}
                          variant="contained"
                        >
                          Scan Now
                        </Button> */}
                          <Stack direction="row">
                            <Box sx={skuBookingButtonStyle.canQtyStyle}>
                              {" "}
                              <input
                                type="number"
                                value={
                                  withdrawalOrderDetails?.details?.qr_codes
                                    ?.length || 0
                                }
                                style={{
                                  wordWrap: "normal",
                                  border: 0,
                                  fontSize: "16px",
                                  minWidth: "20px",
                                  textAlign: "center",
                                  width: "100%",
                                  outline: "none",
                                }}
                                disabled={true}
                              />
                            </Box>
                            <Box
                              sx={skuBookingButtonStyle.orderButtonStylePlus}
                            >
                              <Button
                                size="small"
                                onClick={() => setOpenScanningDialog("scan")}
                              >
                                <AddIcon
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                />
                              </Button>
                            </Box>
                          </Stack>
                        </Box>
                        {/* 
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Filled : -&nbsp;&nbsp;
                          </Typography>
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                filled: parseInt(retunReceivingReq.filled + 1),
                              }))
                            }
                            handelMinusQuantity={() =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                filled: parseInt(
                                  retunReceivingReq.filled > 0
                                    ? retunReceivingReq.filled - 1
                                    : 0
                                ),
                              }))
                            }
                            handelQuantityChange={(event) =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                filled:
                                  event.target.value >= 0 &&
                                  event.target.value <= 999
                                    ? parseInt(event.target.value)
                                    : parseInt(prev.filled),
                              }))
                            }
                            inputValue={retunReceivingReq.filled}
                            // disabled={false}
                          />
                        </Box> */}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Expired :-&nbsp;&nbsp;
                          </Typography>

                          <OrderButtonSku
                            handelAddQuantity={() =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                filled_expired: parseInt(
                                  retunReceivingReq.filled_expired + 1
                                ),
                              }))
                            }
                            handelMinusQuantity={() =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                filled_expired: parseInt(
                                  retunReceivingReq.filled_expired > 0
                                    ? retunReceivingReq.filled_expired - 1
                                    : 0
                                ),
                              }))
                            }
                            handelQuantityChange={(event) =>
                              setRetunReceivingReq((prev) => ({
                                ...prev,
                                filled_expired:
                                  event.target.value >= 0 &&
                                  event.target.value <= 999
                                    ? parseInt(event.target.value)
                                    : parseInt(prev.filled_expired),
                              }))
                            }
                            inputValue={retunReceivingReq.filled_expired}
                            // disabled={false}
                          />
                        </Box>
                        {withdrawalOrderDetails.is_qr === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: { md: "row", xs: "column" },
                            }}
                          >
                            <Typography sx={{ textAlign: "start" }}>
                              Qr Barcode Damage :-&nbsp;&nbsp;
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <Checkbox
                                defaultChecked
                                value={qrBarcodeDamageCheckBox}
                                onChange={handleQrBarcodeDamageChecked}
                                {...label}
                              />
                              <OrderButtonSku
                                handelAddQuantity={() =>
                                  setQrBarcodeDamageQty(
                                    parseInt(qrBarcodeDamageQty + 1)
                                  )
                                }
                                handelMinusQuantity={() =>
                                  setQrBarcodeDamageQty(
                                    parseInt(
                                      qrBarcodeDamageQty > 0
                                        ? qrBarcodeDamageQty - 1
                                        : 0
                                    )
                                  )
                                }
                                handelQuantityChange={(event) =>
                                  setQrBarcodeDamageQty(
                                    parseInt(
                                      event.target.value >= 0 &&
                                        event.target.value <= 999
                                        ? parseInt(event.target.value)
                                        : parseInt(qrBarcodeDamageQty)
                                    )
                                  )
                                }
                                inputValue={qrBarcodeDamageQty}
                                // disabled={false}
                              />
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: { md: "row", xs: "column" },
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Physical Damage&nbsp;&nbsp;
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            <Checkbox
                              defaultChecked
                              value={physicalDamageCheckBox}
                              onChange={handlePhysicalDamageChecked}
                              {...label}
                            />
                            <OrderButtonSku
                              handelAddQuantity={() =>
                                setPhysicalDamageQty(
                                  parseInt(physicalDamageQty + 1)
                                )
                              }
                              handelMinusQuantity={() =>
                                setPhysicalDamageQty(
                                  parseInt(
                                    physicalDamageQty > 0
                                      ? physicalDamageQty - 1
                                      : 0
                                  )
                                )
                              }
                              handelQuantityChange={(event) =>
                                setPhysicalDamageQty(
                                  parseInt(
                                    event.target.value >= 0 &&
                                      event.target.value <= 999
                                      ? parseInt(event.target.value)
                                      : parseInt(physicalDamageQty)
                                  )
                                )
                              }
                              inputValue={physicalDamageQty}
                              // disabled={false}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            m: 1,
                          }}
                        >
                          <Button
                            disabled={containersLeft == 0 ? false : true}
                            variant="contained"
                            onClick={handleCompleteReturn}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: {
                    md: "space-evenly",
                    xs: "start",
                  },
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{ maxHeight: "35px", mt: "8px" }}
                  onClick={handleOpenReturnEditDialog}
                >
                  <BorderColor />
                  Edit
                </Button>
                <Button
                  onClick={() =>
                    ClickAcceptFirst(withdrawalOrderDetails.return_can_order_id)
                  }
                  variant="contained"
                  disableElevation
                  // size="large"
                  sx={{ margin: 1 }}
                >
                  {withdrawalOrderDetails.order_status ===
                  returnCanOrderStatus.PENDING
                    ? "ACCEPT"
                    : withdrawalOrderDetails.order_status ===
                      returnCanOrderStatus.APPROVED
                    ? "Re-Assign"
                    : ""}
                </Button>
                <Button
                  onClick={() =>
                    clickRejected(withdrawalOrderDetails.return_can_order_id)
                  }
                  variant="contained"
                  disableElevation
                  sx={{ margin: 1 }}
                  color="error"
                  // size="small"
                >
                  CANCEL
                </Button>
              </Box>
            </>
          ) : (
            ""
            // <Box
            //   sx={{ display: "flex", justifyContent: "space-evenly" }}
            // >
            //   <Button
            //     onClick={() =>
            //       ClickReAssign(withdrawalOrderDetails.return_can_order_id)
            //     }
            //     variant="contained"
            //     disableElevation
            //     // size="large"
            //     sx={{ margin: 1 }}
            //   >
            //     Re-Assign
            //   </Button>
            //   <Button
            //     onClick={() =>
            //       clickRejected(withdrawalOrderDetails.return_can_order_id)
            //     }
            //     variant="contained"
            //     disableElevation
            //     sx={{ margin: 1 }}
            //     color="error"
            //     // size="small"
            //   >
            //     REJECT
            //   </Button>
            // </Box>
          )}
          {withdrawalOrderDetails.order_status ===
          returnCanOrderStatus.CONSUMER_ACCEPTED ? (
            <Paper
              elevation={10}
              sx={{ margin: 1, alignItems: "center", justifyContent: "center" }}
            >
            <Box
              sx={{
                  width: "100%",
                display: "flex",
                  flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                gap: "20px",
              }}
            >
                <Typography>Enter Refund Amount</Typography>
                <TextField
                  size="small"
                  // key={refundAmount}
                  value={refundAmount}
                  autoFocus
                  onChange={(e) =>
                    setRefundAmount(
                      parseInt(e.target.value) >= 0
                        ? parseInt(e.target.value)
                        : ""
                    )
                  }
                  label="Refund Amount"
                />
                {refundAmount < 1 ? (
                  <Typography color="error">
                    Note: * If there is no refund amount, transaction will not
                    be generated
                  </Typography>
                ) : (
                  <></>
                )}

                {refundAmount >= 0 ? (
              <Button
                variant="contained"
                disableElevation
                sx={{ margin: 2, width: 200 }}
                onClick={() =>
                      handelIssueRefund(
                        withdrawalOrderDetails.return_can_order_id
                      )
                }
              >
                Issue Refund
              </Button>
                ) : (
                  <></>
                )}
            </Box>
            </Paper>
          ) : (
            ""
          )}
          <Box
            sx={{ display: "flex", justifyContent: "center", margin: "10px" }}
          >
            <Button color="error" variant="contained" onClick={handleClose}>
              close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {OpenScanningDialog && (
        <ScanningDialog
          openLinkFromStartDialog={openLinkFromStartDialog}
          title={""}
          orderdata={withdrawalOrderDetails.return_can_order_id}
          setOpenScanningDialog={setOpenScanningDialog}
          openScanningDialog={OpenScanningDialog}
          scannedQrBarcode={scannedQrBarcode}
          setScannedQrBarcode={setScannedQrBarcode}
          currentScanned={{}}
          setCurrentScanned={() => {}}
          scanQtyMax={containersLeft}
          scanOrderId={withdrawalOrderDetails.return_can_order_id}
          scannedCount={withdrawalOrderDetails?.details?.qr_codes?.length || 0}
          setScannedCount={() => {}}
          setRequestObject={() => {}}
          setOpenLinkFromStartDialog={setOpenLinkFromStartDialog}
          setSubmitBtnFlg={() => setOpenScanningDialog(false)}
        />
      )}
      {openLinkFromStartDialog && (
        <Box>
          <Dialog
            open={openLinkFromStartDialog}
            onClose={() => setOpenLinkFromStartDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to link from the beginning?"}
            </DialogTitle>

            <DialogActions>
              <Button
                onClick={() => setOpenLinkFromStartDialog(false)}
                variant="outlined"
                size="small"
              >
                No
              </Button>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  setOpenLinkFromStartDialog(false);
                  resetFilledScan();
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      <EditReturnCanOrderDialog
        openEditReturnCanDialog={openEditReturnCanDialog}
        handleSubmitReturnDialog={handleSubmitReturnDialog}
        handleCloseReturnDialog={handleCloseReturnDialog}
        containerQty={withdrawalOrderDetails.container_qty}
        IsConsumer={withdrawalOrderDetails.is_consumer}
        setOpenDialogReturnCanSingleOrders={
          props.setOpenDialogReturnCanSingleOrder
        }
      />
    </Box>
  );
}

export default ReturnCanParticularOrderDialog;
