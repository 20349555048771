import {
  Box,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../Components/Tables/CoustomTable";
import { tabelConfig } from "../../../Components/AppMeta/appTabelConfig";
import {
  CreatePhysicalGiftCard,
  GetGiftCarData,
  GetPhysicalGiftCards,
} from "../../Redux/Actions/AdminSettingsAction";
import { useDispatch } from "react-redux";
import CoustomAddItemsDialog from "../../../Components/Custom/addItemsDialog";
import { textFieldConfig } from "../../../Components/AppMeta/appTextFieldConfig";
import PrimaryButton from "../../../Components/Buttons/primaryButton";
import { appColors } from "../../../Components/Assests/AppTheme/appThemeConst";
import GiftCardBatchDetails from "./GiftCardBatchDetails";
import { GIFTCARD_STATUS } from "../../../Components/AppMeta/appMetaConfig";
import PhysicalGiftCardDialog from "../../../Components/Dialog/physicalGiftCardDialog";
import moment from "moment";
import { toast } from "react-toastify";

function PhysicalGiftCardMain() {
  const dispatch = useDispatch();
  const [giftCards, setGiftCards] = useState([]);
  const [isEditButon, setIsEditButton] = useState(false);
  const [giftCardSetting, setGiftCardSetting] = useState({});
  const [formValues, setFormValues] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [fieldValidity, setFieldValidity] = useState({});
  const [SelectedGiftCard, setSelectedGiftCard] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [openGiftCardFilterDialog, setOpenGiftCardFilterDialog] =
    useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    status: "",
    from: moment().subtract(180, "days").format("YYYY-MM-DD"),
    to: moment().add(7, "days").format("YYYY-MM-DD"),
    giftCardId: "",
    id: "",
    batchName: "",
    sort: "DESC",
  });
  const tableRowClickFunction = () => {};
  const getGiftcards = () => {
    const reqObj = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      giftCardId: selectedFilters?.giftCardId || undefined,
      status: selectedFilters?.status || undefined,
      batchName: selectedFilters?.batchName || undefined,
      id: selectedFilters?.id || undefined,
      sort: selectedFilters?.sort || undefined,
      from: selectedFilters?.from || undefined,
      to: selectedFilters?.to || undefined,
    };
    dispatch(GetPhysicalGiftCards(reqObj)).then((data) => {
      setGiftCards(data?.data);
    });
  };
  useEffect(() => {
    getGiftcards();
  }, [pageNumber, pageSize]);

  //usefeect to get admin setings
  useEffect(() => {
    dispatch(GetGiftCarData()).then((data) => {
      setGiftCardSetting(data?.data?.gift_card_settings);
    });
  }, []);

  const getStatus = (props) => {
    let status_text;
    let color = "info";
    console.log(props?.row?.status);
    switch (props?.row?.details?.status) {
      case GIFTCARD_STATUS.ACTIVE:
        status_text = "Active";
        color = "info";
        break;
      case GIFTCARD_STATUS.COMPLETED:
        status_text = "Completed";
        color = "success";
        break;
      case GIFTCARD_STATUS.DEACTIVE:
        status_text = "Inactive";
        color = "warning";
        break;
      case GIFTCARD_STATUS.PAYMENTCLOSE:
        status_text = "Payment Closed";
        color = "success";
        break;
      case GIFTCARD_STATUS.PRINTING:
        status_text = "Printing";
        color = "info";
        break;
      default:
        break;
    }
    return (
      <Box>
        <Chip label={status_text} color={color} variant="outlined" />
      </Box>
    );
  };
  const handelEditButton = (props) => {
    setIsEditButton(true);

    const { row } = props;
    setSelectedGiftCard(row);
  };
  const EditActionButtom = (props) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <PrimaryButton
          handleButtonClick={() => {
            handelEditButton(props);
          }}
          btnName="View"
          color="Green"
        />{" "}
      </Box>
    );
  };
  const submitCreateGc = (props) => {
    if (
      !props.batchName ||
      !props.numberOfGiftCards ||
      !props.amount_perGiftCard
    ) {
      return toast.error("Please fill all required fields", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (props.amount_perGiftCard.length > 4) {
      return toast.error("The amount exceeds the maximum limit of 4 digits.", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (!props?.isPasswordRequired) {
      return toast.error("Please choose any Password required option", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (!props?.redeemThrough) {
      return toast.error("Please choose any Redeem  option", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
    dispatch(CreatePhysicalGiftCard(props)).then((data) => {
      if (!data.error) {
        getGiftcards();
        setOpenDialog(false);
        setFormValues({});
      }
    });
  };
  const GiftCardSubmitBtn = (props) => {
    return (
      <Box>
        <PrimaryButton
          btnName="Submit"
          handleButtonClick={() => {
            submitCreateGc(props);
            // handelSubmitManufacture(props)
          }}
          color={appColors.commonBtnColor}
        />
      </Box>
    );
  };

  //function to close gift card filter
  const handleCloseGiftCard = () => {
    setOpenGiftCardFilterDialog(false);
  };

  //function handel gift card
  const handleFilterGiftCard = (props) => {
    getGiftcards();
    handleCloseGiftCard();
  };

  //function to open gift card filter dialog
  const handelFilterDialog = () => {
    setOpenGiftCardFilterDialog(true);
  };

  return (
    <Box>
      <CustomTable
        tableHead={tabelConfig.PhysicalGiftCardTable.headerData}
        tableData={giftCards}
        tableDataKey={[
          ...tabelConfig.PhysicalGiftCardTable.tabelDataKey,
          {
            component: (props) => {
              return getStatus(props);
            },
          },
          {
            component: (props) => {
              return EditActionButtom(props);
            },
          },
        ]}
        addButton={true}
        addButtonName="Add GiftCard"
        handleOnClickAddButton={() => setOpenDialog(true)}
        tableRowClickFunction={tableRowClickFunction}
        pagination="200"
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber || 0}
        pageSize={pageSize}
        handelFilterDialog={handelFilterDialog}
      />
      <CoustomAddItemsDialog
        openDialog={openDialog}
        handelClose={() => setOpenDialog(false)}
        dialogTittle="Add Physical Gift Card"
        fieldsConfig={textFieldConfig.addPhysicalGiftCard}
        submitButton={GiftCardSubmitBtn}
        formValues={formValues}
        setFormValues={setFormValues}
        fieldValidity={fieldValidity}
        setFieldValidity={setFieldValidity}
      />
      {SelectedGiftCard && (
        <GiftCardBatchDetails
          handleClose={() => {
            getGiftcards();
            setSelectedGiftCard();
          }}
          giftCardId={SelectedGiftCard}
          open={SelectedGiftCard ? true : false}
          giftCardSetting={giftCardSetting}
        />
      )}

      {openGiftCardFilterDialog && (
        <PhysicalGiftCardDialog
          openGiftCardFilterDialog={openGiftCardFilterDialog}
          handleCloseGiftCard={handleCloseGiftCard}
          handleFilterGiftCard={handleFilterGiftCard}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
    </Box>
  );
}

export default PhysicalGiftCardMain;
